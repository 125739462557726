<template>
  <div>
   
    <div class="row">
      <div :class="vertical ? 'col-12' : 'col'">
        <b-form-group>
          <label>Especialidade</label>
          <v-select
            :value="especialidade_id"
            :options="especialidades"
            :reduce="(espec) => espec.id"
            @input="handleEspecialidade"
            :loading="loadingEspecialidade"
            label="especialidade"
            :multiple="multipleEspecialidade"
            :disabled="disabledEspecialidade"
          >
            <!-- <template #option="item">
              csdfds  -{{item}}
            </template> -->
          </v-select>
          <b-form-invalid-feedback :force-show="stateEspecialidade === false">
            Selecione a Especialiade
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div :class="vertical ? 'col-12' : 'col'">
        <b-form-group>
          <div class="d-flex align-items-end ">
            <div class="d-flex flex-grow-1 flex-column mr-2">
              <label>Médico</label>
              <v-select
                :options="medicos"
                :reduce="(med) => med.id"
                @input="handleMedico"
                :value="medico_id"
                :loading="loadingMedicos"
                :multiple="multipleMedico"
                :disabled="disabledMedico || (user.isMedico && lockMedico)"
                label="razao_social"
              >
                <template #option="{ id, razao_social }">
                  <p style="margin: 0">{{ razao_social }}</p>
                  <small
                    class=""
                    v-if="user.entidade && user.entidade.id === id"
                  >
                    * Você
                  </small>
                </template>
              </v-select>
            </div>
            <add-medico-resumido @added="onAdded"></add-medico-resumido>
          </div>

          <b-form-invalid-feedback :force-show="stateMedico === false">
            Selecione o Médico
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
    <div class="row"></div>
  </div>
</template>

<script>
import EspecialidadesLib from "../../libs/EspecialidadesLib";
import EntidadesLib from "../../libs/EntidadesLib";
import AddMedicoResumido from "./SelectMedico/AddMedicoResumido";

export default {
  components: {
    AddMedicoResumido,
  },
  props: {
    medico_inicial: [Number, String],
    especialidade_inicial: [Number, String],

    medico_id: [Number, Array],
    especialidade_id: [Number, Array],
    multipleMedico: { type: Boolean, default: false },
    multipleEspecialidade: { type: Boolean, default: false },
    stateEspecialidade: { type: [Boolean, null], default: null },
    stateMedico: { type: [Boolean, null], default: null },
    vertical: { type: Boolean, default: false },
    disabledMedico: { type: Boolean, default: false },
    disabledEspecialidade: { type: Boolean, default: false },
    lockMedico: { type: Boolean, default: false },
  },
  data() {
    return {
      especialidades: [],
      medicos: [],
      loadingEspecialidade: false,
      loadingMedicos: false,
    };
  },
  methods: {
    async carregaEspecialidade() {
      this.loadingEspecialidade = true;
      this.especialidades = await EspecialidadesLib.get();
      if (this.especialidade_inicial) {
        this.handleEspecialidade(this.especialidade_inicial);
      }
      if (this.user.isMedico && this.user.entidade && this.lockMedico) {
        let medicoEspecialidades = [];
        medicoEspecialidades =
          this.user?.entidade?.configuracoes?.especialidades || [];
        //console.log("medico espec", medicoEspecialidades);
        this.especialidades = this.especialidades.filter((espec) =>
          medicoEspecialidades.includes(espec.id)
        );
        if (this.especialidades.length > 0) {
          this.handleEspecialidade(this.especialidades[0]?.id);
        }
      }

      // if (this.especialidade_id && this.especialidades.length > 0) {
      //   this.especialidade = {
      //     ...this.especialidades.find(
      //       (espec) => espec.id == this.especialidade_id
      //     ),
      //   };
      // }
      this.loadingEspecialidade = false;
    },
    async onAdded(medico) {

      if (medico) {
      this.handleEspecialidade(medico.especialidade_id)
        await this.carregaMedicos();

        this.$forceUpdate();
        this.handleMedico(medico.id)
      }
    },
    handleEspecialidade(value) {
      this.$nextTick(function() {
        // console.log('especialidade finded', this.especialidades.find(espec=>espec.id == value));
        this.$emit("especialidade", value);
        this.$emit(
          "especialidadeComplete",
          this.especialidades.find((espec) => espec.id == value)
        );
        if (!this.user.isMedico && !this.lockMedico) {
          this.$emit("medico", null);
        }
      });
    },
    handleMedico(value) {
      this.$nextTick(function() {
        this.$emit("medico", value);
        this.$emit(
          "medicoComplete",
          this.medicos.find((med) => med.id == value)
        );
      });
    },
    async carregaMedicos() {
      this.loadingMedicos = true;
      // console.log('medico_id', this.medico_id, 'especialidade_id', this.especialidade_id);
      await this.$nextTick();
      this.$forceUpdate();
      if (this.medico_inicial) {
        this.handleMedico(this.medico_inicial);
      }
      if (
        this.user.isMedico &&
        this.lockMedico &&
        !this.medico_id &&
        !this.especialidade_id
      ) {
        if (this.user?.entidade && this.user?.entidade?.id) {
          this.medicos = [this.user.entidade];
        } else {
          this.medicos = await EntidadesLib.getById(this.user?.entidade_id);
        }
        if (this.medicos.length > 0) {
          this.handleMedico(this.medicos[0]?.id);
        }
      } else {
        //console.log("alkdsjlkasdjflksajdflksajfjjfj", this.especialidade_id);
        this.medicos = this.especialidade_id
          ? await EntidadesLib.getByEspecialidade(this.especialidade_id)
          : [];
      }

      this.loadingMedicos = false;
    },
  },
  computed: {
    // user() {
    //   return this.$store.state.auth.user;
    // },
    // isMedic() {
    //   return ["medico"].includes(this.user?.perfil);
    // },
  },
  watch: {
    especialidade_id: {
      async handler() {
        if (this.lockMedico && this.user.isMedico) return;

        await this.carregaMedicos();
      },
    },
  },
  created() {
    this.$nextTick();

    this.carregaEspecialidade();
    this.carregaMedicos();
  },
};
</script>

<style lang="scss">
.font-sm {
  font-size: 10px;
}
</style>
