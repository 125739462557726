<template>
  <div>

    <b-table
      :items="historico"
      :fields="campos"
      show-empty
      class="shadow"
    >
      <template #cell(data)="{item}">
        {{ item.data | moment("DD/MM/YYYY") }}
      </template>
      <template #cell(alteracao)="{item}">
        {{
          `O valor ${item.tipo ? "de " + item.tipo : ""} foi alterado de R$${
            item.valor_anterior
          } para R$${item.valor_novo}`
        }}
      </template>
      <template #cell(valor_anterior)="{item}">
        {{ item.valor_anterior | currencyWithMask }}
      </template>
      <template #cell(valor_novo)="{item}">
        {{ item.valor_novo | currencyWithMask }}
      </template>
      <template #empty>
        <div class="text-center">
          📦 Nenhuma alteração de preço no produto
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ExamesHistoricosLib from "../../libs/ExamesHistoricosLib";
export default {
  props: {
    id_exame: [Number, String],
    entidade_id: [Number, String],
    tipo: String,
    historico: { type: Array, default: () => [] },
  },
  data() {
    return {
      exameHistoricos: [],
      campos: [
        { key: "data", label: "Data", class: "text-center", sortable: true },
        { key: "alteracao", label: "Ateração", class: "text-center" },

        { key: "justificativa", label: "Justificativa", class: "text-center" },
        { key: "user", label: "Usuário", class: "text-center" },
      ],
    };
  },
  methods: {
    async carregaHistoricos() {
      this.exameHistoricos = await ExamesHistoricosLib.get({
        exame_id: this.id_exame,
        tipo: this.tipo,
        entidade_id: this.entidade_id,
      });
    },
  },
  async mounted() {
       this.$forceUpdate();
      await this.$nextTick();
  
    // if (this.historico) {

      
      this.exameHistoricos = this.historico;
    // } else if (this.id_exame && this.tipo) {
   
    //   await this.carregaHistoricos();
    // }
  },
};
</script>

<style></style>
