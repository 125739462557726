import axios from "@/utils/axios.js";

const get = async (
  dataDe = "",
  dataAte = "",
  user_id = "",
  tabela = "",
  id_externo = ""
) => {
  let logs = [];

  await axios
    .get("/logs", { params: { dataDe, dataAte, user_id, tabela, id_externo } })
    .then((result) => {
      logs = result.data;
    });

  return logs;
};

const impressoes = {
  get: async (
    dataDe = "",
    dataAte = "",
    user_id = "",
    tabela = "",
    id_externo = ""
  ) => {
    let logs = [];

    await axios
      .get("/logs/impressoes", {
        params: { dataDe, dataAte, user_id, tabela, id_externo },
      })
      .then((result) => {
        logs = result.data;
      });

    return logs;
  },
  store: async ({ id = "", tabela = "", user = "" }) => {
    console.log("tabela", tabela);
    let res;
    await axios
      .post("/logs/impressoes", {
        id,
        tabela,
        user,
      })
      .then((result) => {
        res = result.data;
      })
      .catch(() => {
        //
      });

    return res;
  },
};

export default {
  get,
  impressoes,
};
