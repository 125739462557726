<template>
  <div class="w-100 h-100 my-3">
    <div class="row">
      <div class="col-12 col-sm-4 text-left">
        <div v-if="title != ''">
          <h3 class="mt-2">
            <label> <i class="fa fa-book" /> {{ title }} </label>
          </h3>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        
        <b-input v-model="filtroBusca" placeholder="busca por nome / telefone" debounce="800" />
      </div>
      <div class="col-12 col-sm-4 pt-2">
        <b-btn
          class="float-right mb-2"
          variant="primary"
          @click="novaSolicitacao"
        >
          <i class="fas fa-plus"></i> Nova Solicitação
        </b-btn>
      </div>
    </div>
    <b-table
      :fields="campos"
      :items="solicitacoesFormatadas"
      stacked="sm"
      small
      show-empty
      hover
      selectable
      @row-selected="onRowSelected"
      select-mode="single"
      style="font-size: 13px; text-align: left"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      ref="solicitacoesTable"
    >
      <template #empty>
        <h5 class="text-center my-2">Nenhum solicitação até o momento. 😴</h5>
      </template>

      <template #cell(dt_agendamento)="row">
        {{ row.item.dt_agendamento | moment("DD/MM/YYYY") }}
        <small>
          <small><i class="far fa-clock"/></small>
          {{ row.item.horario_desejado }}
          <br />
          {{ row.item.clinica }}
        </small>
      </template>

      <template #cell(paciente)="row">
        <div class="text-left">
          <i class="fa fa-user" /> {{ row.item.nome }}<br />
          <small><i class="fa fa-phone" /> {{ row.item.telefone }}</small>
        </div>
      </template>

      <template #cell(medico)="row">
        {{ row.item.medico }}<br />
        <small>
          {{ row.item.especialidade }}
        </small>
      </template>

      <template #cell(confirmado)="row">
        <div class="text-md-center">
          <i class="fas fa-check" v-if="row.item.confirmado === 1"></i>
          <i class="fas fa-times" v-else></i>
        </div>
      </template>

      <template #cell(agendado)="row">
        <div class="text-md-center">
          <i class="fas fa-check" v-if="row.item.agendado === 1"></i>
          <i class="fas fa-times" v-else></i>
        </div>
      </template>
      <template #cell(usuario)="row">
        <small>
          {{ row.item.usuario }}<br />
          <small>
            {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
          </small>
        </small>
      </template>
      <template #cell(actions)="row">
        <!-- <b-btn
            variant="primary"
            class="mx-1"
            @click="$refs.AgendasSolicitacoesForm.agendar()"
            size="sm"
          >
            <div class="fas fa-check"></div>
            Agendar
          </b-btn> -->

        <b-btn
          variant="primary"
          class=""
          size="sm"
          @click="excluir(row.item)"
          v-if="!row.item.agendado || row.item.agendado !== 1"
        >
          <div class="fas fa-trash"></div>
          Excluir
        </b-btn>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="solicitacoes.length"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>

    <b-modal id="modal-agenda-solicitacoes" size="lg" no-close-on-backdrop>
      <AgendasSolicitacoesForm
        :solicitacao_edit="solicitacao_edit"
        ref="AgendasSolicitacoesForm"
        @salvou="handleSalvouSolicitacao($event)"
        @abrirAgenda="handleAbrirAgenda($event)"
        :dataSelecionada="dataSelecionada"
        @agendarSolicitacao="handleAgendarSolicitacao($event)"
      />

      <template #modal-title> Nova Solicitação de Agenda </template>

      <template #modal-footer="{ cancel }">
        <div v-if="!solicitacao_edit.agendado">
          <b-btn
            variant="primary"
            @click="$refs.AgendasSolicitacoesForm.salvar()"
          >
            <i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn @click="cancel" variant="secondary">
            <i class="fas fa-ban"></i> Cancelar</b-btn
          >
        </div>
        <div v-else>
          <b-button @click="cancel"><i class="fa fa-times" /> Fechar</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AgendasSolicitacoesForm from "@/components/Agendas/Solicitacoes/AgendasSolicitacoesForm.vue";
import AgendasSolicitacoesLib from "@/libs/AgendasSolicitacoesLib";
import moment from "moment";

export default {
  components: { AgendasSolicitacoesForm },
  props: {
    title: { type: String, default: "Solicitações" },
    dataSelecionada: { type: [Date, Object] },
    filtroData: { Number },
  },
  mounted() {
    this.carregaSolicitacoes();
  },
  data() {
    return {
      solicitacoes: [],
      solicitacoesBusca: [],
      campos: [
        { key: "dt_agendamento", label: "Data / Hora" },
        { key: "paciente", label: "Paciente" },
        { key: "medico", label: "Médico" },
        { key: "tipo", label: "Tipo" },
        { key: "agendado", label: "Agendado", class: "text-center" },
        { key: "usuario", label: "Usuário" },
        { key: "actions", label: "", class: "text-center" },
      ],
      loading: false,
      solicitacao_edit: {},
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      filtroBusca:'',
    };
  },
  sockets: {
    connect() {
      this.carregaSolicitacoes();
    },
    getSolicitacoes(solicitacoes) {
      this.solicitacoes = solicitacoes.map((solicitacao) => {
        return {
          ...solicitacao,
          dt_agendamento: moment(solicitacao.dt_agendamento).format(
            "YYYY-MM-DD"
          ),
        };
      });
    },
    error(message) {
      this.showToast("error", message, 3500);
    },
    success(message) {
      this.showToast("success", message);
    },
  },
  computed: {
    solicitacoesFormatadas() {
      return (this.solicitacoesBusca && this.solicitacoesBusca.length>0?this.solicitacoesBusca: this.solicitacoes)
        .map((solicitacao) => {
          return {
            ...solicitacao,
            _rowVariant: solicitacao.agendado === 1 ?  "success": "danger",
          };
        })
        .sort((a, b) => a.agendado - b.agendado);
    },
  },
  watch: {
    dataSelecionada: {
      deep: true,
      async handler() {
        this.carregaSolicitacoes();
      },
    },
    filtroData: {
      async handler() {
        this.carregaSolicitacoes();
      },
    },
    filtroBusca: function(){
      this.buscaPorNome();
    }
  },
  methods: {
    async buscaPorNome(){
      this.solicitacoesBusca = [];
      if(this.filtroBusca && this.filtroBusca.length>2){
        this.solicitacoesBusca = (await AgendasSolicitacoesLib.buscaPorNome({nome: this.filtroBusca})).data;
        
      }
    },
    novaSolicitacao() {
      this.limpa();
      this.$bvModal.show("modal-agenda-solicitacoes");
    },
    onRowSelected(row) {
      if (row.length > 0) {
        this.limpa();
        let solicitacao = Object.assign({}, row[0]);
        // console.log('-------->', solicitacao);
        delete solicitacao._rowVariant;
        solicitacao.dt_solicitacao = moment(solicitacao.dt_solicitacao).format(
          "YYYY-MM-DD HH:ss"
        );
        // console.log("row", row, "solicitacao", solicitacao);
        if (solicitacao?.id) {
          this.editar(solicitacao);
        }
      }

      this.$refs.solicitacoesTable.clearSelected();
    },
    limpa() {
      for (const key in this.solicitacao_edit) {
        this.solicitacao_edit[key] = null;
      }
    },
    async carregaSolicitacoes() {
      this.$socket.emit(
        "agenda:getSolicitacoes",
        this.dataSelecionada && this.dataSelecionada.start
          ? moment(this.dataSelecionada.start).format("YYYY-MM-DD")
          : moment(this.dataSelecionada).format("YYYY-MM-DD"),
        this.dataSelecionada && this.dataSelecionada.end
          ? moment(this.dataSelecionada.end).format("YYYY-MM-DD")
          : moment(this.dataSelecionada).format("YYYY-MM-DD")
      );
    },
    editar(solicitacao) {
      Object.assign(this.solicitacao_edit, solicitacao);
      this.$bvModal.show("modal-agenda-solicitacoes");
    },
    handleAbrirAgenda(id_agenda) {
      this.$emit("abrirAgenda", id_agenda);
    },
    async handleSalvouSolicitacao(solicitacao) {
      await this.$socket.emit("agenda:storeSolicitacoes", {
        ...solicitacao,
        data: {
          De:
            this.dataSelecionada && this.dataSelecionada.start
              ? moment(this.dataSelecionada.start).format("YYYY-MM-DD")
              : moment(this.dataSelecionada).format("YYYY-MM-DD"),
          Ate:
            this.dataSelecionada && this.dataSelecionada.end
              ? moment(this.dataSelecionada.end).format("YYYY-MM-DD")
              : moment(this.dataSelecionada).format("YYYY-MM-DD"),
        },
      });
      this.carregaSolicitacoes();
      this.$bvModal.hide("modal-agenda-solicitacoes");
    },
    async handleAgendarSolicitacao(solicitacao) {
      await this.$socket.emit("agenda:storeSolicitacoes", {
        ...solicitacao,
        agendar: 1,
        data: {
          De:
            this.dataSelecionada && this.dataSelecionada.start
              ? moment(this.dataSelecionada.start).format("YYYY-MM-DD")
              : moment(this.dataSelecionada).format("YYYY-MM-DD"),
          Ate:
            this.dataSelecionada && this.dataSelecionada.end
              ? moment(this.dataSelecionada.end).format("YYYY-MM-DD")
              : moment(this.dataSelecionada).format("YYYY-MM-DD"),
        },
      });
      this.$bvModal.hide("modal-agenda-solicitacoes");
    },
    async excluir(solicitacao) {
      await this.toastConfirmacao(
        async () => {
          try {
            this.$socket.emit("agenda:deletSolicitacoes", solicitacao.id);
            this.carregaSolicitacoes();
            // this.carregaSolicitacoes();
          } catch (error) {
            this.showToast(
              "error",
              "Ocorreu algum erro ao apagar a solicitação\n" + error.message
            );
          }
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
