import axios from "../utils/axios";

const get = async ({ exame_id = "", tipo, entidade_id = "" }) => {
  // console.log("id exame", exame_id, "tipo", tipo);
  let result = (
    await axios.get("exames/historico_preco", {
      params: { id: exame_id, tipo, entidade_id },
    })
  ).data;
  // console.log("exame/historico_precos", result);
  return result;
};

export default {
  get,
};
