<template>
  <div class="itemCard">
    <div class="num text-center w-25 numPgto">
      <div class="icon" v-if="item.forma == 'Dinheiro'"><b-icon-cash /></div>
      <div class="icon" v-if="item.forma == 'Cartão'">
        <b-icon-credit-card />
      </div>
      <div class="icon" v-if="item.forma == 'PIX'">
        <i class="fa fa-exchange-alt" />
      </div>
      <div class="icon" v-if="item.forma == 'Convenio'">
        <i class="fa fa-industry" />
      </div>
      <div class="icon" v-if="item.forma == 'DEF'">
        <i class="fa fa-user-tag" />
      </div>
      <div class="icon" v-if="item.forma == 'Crédito'">
        <i class="fa fa-wallet" />
      </div>
      {{ item.forma }}
    </div>
    <div class="titulo">
      <div v-if="item.forma == 'Crédito' && item.credito && item.credito.id">
        <small>
          <b>Código: </b> {{ item.credito.id.toString().padStart(5,'0') }}
        </small>
      </div>
      <div v-if="item.forma == 'Cartão'">
        <small>
          <b>Tipo:</b> {{ item.tipo }}<br />
          <b>Bandeira:</b> {{ item.bandeira.nome }} <br />
          <b>Parcelas:</b> {{ item.parcelas }}<br />
          <b>Autorização:</b> {{ item.autorizacao }}<br />
          <b>CV:</b> {{ item.documento }}<br />
        </small>
      </div>
      <div v-if="item.forma == 'Convenio' || item.forma == 'DEF'">
        <small>
          <b>{{ item.forma == "Convenio" ? "Empresa" : "Funcionário" }}:</b>
          {{ item.entidade.razao_social }}<br />
          <b>Parcelas:</b> {{ item.parcelas }}<br />
          <b>Vale/Autorização:</b> {{ item.vale }}<br />
        </small>
      </div>
    </div>
    <div class="valor">
      {{ item.valor }}
    </div>
    <div class="options">
      <a href="#" class="text-warning mr-4" @click.prevent="editar"
        ><i class="fa fa-edit"></i> Editar</a
      >
      <a href="#" @click.prevent="excluir"
        ><i class="fa fa-trash"></i> Remover</a
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
    caixa: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    editar() {
      this.$emit("editar", this.item);
    },
    excluir() {
      this.$emit("excluir", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/venda.scss";
</style>
