<template>
  <div>
    <div>
      <b-form-group>
        <b-dropdown :block="block" :menu-class="{ 'w-100': block }">
          <template #button-content>
            <div class="d-inline" v-if="value">
              <i class="fas fa-cash-register"></i>
              <b> Caixa n° {{ value.caixa_num }}</b>
            </div>
            <div class="d-inline" v-else>
              <i class="fas fa-cash-register"></i> Caixas
            </div>
          </template>
          <small
            class="d-flex justify-content-center"
            v-if="caixas && caixas.length === 0"
          >
            Nenhum caixa aberto...
          </small>
          <b-dropdown-item
            @click="setCaixa(caixa)"
            v-for="caixa of caixas"
            :key="caixa.id"
            :active="value && caixa.id === value.id"
          >
            <small>
              <i class="fas fa-cash-register"></i> ( {{ caixa.empresa }} )
              <b> Caixa n° {{ caixa.caixa_num }}</b>
            </small>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="openCaixaInfo" class="text-center">
            <i class="fas fa-plus"></i> Abrir caixa
          </b-dropdown-item>
        </b-dropdown>
      </b-form-group>
    </div>

    <b-modal size="lg" id="openCaixa" hide-footer>
      <OpenCaixaForm
        @caixa="onCaixa"
        :empresa_id="empresa_id"
        independent
        :numCaixa="caixas&&caixas.length ? (caixas.length + 1) : 1"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import CaixaLib from "../../libs/CaixasLib";
import OpenCaixaForm from "../Caixas/OpenCaixaForm.vue";

export default {
  components: {
    OpenCaixaForm,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    empresa_id: [Number, String],
    block: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      caixas: [],
    };
  },
  methods: {
    async carregar() {
      this.caixas = await CaixaLib.buscarCaixaControle(
        this.empresa_id,
        moment().format("YYYY-MM-DD"),
        null,
        { noClosed: true }
      );
      if (this.caixas.length > 0) this.setCaixa(this.caixas[0]);
    },
    async setCaixa(caixa) {
      // console.log("caixa --->", caixa);
      if (!caixa || !caixa?.id) return;

      // await this.carregar();
      // if (!this.caixas.some((cx) => cx.id === caixa.id)) {
      //   this.showToast('error', 'Não há caixa ou o caixa foi fechado!', 4000)
      //   this.$emit(null);
      //   return;
      // }
      this.$emit("input", caixa);
    },
    openCaixaInfo() {
      this.$bvModal.show("openCaixa");
    },
    closeCaixaInfo() {
      this.$bvModal.hide("openCaixa");
    },
    async onCaixa() {
      this.closeCaixaInfo();
      await this.carregar();

      if (this.caixas.length == 1) this.setCaixa(this.caixas[0]);
    },
  },
  created() {
    this.carregar();
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style></style>
