<template>
  <div>
    <b-tabs>
      <!-- {{exame.imagem}} -->
      <b-tab title="Cadastro">
        <b-form @submit.prevent.stop="salvar()">
          <div class="row mt-3 ">
            <div
              class="col    d-flex
              flex-column
              align-content-start
              justify-content-start
              p-0
              m-0
              pr-sm-2"
            >
              <send-files
                icon="box-seam"
                @fileAddOrchanged="setFile($event)"
                :logo="exame.imagem"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-group>
                <label>Nome</label>
                <b-form-input
                  placeholder="Nome"
                  v-model="$v.exame.nome.$model"
                  :state="validateState('nome')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <b-form-group>
                <label>Descrição</label>
                <b-form-textarea
                  placeholder="Descrição"
                  v-model="$v.exame.descricao.$model"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <b-form-group>
                <label>Tipo</label>
                <b-form-select
                  v-model="$v.exame.tipo.$model"
                  :state="validateState('tipo')"
                  :options="['Exame', 'Consulta', 'Produto', 'Fidelização','Acerto']"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <b-form-group>
                <SelectEspecialidade v-model="exame.especialidade_id" />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6">
              <b-form-group>
                <label>Valor de Venda</label>
                <money
                  class="form-control"
                  v-model="exame.valor_venda"
                  v-bind="moneyMask"
                ></money>

                <b-form-invalid-feedback
                  :force-show="
                    exame.valor_venda != exame_edit.valor_venda &&
                      !exame.justificativa &&
                      editando
                  "
                >
                  Ao alterar o valor de venda, é necessário informar uma
                  justificativa
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-6">
              <b-form-group>
                <label>Valor de Custo</label>

                <money
                  class="form-control"
                  v-model="exame.valor_custo"
                  v-bind="moneyMask"
                ></money>
                <b-form-invalid-feedback
                  :force-show="
                    exame.valor_custo != exame_edit.valor_custo &&
                      !exame.justificativa &&
                      editando
                  "
                >
                  Ao alterar o valor de custo, é necessário informar uma
                  justificativa
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div
            class="row"
            v-if="
              (exame.valor_venda != exame_edit.valor_venda ||
                exame.valor_custo != exame_edit.valor_custo) &&
                editando
            "
          ></div>
          <div class="row mt-3">
            <div class="col-12 col-sm-6 text-center">
              <b-form-group>
                <b-form-checkbox v-model="exame.emite_guia" :value="1" :unchecked-value="0" class="form-control" switch >
                  Emite Guia
                </b-form-checkbox>
                <small class="text-muted">*Marque se irá ser possível emitir guias.</small>
              </b-form-group>
            </div>
          </div>
          <div class="bottom-actions" :style="`position: ${footer}`">
            <hr />
            <b-btn variant="primary" class="mr-3" @click="salvar"
              ><i class="fas fa-save"></i> Salvar</b-btn
            >
            <b-btn variant="secondary" @click="fechar()"
              ><i class="fas fa-ban"></i> Cancelar</b-btn
            >
          </div>
        </b-form>
      </b-tab>
      <b-tab title="Histórico de Preços" v-if="editando">
        <div class="row">
          <div class="col">
            <exame-historico-precos :historico="exame.justificativas" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ExamesLib from "../../libs/ExamesLib";
import ExameHistoricoPrecos from "./ExameHistoricoPrecos.vue";
import SendFiles from "../common/SendFiles.vue";
import moment from "moment";
import SelectEspecialidade from "../common/SelectEspecialidade.vue";

function historicoFactory(
  data=  moment().format('YYYY-MM-DD HH:mm:ss'),
  valor_anterior,
  valor_novo,
  tipo,
  justificativa
) {
  return { data, valor_anterior, valor_novo, tipo, justificativa };
}
export default {
  components: {
    ExameHistoricoPrecos,
    SendFiles,
    SelectEspecialidade,
  },
  mixins: [validationMixin],
  props: {
    editObj: { type: Object, default: () => {} },
    footer: { type: String, default: "relative" },
    editId: [Number, String],
  },
  data() {
    return {
      exame: {
        nome: "",
        especialidade_id: null,
        descricao: "",
        tipo: "",
        valor_venda: 0,
        valor_custo: 0,
        justificativas: [],
        configuracoes: {},
        emite_guia: 1
      },
      editando: false,
      exame_edit: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  validations: {
    exame: {
      nome: { required },
      descricao: {},
      tipo: { required },
      // justificativa: {
      //   required() {
      //     if (!this.editando) return true;
      //     if (this.exame.valor_venda != this.exame_edit.valor_venda)
      //       return !!this.exame.justificativa;
      //     if (this.exame.valor_custo != this.exame_edit.valor_custo)
      //       return !!this.exame.justificativa;
      //     return true;
      //   },
      // },
      // valor_venda: {   required(){
      //   if (this.exame.valor_venda != this.exame_edit.valor_venda)  return !!this.exame.justificativa
      //   if (this.exame.valor_custo != this.exame_edit.valor_custo)  return !!this.exame.justificativa
      // } },
      // valor_custo: {   required(){
      //   if (this.exame.valor_venda != this.exame_edit.valor_venda)  return !!this.exame.justificativa
      //   if (this.exame.valor_custo != this.exame_edit.valor_custo)  return !!this.exame.justificativa
      // } },
    },
  },
  methods: {
    validar() {
      this.$v.exame.$touch();
      if (this.$v.exame.$anyError) {
        return false;
      }

      return true;
    },
    async salvar() {
      //console.log('salvou', this.exame);
      if (this.editando) {
        this.$forceUpdate();
        await this.$nextTick();
        if (this.exame.valor_venda != this.exame_edit.valor_venda) {
          const { value: justificativa } = await this.$swal.fire({
            title: "Justifique a alteração no valor de venda.",
            input: "text",
            inputLabel: "Justificativa",
            inputPlaceholder: "Digite a justificativa",
            inputValidator: (value) => {
              if (!value) {
                return "A justificativa é obrigatória";
              }
            },
          });

          if (justificativa) {
            this.exame.justificativas.push({
              ...historicoFactory(
              moment().format('YYYY-MM-DD HH:mm:ss'),
                this.exame_edit.valor_venda,
                this.exame.valor_venda,
                "venda",
                justificativa
              ),
              _rowStatus: "added",
            });
          } else {
            return;
          }
        }
        if (this.exame.valor_custo != this.exame_edit.valor_custo) {
          const { value: justificativa } = await this.$swal.fire({
            title: "Justifique a alteração no valor de custo.",
            input: "text",
            inputLabel: "Justificativa",
            inputPlaceholder: "Digite a justificativa",
            inputValidator: (value) => {
              if (!value) {
                return "A justificativa é obrigatória";
              }
            },
          });

          if (justificativa) {
            this.exame.justificativas.push({
              ...historicoFactory(
               moment().format('YYYY-MM-DD HH:mm:ss'),
                this.exame_edit.valor_custo,
                this.exame.valor_custo,
                "custo",
                justificativa
              ),
              _rowStatus: "added",
            });
          } else {
            return;
          }
        }
      }
      if (this.validar()) {
        try {
          // console.log('VALOR EXAME ANTES DO FORMAT', this.exame.valor_venda);
          // this.exame.valor_venda = this.formatValorReal(this.exame.valor_venda);
          // this.exame.valor_custo = this.formatValorReal(this.exame.valor_custo);

          // console.log('VALOR EXAME DEPOIS DO FORMAT', this.exame.valor_venda);
          delete this.exame.valor_venda_padrao;
          delete this.exame.valor_custo_padrao;
          this.loading = true;
          let result = await ExamesLib.store(this.exame);
          // console.log('result',result.status)
          if (result.status && result.data) {
            this.showToast("success", "Exame salvo com sucesso!");
            if (this.editando) {
              this.$emit("salvou", this.exame.id);
            } else {
              this.$emit("salvou", result.data[0]);
            }
          }
          this.$bvModal.hide("modal-exames");
        } catch (error) {
          this.showToast(
            "error",
            `Ops! Alguma coisa deu errado :/\n${error.message}`,
            7000
          );
        } finally {
          this.loading = false;
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.exame[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      for (const key in this.exame) {
        this.exame[key] = null;
      }

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    setFile(file) {
      this.$forceUpdate();
      this.exame.imagem = file;
    },
    fechar() {
      this.$emit("close");
    },
  },

  async created() {
    await this.$nextTick();
    this.$forceUpdate();

    if (this.editId) {
      this.editando = true;
      this.exame_edit = (await ExamesLib.get(null, null, this.editId))[0];
      this.exame = JSON.parse(JSON.stringify(this.exame_edit));
      // console.log(this.exame);
    } else if (this.editObj) {
      if (this.editObj.id) {
        this.editando = true;
        this.exame_edit = JSON.parse(JSON.stringify(this.editObj));
        // this.exame_edit.valor_venda = this.formatValorReal(
        //   this.exame_edit.valor_venda
        // );
        // this.exame_edit.valor_custo = this.formatValorReal(
        //   this.exame_edit.valor_custo
        // );
        Object.assign(this.exame, this.exame_edit);
      }
    }
  },
  computed: {},
};
</script>

<style></style>
