<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner></b-spinner>
      carregando...
    </div>
    <div
      class="row"
      v-if="venda.id"
    >
      <div class="col-6">
        <b>Venda</b><br />
        {{ venda.id }}
      </div>
      <div class="col-6">
        <b>Data</b><br />
        {{ venda.created_at | moment("DD/MM/YYYY HH:mm") }}
      </div>
      <div class="col-12 mt-2">
        <b>Cliente</b><br />
        {{ venda.Cliente }}
      </div>
      <div class="col-12 mt-2">
        <b>Itens</b><br />
        <b-table
          style="font-size: 11px"
          :items="venda.itens"
          :fields="[
            { key: 'sel', title: '' },
            { key: 'descricao', title: 'Exame/Consulta' },
            { key: 'fornecedor', title: 'Fornecedor/Médico/Laboratório' },
            { key: 'vl_total', title: 'valor' },
          ]"
          selectable
          hover
          select-mode="multi"
          selected-variant="primary"
          @row-selected="onRowSelected"
          ref="table"
        >
          <template #cell(sel)="row">
            <b-checkbox
              v-model="row.item.selected"
              disabled
            ></b-checkbox>
          </template>
          <template #cell(descricao)="row">
            <div>
              {{ row.item.descricao }}
              <div v-if="row.item.exames && row.item.exames.exames">
                <ul>
                  <li
                    v-for="it in row.item.exames.exames"
                    :key="row.item.id + it.id"
                  >
                    {{ it.nome }}
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template #cell(fornecedor)="row">
            {{ row.item.fornecedor }}

            <div
              style="font-size: 9px"
              class="text-danger"
              v-if="row.item.pagamentos && row.item.pagamentos.length > 0"
            >
              Este item está no pagamento do médico código
              {{ row.item.pagamentos[0].id }}
              <br />
              Pagamento para
              {{ row.item.pagamentos[0].vencimento | moment("DD/MM/YYYY") }}.
              <b
                ><br />Será gerado abatimento / dívida do médico para próximo
                pagamento</b
              >
            </div>
          </template>
          <template #cell(vl_total)="row">
            {{ row.item.vl_total | currency }}
          </template>
        </b-table>
      </div>
      <div class="col-12"><hr /></div>
      <div class="col-6 mt-2">
        <b>Valor Total</b><br />
        {{ devolucao.vl_total | currencyWithMask }}
      </div>
      <div class="col-6 mt-2">
        <b>Valor Estornar</b><br />
        <money
          class="form-control"
          v-bind="moneyMask"
          v-model="devolucao.vl_estorno"
        ></money>
      </div>
      <div class="col-12"><hr /></div>

      <div class="col-12 mt-2">
        <b>Tipo de devolução</b><br />
        <b-radio-group
          class="my-2"
          :options="['Crédito Cliente', 'Devolução Dinheiro']"
          v-model="devolucao.tipo_devolucao"
        >
        </b-radio-group>
        <div
          class="alert alert-info"
          v-if="devolucao.tipo_devolucao"
        >
          <span v-if="devolucao.tipo_devolucao == 'Devolução Dinheiro'">
            Será debitado do caixa o valor informado de dinheiro!
          </span>
          <span v-else>
            Será gerado um crédito para o cliente no valor informado!
          </span>
        </div>
        <div v-if="venda && devolucao.tipo_devolucao == 'Devolução Dinheiro'">
          <b>Selecione o caixa de onde será retirado o dinheiro</b>

          <select-caixa
            :empresa_id="venda.empresa_id"
            v-model="caixaControle"
          />
        </div>
      </div>
      <div class="col-12"><hr /></div>

      <div class="col-12 mt-2">
        <b>Motivo Devolução / Observações</b>
        <b-textarea v-model="devolucao.motivo"></b-textarea>
      </div>
    </div>
    <BotoesSalvarCancelar
      @salvar="salvar"
      @cancelar="$emit('cancelar')"
    ></BotoesSalvarCancelar>
  </div>
</template>

<script>
import VendaLib from "../../../libs/VendaLib";
import BotoesSalvarCancelar from "../../common/BotoesSalvarCancelar.vue";

export default {
  components: {
    BotoesSalvarCancelar,
    SelectCaixa: () => import("../../common/SelectCaixa.vue"),
  },
  props: {
    vendaId: {
      type: Number,
    },
  },
  mounted() {
    console.log("valor", this.vendaId);
    this.loadVenda();
  },
  data() {
    return {
      caixaControle: null,
      devolucao: {
        id: 0,
        user_id: 0,
        venda_id: 0,
        itens: [],
        vl_total: 0,
        vl_estorno: 0,
        vl_custo: 0,
        motivo: "",
        tipo_devolucao: "Crédito Cliente",
        cliente_id: 0,
        caixa_devolucao_id: null,
      },
      venda: {
        itens: [],
        id: 0,
        vl_total: 0,
        Cliente: "",
        clinica: "",
      },
      selecionado: {
        vl_total: 0,
        vl_estorno: 0,
      },
      loading: false,
    };
  },
  watch: {
    caixaControle: {
      deep: true,
      handler() {
        console.log("caixa", this.caixaControle);
        if (this.caixaControle) {
          this.devolucao.caixa_devolucao_id = this.caixaControle.id;
        } else {
          this.devolucao.caixa_devolucao_id = null;
        }
      },
    },
  },
  computed: {},
  methods: {
    async salvar() {
      if (this.venda && this.venda.itens && this.venda.itens.length > 0) {
        this.devolucao.itens = this.venda.itens.filter((it) => it.selected);
      }
      console.log(this.devolucao);

      if (!this.devolucao.tipo_devolucao) {
        this.$bvToast.toast("Informe o tipo de devolução", {
          title: "Atenção",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (
        (this.devolucao.tipo_devolucao == "Devolução Dinheiro" &&
          !this.devolucao.caixa_devolucao_id) ||
        this.devolucao.caixa_devolucao_id == 0
      ) {
        this.$bvToast.toast(
          "Você precisa informar o caixa que retirar o dinheiro para devolução!",
          {
            title: "Atenção",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }
      if (
        !this.devolucao.vl_estorno ||
        this.devolucao.vl_estorno == 0 ||
        this.devolucao.vl_estorno == "R$ 0,00"
      ) {
        this.$bvToast.toast("Informe o valor a estornar", {
          title: "Atenção",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (!this.devolucao.itens || this.devolucao.itens.length <= 0) {
        this.$bvToast.toast("Selecione ao menos um item para devolução", {
          title: "Atenção",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (
        !this.devolucao.vl_total ||
        this.devolucao.vl_estorno > this.devolucao.vl_total
      ) {
        this.$bvToast.toast(
          "Valor a estornar não pode ser maior que o valor total",
          {
            title: "Atenção",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }

      if (!this.devolucao.motivo || this.devolucao.motivo.trim().length <= 0) {
        this.$bvToast.toast("Informe o motivo da devolução", {
          title: "Atenção",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.loading = true;
      let res = await VendaLib.devolver(this.devolucao);
      console.log("res", res);
      if (res && res.success) {
        this.$bvToast.toast("Devolução realizada com sucesso!", {
          title: "Sucesso",
          variant: "success",
          solid: true,
        });
        this.$emit("salvo", res);
      } else {
        this.$bvToast.toast("Erro ao salvar devolução!", {
          title: "Erro",
          variant: "danger",
          solid: true,
        });
      }
      this.loading = false;
    },
    async loadVenda() {
      if (this.vendaId) {
        this.devolucao.venda_id = this.vendaId;
        this.loading = true;
        let res = await VendaLib.buscarVendas({
          "vendas.id": this.vendaId,
          withFornPayment: true,
        });
        console.log("res", res);
        if (res && res.length > 0) {
          this.venda = res[0];
          if (this.venda.devolucoes && this.venda.devolucoes.length > 0) {
            for (let devolu of this.venda.devolucoes) {
              if (typeof devolu.itens == "string") {
                devolu.itens = JSON.parse(devolu.itens);
              }
            }
          }
          if (this.venda.itens && this.venda.itens.length > 0) {
            this.venda.itens.forEach((it) => {
              it.selected = false;
              it.disabled =
                this.venda.devolucoes &&
                this.venda.devolucoes.length > 0 &&
                this.venda.devolucoes.some((d) =>
                  d.itens.some((i) => i.id == it.id)
                );
              it._rowVariant = it.disabled ? "secondary" : "";
            });
          }
          this.devolucao.venda_id = this.venda.id;
          this.devolucao.user_id = this.$store.state.auth.user.id;
          this.devolucao.cliente_id = this.venda.paciente_id;
          console.log(this.devolucao);
        }
      }
      this.loading = false;
    },
    async onRowSelected(items) {
      console.log("items", items);
      if (
        this.venda &&
        this.venda.devolucoes &&
        this.venda.devolucoes.length > 0
      ) {
        if (
          this.venda.devolucoes.some((d) =>
            d.itens.some((i) => items.some((it) => it.id == i.id))
          )
        ) {
          this.$bvToast.toast("Este item já foi devolvido!", {
            title: "Atenção",
            variant: "danger",
            solid: true,
          });
          this.$refs.table.clearSelected();
          items[0].selected = false;
          return;
        }
      }
      if (this.venda && this.venda.itens) {
        this.venda.itens.forEach((it) => (it.selected = false));
      }
      items.forEach((it) => (it.selected = true));
      this.devolucao.vl_total = items.reduce((a, b) => a + b.vl_total, 0);
      this.devolucao.vl_estorno = items.reduce((a, b) => a + b.vl_total, 0);
      this.devolucao.vl_custo = items.reduce((a, b) => a + b.vl_custo, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
