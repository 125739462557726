<template>
  <div>
    <div class="row">
      <div class="col">
        <h5>Olho Direito</h5>

        <div class="col">
          <div class="row">
            <div class="col">
              <label>Esférico</label>
              <b-input
                type="number"
                v-model="oftalmo.od.esferico"
                class=""
                size="sm"
              >
              </b-input>
            </div>
            <div class="col">
              <label>Cilindrico</label>
              <b-input
                type="number"
                v-model="oftalmo.od.cilindrico"
                class=""
                size="sm"
              >
              </b-input>
            </div>
            <div class="col">
              <label>Eixo</label>
              <b-input
                type="number"
                v-model="oftalmo.od.eixo"
                class=""
                size="sm"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <h5>Olho Esquerdo</h5>

        <div class="col">
          <div class="row">
            <div class="col">
              <label>Esférico</label>
              <b-input
                type="number"
                v-model="oftalmo.oe.esferico"
                class=""
                size="sm"
              >
              </b-input>
            </div>
            <div class="col">
              <label>Cilindrico</label>
              <b-input
                type="number"
                v-model="oftalmo.oe.cilindrico"
                class=""
                size="sm"
              >
              </b-input>
            </div>
            <div class="col">
              <label>Eixo</label>
              <b-input
                type="number"
                v-model="oftalmo.oe.eixo"
                class=""
                size="sm"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <h5>Adição</h5>

        <div class="col">
          <div class="row">
            <div class="col-12 col-sm-4">
              <label>Adição</label>
              <b-input
                type="number"
                v-model="oftalmo.adicao"
                class=""
                size="sm"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oftalmo: Object,
  },
  watch: {
    oftalmo: {
      deep: true,
      handler() {
        this.$emit("change", this.oftalmo);
      },
    },
  },
  mounted() {},
};
</script>

<style></style>
