<template>
  <div class="row">
    <div class="col-9 pr-1">
      <vue-simple-suggest
        :disabled="disabled"
        v-model="exameSel"
        @select="onSelect"
        :list="examesLista"
        :styles="autoCompleteStyle"
        :filter-by-query="false"
        :min-length="minLength"
        :debounce="200"
        display-attribute="nome"
        mode="select"
        :controls="{
          selectionUp: [38, 33],
          selectionDown: [40, 34],
          select: [13, 36],
          showList: [40],
          hideList: [27, 35, 13],
        }"
      >
        <div slot="suggestion-item" slot-scope="{ suggestion }">
          {{ suggestion.nome }}

          <small v-if="suggestion.tipo">({{ suggestion.tipo }})</small>
          <br />
          <small class="text-muted">{{ suggestion.descricao }}</small>

          <small>
            <div class="d-flex justify-content-between">
              <small v-if="suggestion.valor_venda"
                >Vl. Venda:
                {{ suggestion.valor_venda | currencyWithMask }}</small
              >
              <small v-if="suggestion.valor_custo"
                >Vl. Custo:
                {{ suggestion.valor_custo | currencyWithMask }}</small
              >
            </div>
          </small>
        </div>
      </vue-simple-suggest>
      <small class="text-muted">
        {{ description }}
        {{ `${minLength ? `Minimo de letras: ${minLength}` : ""}` }}
      </small>
    </div>
    <div
      class="col-1 pl-0 pr-0 mr-0 text-center"
      v-if="exameSel && exameSel.id && exameSel.id > 0 && showBotaoEdit"
    >
      <b-button variant="primary" size="md" @click="editarExame">
        <i class="fa fa-edit" />
      </b-button>
    </div>
    <div class="col-1 mr-0 pl-0 ml-0 text-center" v-if="showBotaoAdd">
      <b-button
        :disabled="disabled"
        variant="primary"
        size="md"
        @click="addExame"
      >
        <i class="fas fa-plus"></i>
      </b-button>
    </div>
    <div class="col-1 pl-0 ml-0 text-center" v-if="showBotaoSearch">
      <b-button
        :disabled="disabled"
        variant="primary"
        size="md"
        @click="openSearch"
        v-b-tooltip.hover
        title="Clique para uma busca mais detalhada."
      >
        <i class="fas fa-search"></i>
      </b-button>
    </div>

    <b-modal id="modal-exames" size="lg" hide-footer no-enforce-focus>
      <template #modal-title>
        <i class="fas fa-hand-holding-medical"></i>
        <!-- {{ typeof exame_edit.id === "number" ? "Editar" : "Adicionar" }} -->
        Adicionar Produto/Serviço
      </template>
      <ExamesForm
        footer="unset"
        @salvou="changeExame($event)"
        :editId="editId"
        @close="closeModal('modal-exames')"
      />
    </b-modal>

    <b-modal  size="xl" hide-footer id="modalSearchExames">
      <template #modal-title>
        <i class="fas fa-search"></i> Buscar Exames / Serviços / Produtos
      </template>
      <search-exames
      :tabs="searchTabs"
      :entidadeId="entidadeId"
        @input="onSelect($event)"
        @close="closeSearch"
      ></search-exames>
    </b-modal>
  </div>
</template>

<script>
import ExamesLib from "../../libs/ExamesLib";
import ExamesForm from "../Exames/ExamesForm.vue";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import SearchExames from "./SearchExames.vue";
export default {
  components: {
    VueSimpleSuggest,
    ExamesForm,
    SearchExames,
  },
  props: {
    showBotaoAdd: {
      type: Boolean,
      default: true,
    },
    minLength: {
      type: [Number, String],
      default: 3,
    },
    showBotaoEdit: {
      type: Boolean,
      default: true,
    },
    showBotaoSearch: {
      type: Boolean,
      default: true,
    },
    description: {
      type: [String],
      default: `* Comece a digitar para buscar.`,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFidelizacoes: {
      type: Boolean,
      default: false,
    },
    selecionado: [Number, String],
    value: Object,
    withJustificativas: {
      type: Boolean,
      default: false,
    },
    tipo: {
      type: String,
      default: "",
    },
    tabela: {
      type: String,
      default: "exames",
    },
    entidadeId: { type: [Number, String], default: "" },
    searchTipo: {
      type: Boolean,
      default: false,
    },
    getWithFilters: {
      type: Boolean,
      default: true,
    },
  
    searchTabs: {type: Array, default: ()=>["Exame", "Consulta", "Produto", "Serviço", "Fidelização"]}
  },
  created() {
    this.carregar();
  },
  data() {
    return {
      exameSel: {
        id: "",
        nome: "",
      },
      loading: false,
      editId: "",
      autoCompleteStyle: {
        defaultInput: "form-control ",
        suggestions:
          "position-absolute list-group z-1000 auto-complete-suggestion-correction",
        suggetItem: "suggestItem",
        lastFind: "",
      },
    };
  },
  watch: {
    exameSel: function() {},
    selecionado: async function() {
      // console.log('select',this.selecionado);
      this.$forceUpdate();
      await this.$nextTick();
      await this.carregar();
      this.$emit("input", this.exameSel);
    },
    value: {
      deep: true,
      handler() {
        // console.log('value',this.value)
        if (this.value && this.value.nome) {
          this.exameSel = this.value;
        }
      },
    },
  },
  computed: {},
  methods: {
    onSelect(exame) {
      //console.log('onSelect', exame);
      if (exame?.id) {
        this.exameSel = Object.assign({}, exame);
        this.$emit("select", exame);
        this.$emit("input", exame);
      }
    },
    openSearch() {
      this.$bvModal.show("modalSearchExames");
    },
    closeSearch() {
      this.$bvModal.hide("modalSearchExames");
    },

    async changeExame(id) {
      //console.log('changeExame',id);
      this.selecionado = id;
      await this.carregar();
      this.onSelect(this.exameSel);
    },
    closeModal(nome) {
      this.$bvModal.hide(nome);
    },
    addExame() {
      this.editId = "";
      this.$bvModal.show("modal-exames");
    },
    editarExame() {
      this.editId = this.exameSel.id;
      this.$bvModal.show("modal-exames");
    },
    async carregar() {
      this.$nextTick();
      this.$forceUpdate();
      // console.log('selecionado',this.selecionado)
      if (this.selecionado) {
        let res;
        if (this.withJustificativas && this.tabela) {
          res = (
            await ExamesLib.get(this.tabela, this.entidadeId, this.selecionado)
          ).result;
        } else {
          res = await ExamesLib.getWithFilters({
            id: this.selecionado,
            tipo: this.tipo,
          });
        }

        if (res && res.length > 0) {
          this.exameSel = res[0];
        }
        // console.log('buscou selecionado',this.selecionado, this.pacienteSel)
      }
    },
    examesLista(busca) {
      // console.log('BUSCOOU', busca);
      if (!busca.trim()) return;
      if (busca != this.lastFind) {
        this.lastFind = busca;
        let tipo = this.$props.tipo;
        let searchTipo = this.$props.searchTipo;
        let getWithFilters = this.$props.getWithFilters;

        return new Promise(function(resolve, reject) {
          // console.log("start busca", busca);

          let lista = getWithFilters
            ? ExamesLib.getWithFilters({
                nome: busca,
                tipo,
                searchTipo,
              })
            : ExamesLib.get(tipo, null, null, busca);

          lista
            .then((res) => {
              //console.log(res);
              resolve(res);
            })
            .catch(() => {
              reject();
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
