var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.printNow)?_c('b-modal',{attrs:{"id":"modal-print-gruia","no-enforce-focus":"","no-fade":"","hide-backdrop":"","hide-footer":"","size":"lg"},on:{"hide":function($event){_vm.printNow = false;
      _vm.$emit('close');}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.print}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1),_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.download}},[_c('b-icon-download'),_vm._v(" Baixar")],1),_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.sendWpp}},[_c('b-icon-whatsapp'),_vm._v(" Enviar via Whatsapp")],1)]},proxy:true}],null,false,333360855)},[_c('div',{attrs:{"id":"printDiv"}},[(_vm.item)?_c('print-component',{attrs:{"filename":`Guia ${_vm.item.paciente}`,"printNow":_vm.printNow,"titulo":`Encaminhamento de Exame/Consulta`,"show":true},on:{"done":function($event){return _vm.$emit('done')}}},[_c('section',{staticClass:"pdf-item"},[_c('div',{staticClass:"row mt-2 p-2",staticStyle:{"font-size":"12px"}},[_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Código:")]),_vm._v(" "+_vm._s(_vm.item.id)+" ")]),_c('div',{staticClass:"col-6"},[_c('b',[_vm._v("Impresso em:")]),_vm._v(" "+_vm._s(_vm.data)+" ")]),_c('div',{staticClass:"col-6"},[_c('b',[_vm._v("Hora da Consulta:")]),_vm._v(" "+_vm._s(_vm.item.exames && _vm.item.exames.hora_consulta ? _vm.item.exames.hora_consulta.slice(0, 5) : "")+" ")]),_c('div',{staticClass:"col-6"},[_c('b',[_vm._v("Data Solicitação:")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.item.exames ? _vm.item.exames.data : "","DD/MM/YYYY"))+" ")]),_c('div',{staticClass:"col-6"},[_c('b',[_vm._v("Paciente:")]),_vm._v(" "+_vm._s(_vm.item.paciente)+" ")]),_c('div',{staticClass:"col-12 mt-1"},[_c('b',[_vm._v("Médico Solicitante:")]),_vm._v(" "+_vm._s(_vm.item.exames ? _vm.item.exames.medicoNome : "")+" ")]),_c('div',{staticClass:"col-12 mt-1"},[_c('b',[_vm._v("Laboratório:")]),_vm._v(" "+_vm._s(_vm.item.fornecedor)+" ")]),(
                _vm.item.fornecedor_enderecos &&
                _vm.item.fornecedor_enderecos.length > 0
              )?_c('div',{staticClass:"col-12 mt-1"},[(
                  _vm.item.fornecedor_enderecos &&
                  _vm.item.fornecedor_enderecos.length > 0
                )?_c('div',{staticClass:"mt-1"},[_c('small',_vm._l((_vm.item.fornecedor_enderecos),function(endereco,key){return _c('div',{key:key},[_c('b',[_vm._v("Endereço "+_vm._s(key + 1)+":")]),_vm._v(" "+_vm._s(endereco.logradouro)+","+_vm._s(endereco.numero)+" - "+_vm._s(endereco.bairro)+" | "+_vm._s(endereco.cidade)+" - "+_vm._s(endereco.uf)+" "),_c('br'),(
                        endereco.complemento &&
                        endereco.complemento != '' &&
                        endereco.complemento != '0' &&
                        endereco.complemento != '00'
                      )?_c('small',[_c('b',[_vm._v("Complemento:")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(endereco.complemento))]):_vm._e()])}),0)]):_vm._e()]):_c('div',{staticClass:"col-12 mt-1"},[_c('b',[_vm._v("Endereço: ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Nenhum endereço cadastrado...")])]),(
                _vm.item.fornecedor_telefones &&
                _vm.item.fornecedor_telefones.length > 0
              )?_c('div',{staticClass:"col-12 mt-2"},[_c('b',[_vm._v("Telefone(s): ")]),_vm._l((_vm.item.fornecedor_telefones),function(tel){return _c('span',{key:`tel${tel.telefone}`,staticClass:"mr-2"},[_vm._v(" "+_vm._s(tel.telefone))])})],2):_vm._e()])]),_c('section',{staticClass:"pdf-item"},[_c('br'),_c('b',[_vm._v("Exame(s) / Consulta(s)")]),_c('hr'),_c('div',{staticClass:"row p-1",staticStyle:{"font-size":"11px"}},_vm._l((_vm.item.exames.exames),function(exame){return _c('div',{key:`exame_${exame.itemId}`,staticClass:"col-12 pb-1"},[_c('b',[_vm._v(_vm._s(_vm.item.exames.exames.indexOf(exame) + 1)+".")]),_vm._v(" "+_vm._s(exame.nome)+" "),_c('hr',{staticClass:"pt-0 pb-0 mt-1 mb-1"})])}),0),_c('div',{staticClass:"row justify-content-end align-content-end",staticStyle:{"margin-top":"200px"}},[_c('div',{staticClass:"col-7 border-top"},[_c('b',[_vm._v(_vm._s(_vm.item.usuario ? _vm.item.usuario : _vm.item.exames.usuario))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8",staticStyle:{"font-size":"10px"}},[_vm._v(" Para validar basta ler o QRcode ao lado ou abrir o link "),_c('a',{attrs:{"href":`https://web.saudesocial.net/guia/${_vm.baseConv(
                      _vm.item.id
                    )}`,"target":"blank"}},[_vm._v("https://web.saudesocial.net/guia/"+_vm._s(_vm.baseConv(_vm.item.id)))]),_c('div',[_c('b',[_vm._v("Esta guia tem validade de 30 dias após sua emissão. Após vencimento não será possível solicitar estorno ou reembolso, nem será elegível para troca ou substituição.")])])]),_c('div',{staticClass:"col-4 text-right"},[_c('div',{staticClass:"float-right"},[_c('qr-code',{attrs:{"text":`https://web.saudesocial.net/guia/${_vm.baseConv(
                        _vm.item.id
                      )}`,"size":50,"error-level":"L"}})],1)])])])])])]):_vm._e()],1)]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-wpp","hide-footer":"","title":"Enviar guia para whatsapp"}},[_c('b-form-group',{attrs:{"label":"Número"}},[_c('vue-simple-suggest',{ref:"select-num-wpp",attrs:{"suggestion-click":true,"list":_vm.telefonesDisponiveis,"filter-by-query":true,"mode":"select","controls":{
          selectionUp: [38, 33],
          selectionDown: [40, 34],
          select: [13, 36],
          showList: [40],
          hideList: [27, 35, 13],
        }},model:{value:(_vm.wppNumber),callback:function ($$v) {_vm.wppNumber=$$v},expression:"wppNumber"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wppNumber),expression:"wppNumber"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],staticClass:"form-control optional-custom-input",attrs:{"autocomplete":"off","id":`txtField${Math.random()}`,"name":`txtField${Math.random()}`},domProps:{"value":(_vm.wppNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.wppNumber=$event.target.value}}})]),(_vm.wppAlert.show)?_c('b-alert',{attrs:{"variant":_vm.wppAlert.type}},[_vm._v(" "+_vm._s(_vm.wppAlert.message)+" ")]):_vm._e(),_c('botoes-salvar-cancelar',{attrs:{"okText":'Enviar',"okIcon":'fab fa-whatsapp',"saving":_vm.enviandoWpp},on:{"salvar":_vm.enviarWpp,"cancelar":() => _vm.$bvModal.hide('modal-wpp')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }