<template>
  <div>
    <b-modal
      v-if="printNow"
      id="modal-print-gruia"
      no-enforce-focus
      no-fade
      hide-backdrop
      hide-footer
      hide-header-close
      hide-header
      @hide="
        printNow = false;
        $emit('close');
      "
    >
      <print-component
        :filename="`Solicitação de Exame ${item.medicoNome}`"
        v-if="item"
        :printNow="printNow"
        @done="$emit('done')"
        :titulo="`Solicitação de Exame(s)`"
      >
        <div class="row mt-2 p-2">
          <div class="col-6">
            <b>Data:</b>
            {{ data }}
          </div>
          <!-- <div class="col-6">
            <b>Data Consulta:</b>
            {{ item.data | moment("DD/MM/YYYY") }}
          </div>
          <div class="col-6">
            <b>Hora da Consulta:</b>
            {{item.hora_consulta?item.hora_consulta.slice(0,5):'' }}
          </div> -->

          <div class="col-12 mt-1">
            <b>Médico Solicitante:</b>
            {{ item.medicoNome }}
          </div>
          <!-- <div class="col-12 mt-1">
            <b>Especialidade:</b>
            {{ item.especialidade }}
          </div> -->
          <!-- <div class="col-12 mt-1">
            <b>Laboratório:</b>
            {{ item.fornecedor }}
          </div> -->
          <!-- <div
            class="col-12 mt-1"
            v-if="
              item.fornecedor_enderecos && item.fornecedor_enderecos.length > 0
            "
          >
            <b>Endereço:</b>
            {{ item.fornecedor_enderecos[0].logradouro }},{{
              item.fornecedor_enderecos[0].numero
            }}
            - {{ item.fornecedor_enderecos[0].bairro }}
            |
            {{ item.fornecedor_enderecos[0].cidade }} -
            {{ item.fornecedor_enderecos[0].uf }} <br />
            <small
              v-if="
                item.fornecedor_enderecos[0].complemento &&
                  item.fornecedor_enderecos[0].complemento != ''
              "
              ><b>Complemento:</b> <br />
              {{ item.fornecedor_enderecos[0].complemento }}</small
            >
          </div> -->
          <!-- <div
            class="col-12 mt-1"
            v-if="
              item.fornecedor_telefones && item.fornecedor_telefones.length > 0
            "
          >
            <b>Telefone(s): </b>
            <span
              class="mr-2"
              v-for="tel in item.fornecedor_telefones"
              :key="`tel${tel.telefone}`"
            >
              {{ tel.telefone }}</span
            >
          </div> -->
        </div>
        <hr />
        <div class="row p-3">
          <div
            class="col-12 pb-1"
            v-for="exame of item.exames"
            :key="`exame_${exame.id}`"
          >
            <b>{{ item.exames.indexOf(exame) + 1 }}.</b>
            {{ exame.nome }} <br /><small
              v-if="exame.descricao && exame.descricao.length > 0"
              class="pl-3"
              >{{ exame.descricao }}</small
            >
            <hr />
          </div>
        </div>
        <div
          class="row justify-content-end align-content-end"
          style="margin-top: 700px"
        >
          <div class="col-7 border-top">
            <b>{{ item.medicoNome }}</b>
            <small> <b> CRM: </b> {{ item.medicoCRM.join(", ") }}</small>
          </div>
        
        </div>
      </print-component>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../../../Print/PrintComponent.vue";
import moment from "moment";
import SolicitacaoExamesLib from "../../../../libs/SolicitacaoExamesLib";
import LogsLib from "../../../../libs/LogsLib";

export default {
  components: {
    PrintComponent,
  },
  props: {
    infos: {
      type: Object,
      default: null,
    },
    id: [Number, String],
  },
  mounted() {
    if (this.id) {
      this.carregaInfos();
    } else if (this.infos) {
      this.item = Object.assign(this.item, this.infos);
      this.$nextTick(() => {
        this.open();
      });
    }
  },
  data() {
    return {
      printNow: false,
      loading: true,
      data: moment().format("DD/MM/YYYY"),
      item: {},
    };
  },
  watch: {
    printNow: function() {},
  },
  computed: {},
  methods: {
    async open() {
      console.log(this.item);

      this.printNow = true;
      await this.$nextTick();
      LogsLib.impressoes.store({
        id: this.item?.id,
        tabela: "paciente_solicitacoes_exames",
      });
      this.$bvModal.show("modal-print-gruia");
    },
    async carregaInfos() {
      this.item = (await SolicitacaoExamesLib.get({ id: this.id }))[0];
      await this.$nextTick();
      await this.open();
    },
  },
};
</script>

<style lang="scss" scoped></style>
