<template>
  <div>
   
    <div class="row justify-content-center">
      <div class="col">
        <select-caixa
        :value="caixa"
        @input="onCaixa"

          :empresa_id="empresa_id"
          block
        ></select-caixa>
      </div>
    </div>

     <div class="row">
      <div class="col text-center">
        <b-form-group class="form-control">
          <b-form-radio-group @change="carregarListagem" v-model="caixa.somenteNaoVinculadas" :options="[{text: 'Não vinculadas', value: 1}, {text: 'Todas', value: 0}]"></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div
      class="row"
      v-if="
        listagem &&
          listagem.lancamentos &&
          listagem.lancamentos.length > 0 &&
          !loading
      "
    >
      <div class="col-12">
        <VendaCads @input="onVenda" v-model="venda" :listagem="listagem" />
      </div>
    </div>

    <div
      v-if="!loading &&listagem.lancamentos && listagem.lancamentos.length === 0"
      class="text-center  d-flex flex-column p-2"
    >
      <div class="justify-content-center py-2 ">
        <img
          width="100%"
          height="125px"
          :src="require('@/assets/img/undraw_No_data_re_kwbl.svg')"
          alt=""
        />
        <p class="mt-2">
          <i class="fas fa-frown text-secondary"></i> Nenhuma venda
          encontrada...
        </p>
      </div>
    </div>

    <div v-if="loading" class="">
      <div class="d-flex text-muted justify-content-center">
        <h4><b-icon animation="cylon" icon="search"></b-icon> Buscando...</h4>
      </div>
    </div>

    <div class="row mt-3 border-top">
      <div class="col pt-3">
        <b-btn @click="$emit('close')" class=" d-block ml-auto">
          <i class="fas fa-ban"></i> Cancelar</b-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import SelectCaixa from "../common/SelectCaixa.vue";
import CaixasLib from "../../libs/CaixasLib";
import VendaCads from "./VendaListagem.vue";
import HorariosLib from "../../libs/HorariosLib";

export default {
  props: {
    empresa_id: {
      type: [String, Number],
      required: true,
    },
    horario: [Object],
  },
  components: {
    SelectCaixa,
    VendaCads,
  },
  data() {
    return {
      caixa: {somenteNaoVinculadas:1, filterCanceled: true},
      listagem: {lancamentos: []},
      loading: false,
      venda: {},
    };
  },
  methods: {
    async carregarListagem() {
      this.loading = true;
      if (this.caixa && this.caixa?.id) {
        let listagem = await CaixasLib.listagemCaixa(this.caixa);
        if (listagem) {
          this.listagem = Object.assign(this.listagem, listagem)
        }
      }
      this.loading = false;
    },
    onCaixa(caixa){
      this.caixa = Object.assign(this.caixa, caixa)
      this.carregarListagem()
    },
    async onVenda(venda) {
      if (!venda?.id || !venda) return;
      await this.$swal({
        title: "Tem certeza que deseja vincular essa venda ao paciente?",
        html: `<p>Verifique se os dados estão corretos!</p>`,

        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (re) => {
        if (re.isConfirmed) {
          await this.vincularVenda();
        }
      });
    },
    async vincularVenda() {
      let error = []

      if(!this.venda?.id ) error.push('Nenhuma venda selecionada')
      if(!this.venda?.vendaitemId  ) error.push('Erro no item da venda selecionada')
      if(!this.horario?.id ) error.push('Erro no horário')

      if (error.length > 0) {
        this.$swal({
          title: "Houve um problema",
          html: `<b>Erro ao executar a operação</b><hr>${error.reduce((ret, vl)=>ret+=`<hr>${vl}`)}`,
          icon: "error",
        });
        return;
      }
      try {
        const result = await HorariosLib.vincularVenda(
          this.horario?.id,
          this.venda?.id,
          this.venda?.vendaitemId
        );

        if (result) {
          this.$emit("vinculou", { venda: this.venda });
        }
      } catch (error) {
        this.$swal({
          title: "Houve um problema",
          html: `<b>Erro ao executar a operação</b> <hr/> ${error.message}`,
          icon: "error",
        });
      }
    },
  },
  watch: {
    "caixa.id": {
      handler() {
        if (this.caixa?.id) this.carregarListagem();
      },
    },
    
  },
  created() {
    this.carregarListagem();
  },
};
</script>

<style lang="scss"></style>
