import axios from '../utils/axios';

const get = async ({ paciente_id = "", codigo= "", id="", horario_id = "", venda_id= "" }) => {
    // console.log('codigo no pacienteReceita',codigo);
    let result
    await axios.get('pacientes/receitas', { params: { paciente_id, codigo, id, horario_id, venda_id } }).then(res => {
     
        result = res.data
    }).catch(err => {
        if (err.response.data) {
            throw err.response.data
        } else {
            throw new Error('Erro ao buscar receitas!')
        }
    })

    return result;
}

// const verificaCodReceita = async (cod) => {
//     await axios.get(`pacientes/receitas/codigo/${cod}`)
// }

const store = async (data) => {

    let result = await axios.post('pacientes/receitas', data).then(res => {

        return res
    }).catch(err => {
        if (err.response.data) {
            throw err.response.data
        } else {
            throw new Error('Erro ao inserir receitas!')
        }
    })
    return result
}

const del = async (id) => {

    let result = await axios.delete(`pacientes/receitas/${id}`).then(res => {

        return res
    }).catch(err => {
        console.log(err);
        console.log(err.response);
        if (err.response.data && err.response.data.error) {
            throw err.response.data.error.message
        } else {
            throw new Error('Erro ao excluir receitas!')
        }
    })
    return result
}

export default {
    get,
    store,
    del
}