import axios from "@/utils/axios";

const getByCep = async (cep) => {
  // console.log(cep)
  if (cep) {
    let endereco;
    await axios
      .get(
        `https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${cep}/json/`
      )
      .then((res) => {
        endereco = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(endereco)
    return endereco;
  }
};

const IBGE = {
  baseUrl: "https://servicodados.ibge.gov.br/api/v1/localidades/",
  async getMunicipios(UF = "", municipio = "") {
    let url = this.baseUrl;
    if (UF) {
      url += `estados/${UF}/municipios`;
    } else {
      url += "municipios";
    }
    try {
      let municipios = (await axios.get(url)).data;
      // console.log(municipios)

      if (Array.isArray(municipios)) {
        municipios = municipios.map((m) => ({
          codMunicipio: m.id,
          nome: m.nome,
        }));

        if (municipio) {
          // console.log( 'AABBCC' ,municipios)
          let muncipioFinded = municipios.find((m) => {
            return m.nome.trim().toLowerCase() == municipio.trim().toLowerCase();
          });
          console.log(muncipioFinded);
          if (muncipioFinded) {
            return muncipioFinded;
          }
        }

        return municipios;
      }
    } catch (error) {
      console.debug("Erro ao conectar IBGE", error);
    }
  },
};

export default {
  getByCep,
  IBGE,
};
