<template>
  <div>

    <b-form-group>
      <label v-if="label !== ''" style="margin-bottom:5px"><i class="fa fa-stethoscope"/> {{ label }}</label>
      <v-select
        :value="value"
        :options="especialidades"
        :reduce="(espec) => espec.id"
        @input="handleInput"
        :loading="loading"
        label="especialidade"
        :multiple="multipleEspecialidade"
      ></v-select>
      <b-form-invalid-feedback :force-show="state">
        Selecione a Especialiade
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import EspecialidadesLib from "../../libs/EspecialidadesLib";
export default {
  props: {
    label: { type: [String], default: "Especialidade" },
    value: { type: [Number, String] },
    multipleEspecialidade: { type: Boolean, default: false },
    state: { type: Boolean, default: false },
    defaultEspecialidades: Array,
  },
  data() {
    return {
      especialidades: [],
      loading: false,
    };
  },
  methods: {
    async carregaEspecialidade() {
      await this.$nextTick();
      if (
        Array.isArray(this.defaultEspecialidades) &&
        this.defaultEspecialidades.length > 0
      ) {
        this.especialidades = this.defaultEspecialidades;
      } else {
        this.loading = true;
        this.especialidades = await EspecialidadesLib.get();
        this.loading = false;
      }
    },
    handleInput(value) {
      this.$nextTick(function() {
        this.$emit("especialidade", value);
        this.$emit("input", value);
        this.$emit(
          "especialidadeComplete",
          this.especialidades.find((espec) => espec.id == value)
        );
      });
    },
  },
  watch: {
    defaultEspecialidades() {
      this.carregaEspecialidade();
    },
  },
  created() {
    this.carregaEspecialidade();
  },
};
</script>

<style></style>
