<template>
  <div>
    <!-- {{venda.cliente}} -->
    <!-- {{ venda.itens }} -->
    <!-- {{ fidelizacoes }} -->
    <div class="row">
      <div
        class="col-12 col-sm-3"
        v-if="venda"
      >
        <label>Venda. Num.</label>
        <b-input
          disabled
          v-model="venda.id"
        />
      </div>
      <div
        v-if="horario"
        class="col d-flex justify-content-end"
      >
        <select-caixa
          :empresa_id="horario.clinica_id"
          v-model="venda.caixaControle"
        />
      </div>
      <div
        class="col-12 col-sm-9"
        v-if="!horario"
      >
        <label>Paciente / Cliente / Num.Cartão</label>
        <select-paciente
          ref="selectPaciente"
          :tipo="['paciente']"
          @edit="onEdit"
          @new="onNew"
          @paciente="selecionarCliente"
          showFidelizacoes
          :value="venda.cliente"
          setNullOnEmpty
          :selecionado="venda.cliente ? venda.cliente.id : ''"
        />
      </div>

      <div
        class="col-12 mt-3 fidelizacoes-container"
        v-if="
          venda.cliente &&
          (venda.cliente.convenioNome || venda.cliente.funcionario === 1)
        "
      >
        <div
          class="fidelizacao-item"
          :class="{ selected: venda.funcionarioAtivado }"
          @click="ativarFuncionario"
          style="margin: 0 auto; width: 40%"
          v-if="venda.cliente.funcionario === 1"
        >
          <h4 class="text-center"><b-icon-person-badge /> FUNCIONÁRIO / DEF</h4>
          <hr />
          Pessoa selecionada é funcionário e pode ter descontos especiais nos
          preços de venda*
        </div>
        <div
          class="fidelizacao-item"
          :class="{ selected: venda.convenioAtivado }"
          @click="ativarConvenio"
          style="margin: 0 auto; width: 40%"
          v-if="venda.cliente.convenioNome"
        >
          <h4 class="text-center">
            <b-icon-shop /> CONVENIO
            <small v-if="venda.cliente.convenioTitular">
              <br />
              <small>
                <i class="fa fa-user" /> Dependente de
                {{ venda.cliente.convenioTitular.razao_social }}
              </small>
            </small>
          </h4>
          <div class="text-center"><small>CLIQUE PARA ATIVAR</small></div>
          <hr />
          <div class="text-center">
            <b-icon-shop /> {{ venda.cliente.convenioNome }}
          </div>

          <hr />
          <small v-if="venda.cliente.configuracoes.convenio_dependentes">
            <b><i class="fa fa-users" /> Dependentes</b>
            <ul>
              <li
                v-for="dep in venda.cliente.configuracoes.convenio_dependentes"
                :key="`dep_of_${dep.id + venda.cliente.id}`"
              >
                {{ dep.razao_social }}
              </li>
            </ul>
          </small>
        </div>
      </div>
      <!--FIDELIZACOES-->

      <div
        class="col-12 mt-3"
        v-if="fidelizacoes && fidelizacoes.length > 0"
      >
        <label
          >Fidelização
          <small>(selecione para ativar os descontos)</small></label
        >

        <RenovarFidelizacao
          v-if="fidelizacoesVencidas.length > 0"
          :fidelizacoes="fidelizacoesVencidas"
          @renovar="renovarFidelizacao"
        />

        <div class="fidelizacoes-container">
          <div
            class="fidelizacao-item"
            :class="{
              selected: venda.fidelizacao == fidel,
              vencido: fidel.vencido === true || fidel.cancelado == true,
            }"
            @click="selecionarFidelizacao(fidel)"
            v-for="fidel in fidelizacoes"
            :key="fidelizacoes.indexOf(fidel)"
          >
            <img :src="require('@/assets/img/icones/menu/loyalty_40px.png')" />

            {{ fidel.exameSelecionado.fidelizcao.nome }}
            <br />
            <small
              ><small><b>Titular</b> {{ fidel.titular || "" }}</small></small
            >

            <div class="fidelizacao-codigo">{{ fidel.fidelizacao.codigo }}</div>
            <div class="fidelizacao-validade">
              Val.: {{ fidel.fidelizacao.validade | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="pb-0 mb-0" />
    <!-- ITENS DA VENDA -->
    <div class="row mt-2">
      <div class="col-9 bg-primary text-white pt-2">
        <h5><i class="fa fa-shopping-cart" /> Itens da Venda</h5>
      </div>
      <div class="col-3 text-right">
        <b-button
          variant="primary"
          block
          @click="addItem"
        >
          <b-icon-plus /> Adicionar
        </b-button>
      </div>
    </div>
    <div class="col-12 p-3 text-center">
      <venda-itens
        :itens="venda.itens"
        @editar="editarItem"
        @remover="excuirItem"
        :hasFidel="venda.fidelizacao ? true : false"
        :convenio="venda.convenioAtivado"
      />
    </div>

    <!--PAGAMENTOS-->
    <div class="row mt-2">
      <div class="col-9 bg-primary text-white pt-2">
        <h5><i class="fa fa-cash-register"></i> Pagamentos</h5>
      </div>
      <div class="col-3 text-right">
        <b-button
          variant="primary"
          block
          @click="addPagamento"
        >
          <b-icon-plus /> Adicionar
        </b-button>
      </div>
      <div class="col-12 mt-3">
        <div
          v-if="!venda.pagamentos || venda.pagamentos.length <= 0"
          class="text-center"
        >
          Nenhum pagamento informado ainda.
        </div>
        <div class="pagamentos-container p-3">
          <caixa-pagamento-card
            v-for="pagamento in venda.pagamentos"
            :key="venda.pagamentos.indexOf(pagamento)"
            :item="pagamento"
            @editar="editarPagamento"
            @excluir="excluirPagamento"
          />
        </div>
      </div>
    </div>

    <!--MODAIS-->
    <b-modal
      id="modal-add-item"
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      title="Adicionar/Editar Item"
    >
      <venda-item-form
        @adicionarItem="adicionarItem"
        @close="closeModalItens"
        v-model="itemEdit"
        :fidelizacaoObj="venda.fidelizacao"
        :cliente="venda.cliente"
        :convenio="venda.convenioAtivado"
        :horario="horario"
        :funcionario="venda.funcionarioAtivado"
      />
    </b-modal>

    <b-modal
      id="modal-forma-pagamento"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      title="Adicionar Pagamento"
      size="lg"
      @close="closePagamento"
    >
      <caixa-forma-pagamento-form
        @creating="setTempForma"
        :descontoPorForma="{
          status: venda.itens.some(
            (item) => item.aplicouDescontoPersonalizado === true
          ),
          msg: 'Há um desconto para dessa forma de pagamento.',
        }"
        @salvar="adicionarPagamento"
        :valorAberto="valorAberto"
        @close="closePagamento"
        :edit="pagamentoEdit"
        :convenio="venda.convenioAtivado"
        :funcionario="venda.cliente"
        :caixa="venda.caixaControle"
      />
    </b-modal>

    <hr />
    <!-- Reumos Totais -->
    <div class="row mt-4">
      <div class="col-12 col-sm-3 text-center">
        <h5>
          Sub-total<br />
          <small> {{ venda.vl_subtotal | currencyWithMask }}</small>
        </h5>
      </div>
      <div class="col-12 col-sm-3 text-center">
        <h5>
          Desconto/Acrescimo<br />
          <small>{{ (venda.vl_desconto * -1) | currencyWithMask }}</small>
        </h5>
      </div>
      <div class="col-12 col-sm-3 text-center">
        <h5>
          Total
          <br />
          <b>
            {{ venda.vl_total | currencyWithMask }}
          </b>
        </h5>
      </div>
      <div class="col-12 col-sm-3 text-center">
        <h5
          :class="{
            'text-danger': venda.vl_pago != venda.vl_total,
            'text-success': venda.vl_total == venda.vl_pago,
          }"
        >
          Pago
          <br />
          <b>
            {{ venda.vl_pago | currencyWithMask }}
          </b>
        </h5>
      </div>
    </div>

    <div class="mt-3">
      <botoes-salvar-cancelar
        :saving="saving"
        @salvar="salvarVenda"
        @cancelar="cancelar"
      >
        <template #left>
          <b-btn
            :disabled="!venda.cliente"
            @click="openModalReceita"
            variant="info"
          >
            <i class="fas fa-signature"></i> Lançar Receita
          </b-btn>
        </template>
      </botoes-salvar-cancelar>
    </div>
    <b-modal
      v-if="pacienteSelecionado"
      id="modal-paciente-form"
      no-enforce-focus
      hide-footer
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-header
    >
      <paciente-ficha
        :paciente_id="pacienteSelecionado.id"
        @close="closeModalPaciente"
        closeOnSave
      />
    </b-modal>
    <b-modal
      v-if="!!venda.cliente"
      id="modal-receita"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        <i class="fas fa-signature"></i> Lançar receita para
        <b>{{ venda.cliente.razao_social }}</b>
      </template>
      <div class="d-flex flex-column justify-content-center">
        <h4 class="text-muted text-center">Receitas lançadas na venda</h4>
        <small class="text-muted text-center"
          >* Atenção, essas receitas serão adicionadas somente ao salvar a
          venda.</small
        >
      </div>
      <PacienteReceita
        @receita="onReceita"
        :directSave="false"
        :venda_id="venda.id"
        :local="venda.receitas"
        :paciente="venda.cliente"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import SelectPaciente from "../../common/SelectPaciente.vue";
import VendaItemForm from "./VendaItemForm.vue";
import VendaItens from "./VendaItens.vue";
import CaixaFormaPagamentoForm from "../CaixaFormaPagamentoForm.vue";
import BotoesSalvarCancelar from "../../common/BotoesSalvarCancelar.vue";
import CaixaPagamentoCard from "../CaixaPagamentoCard.vue";
import PacienteFicha from "@/components/Entidades/Paciente/PacienteFicha.vue";
import VendaLib from "../../../libs/VendaLib";
import EntidadesLib from "../../../libs/EntidadesLib";
import FidelizacoesLib from "../../../libs/FidelizacoesLib";
import CaixasLib from "../../../libs/CaixasLib";
import SelectCaixa from "../../common/SelectCaixa.vue";
import PacienteReceita from "../../Entidades/Paciente/PacienteReceitas/PacienteReceita.vue";
import Validadores from "../../../helpers/Validadores";

const _createFidelizacao = (
  {
    //id = "",
    nome = "",
    prazo = "",
    qtd_dependentes = "",
    produto_id = "",
    valor_venda,
    valor_custo,
    status,
    desconto,
    renovavel,
    valor_renovacao,
    contrato,
    dependente_adicional,
    valor_adicional,
  },

  {
    id,
    codigo,
    dependentes,
    dt_aquisicao = "",
    dt_vencimento,
    titular,
    entidade_fidelizacao_id,
    vencido,
  },
  fidelizacao_id = null
) => {
  return {
    idInt: null,
    id,
    fidelizacao_id,
    tipoVenda: "Fidelização",
    entidadeId: null,
    entidade_fidelizacao_id,
    entidadeSelecionada: null,
    titular,
    vencido,
    exameSelecionado: {
      id: fidelizacao_id,
      nome: "",
      descricao: "",
      tipo: "Fidelização",
      valor_venda: 0,
      valor_custo: 0,
      configuracoes: {},

      imagem: null,
      justificativas: [],
      fidelizcao: {
        id,
        nome,
        prazo,
        qtd_dependentes,
        produto_id,
        desconto,
        status,
        renovavel,
        valor_renovacao,

        valor_venda,
        valor_custo,
        contrato,
        dependente_adicional,
        valor_adicional,
      },
    },
    fidelizacaoPreco: null,
    valor_venda: null,
    valor_custo: null,
    dependenteSelecionado: {},
    solicitacaoExame: null,
    valor_original: null,
    fidelizacao: {
      dependentes,
      valor: valor_venda,
      codigo,
      codigoLivre: null,
      aquisicao: dt_aquisicao ? dt_aquisicao : "",
      validade: dt_vencimento ? dt_vencimento : "",
    },
    desconto: null,
    vl_desconto: 0,
  };
};

export default {
  components: {
    SelectPaciente,
    VendaItemForm,
    VendaItens,
    CaixaFormaPagamentoForm,
    BotoesSalvarCancelar,
    CaixaPagamentoCard,
    PacienteFicha,
    SelectCaixa,
    PacienteReceita,
    RenovarFidelizacao: () => import("../../Fidelizacao/RenovarFidelizacao"),
  },
  props: {
    horario: Object,
    paciente: Object,
    caixa: Object,
  },

  mounted() {
    //console.log("caixa", this.caixa);
    if (this.caixa) {
      this.venda.caixaControle = this.caixa;
    }
    // else {
    //    this.buscarCaixa();
    // }

    if (this.paciente && this.paciente?.id) {
      this.selecionarCliente(this.paciente);
    }
  },
  data() {
    return {
      venda: {
        cliente: null,
        vl_subtotal: 0,
        vl_desconto: 0,
        vl_total: 0,
        vl_pago: 0,
        vl_custo: 0,
        itens: [],
        receitas: [],
        fidelizacao: null,
        pagamentos: [],
        caixaControle: null,
        id: null,
        _horarioId: this.horario?.id,
        _agendaId: this.horario?.id_agenda,
        convenioAtivado: null,
        funcionarioAtivado: null,
      },
      tempFormaPagamento: null,
      pacienteSelecionado: { id: null },
      itemEdit: null,
      pagamentoEdit: null,
      fidelizacoes: [],
      fidelizacoesEncontradas: [],

      itensExc: [],
      pagamentosExc: [],
      loading: false,
      saving: false,
    };
  },
  watch: {
    "venda.itens": {
      deep: true,
      handler() {
        // //console.log("inicou change itens");
        this.venda.vl_subtotal = this.venda.itens.reduce((ret, vl) => {
          ret += vl.valor_original ? vl.valor_original : vl.valor_venda;
          return ret;
        }, 0);
        //console.log("CALCULANDO TOTAIS, ", this.venda.itens);
        this.venda.vl_desconto = this.venda.itens.reduce((ret, vl) => {
          ret += vl.valor_desconto ? parseFloat(vl.valor_desconto) : 0;
          return ret;
        }, 0);
        this.venda.vl_custo = this.venda.itens.reduce((ret, vl) => {
          ret += vl.vl_custo ? vl.vl_custo : 0;
          ret += vl.valor_custo ? vl.valor_custo : 0;
          return ret;
        }, 0);
        //console.log("vl", this.venda.vl_subtotal, this.venda.vl_desconto);
        this.venda.vl_total = this.venda.vl_subtotal - this.venda.vl_desconto;

        //this.calcularDescontos();
        //this.selecionarFidelizacao(this.fidelizacoes[this.fidelizacoes.length-1]);
        ////console.log("fdi", this.fidelizacoes);
        // //console.log("finalizou change itens");
      },
    },
    "venda.pagamentos": {
      deep: true,
      handler() {
        //  this.calcularDescontos();
        ////console.log("venda.pagamentos");
        this.venda.vl_pago = this.venda.pagamentos.reduce((ret, vl) => {
          ret += this.formatValorReal(vl.valor);
          return ret;
        }, 0);
      },
    },
    "venda.fidelizacao": function () {
      // //console.log("venda.fidelizacao");
      this.calcularDescontos();
    },
    // "formaPg": function() {
    //   this.calcularDescontos();
    // },
  },

  computed: {
    valorAberto: function () {
      // //console.log("valorAberto");
      return this.venda.vl_total - this.venda.vl_pago;
    },
    fidelizacoesVencidas() {
      //console.log("to fitler", this.fidelizacoes);
      return this.fidelizacoes
        .filter((f) => f.vencido)
        .map((f) => ({
          ...f.fidelizacao,
          id: f.fidelizacao_id,
          titular: f.titular,
          vencido: f.vencido,
          entidade_fidelizacao_id: f.entidade_fidelizacao_id,
          aquisicao: f.fidelizacao.aquisicao,
          validade: f.fidelizacao.validade,
          renovavel: f.exameSelecionado.fidelizcao.renovavel,
          valor_renovacao: f.exameSelecionado.fidelizcao.valor_renovacao,
          nome:
            f.exameSelecionado &&
            f.exameSelecionado.fidelizcao &&
            f.exameSelecionado.fidelizcao.nome
              ? f.exameSelecionado.fidelizcao.nome
              : "",
          idFidela:
            f.exameSelecionado &&
            f.exameSelecionado.id &&
            f.exameSelecionado.fidelizcao.id
              ? +f.exameSelecionado.fidelizcao.id
              : null,
        }));
    },
    // user() {
    //   return this.$store.state.auth.user;
    // },
  },
  methods: {
    ativarFuncionario() {
      if (!this.venda.funcionarioAtivado) {
        if (this.venda.cliente.funcionario === 1) {
          this.venda.funcionarioAtivado = true;
          this.venda.convenioAtivado = null;
        }
      } else {
        this.venda.funcionarioAtivado = null;
      }
      console.log("this.venda", this.venda);
      this.calcularDescontos();
    },
    ativarConvenio() {
      if (!this.venda.convenioAtivado) {
        if (this.venda.cliente.convenioTitular) {
          this.venda.cliente = this.venda.cliente.convenioTitular;
        }
        if (this.venda.cliente.convenio_id) {
          this.venda.convenioAtivado = {
            convenio_id: this.venda.cliente.convenio_id,
            convenio_nome: this.venda.cliente.convenioNome,
            dependentes: this.venda.cliente.configuracoes.convenio_dependentes,
          };
          this.venda.funcionarioAtivado = null;
        }
      } else {
        this.venda.convenioAtivado = null;
      }
      this.calcularDescontos();
    },
    renovarFidelizacao(fidelizacao) {
      // //console.log("renovarFidelizacao", fidelizacao);
      const fidelizacaoRenovar = this.fidelizacoes.find(
        (f) => f.entidade_fidelizacao_id === fidelizacao.entidade_fidelizacao_id
      );
      // //console.log("Vai renovar: ", fidelizacaoRenovar);

      const mesesToAdd = fidelizacaoRenovar.exameSelecionado.fidelizcao.prazo;
      const novaValidade = moment().add("M", mesesToAdd).format("DD/MM/YYYY");
      fidelizacaoRenovar.fidelizacao.validade = novaValidade;

      function createItem() {
        return {
          tipoVenda: "Renovação Fidelizaçao",
          renovarFidelizacao: true,
          exameSelecionado: {
            ...fidelizacao,
            valor_venda: fidelizacao.valor_renovacao,
            descricao: `Renovação do cartão ${fidelizacao.codigo} para a data ${novaValidade}`,
          },
          valor_venda: fidelizacao.valor_renovacao,
          valor_original: fidelizacao.valor_renovacao,
          fidelizacao_id: fidelizacao.id,
          fidelizacao,
        };
      }
      this.adicionarItem(createItem());
      // Atualizar cartão para usar local
      const idx = this.fidelizacoes.findIndex(
        (f) => f.fidelizacao_id === fidelizacaoRenovar.fidelizacao_id
      );
      // //console.log("achou o idx p atualizar", idx);
      if (idx > -1) {
        fidelizacaoRenovar.vencido = false;

        this.$set(this.fidelizacoes, idx, fidelizacaoRenovar);
      }
    },
    async selecionarCliente(cliente, isIncompleto) {
      //console.log("selectClietne", cliente);
      // if (!cliente || !cliente?.id) {
      //   // this.venda.cliente = {}
      //   return;
      // }

      this.fidelizacoes = [];
      this.venda.cliente = cliente;

      //if(cliente.)
      // //console.log(cliente.id);
      if (cliente) {
        let fidelizacoes = await FidelizacoesLib.getFidelizacoesPaciente(
          cliente.id
        );
        //console.log("fidelizacoes encontradas ", fidelizacoes);

        let fids = await fidelizacoes.reduce((ret, vl) => {
          if (!ret.some((x) => x.id == vl.id)) {
            let p = vl;

            if (p.dt_vencimento) {
              // //console.log(1);
              if (p.dt_vencimento.indexOf("/") >= 0) {
                // //console.log(12);
                //
                p.dt_vencimento = moment(p.dt_vencimento, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                );
              } else {
                // //console.log(13);

                p.dt_vencimento = moment(p.dt_vencimento).format("YYYY-MM-DD");
              }
              // //console.log(14, p.dt_vencimento);
            }
            ret.push(p);
          }
          return ret;
        }, []);

        this.fidelizacoesEncontradas = fids.map((f) =>
          _createFidelizacao(f.fidelizacao, f, f.fidelizacao.id)
        );

        //console.log("asda2", this.fidelizacoesEncontradas);

        if (this.fidelizacoesEncontradas.length > 0) {
          this.fidelizacoes.push(...this.fidelizacoesEncontradas);
        }

        //this.fidelizacoesEncontradas = fidelizacoes;

        // this.fidelizacoes = this.fidelizacoes.sort((a, b) => {
        //   return a.id > b.id ? -1 : 1;
        // });
        ////console.log("aaaaaaaaaaaaa", this.fidelizacoes);
        // this.ativarConvenio();w
        ////console.log(this.fidelizacoes);
      }

      if (isIncompleto === true) {
        this.showToast(
          "error",
          "O cadastro do paciente está incompleto, verifique.",
          5000
        );
        this.openModal(cliente);
        this.fidelizacoes = [];
        this.fidelizacoesEncontradas = [];
        this.venda.cliente = null;

        return;
      }

      setTimeout(() => {
        this.ativarConvenio();
        this.calcularDescontos();
      }, 200);
    },
    // onMudarForma(forma) {
    //   // //console.log("onMudarForma", forma);

    //   this.formaPg = forma;
    //   this.calcularDescontos();
    // },
    setTempForma(pagamento) {
      // //console.log("setTempForma received", pagamento);

      this.tempFormaPagamento = pagamento;
      this.calcularDescontos();
    },
    openModalReceita() {
      this.$bvModal.show("modal-receita");
    },
    closeModalReceita() {
      this.$bvModal.hide("modal-receita");
    },
    onReceita(receitas) {
      if (Array.isArray(receitas)) {
        Object.assign(this.venda.receitas, receitas);
      }
    },
    async buscarCaixa() {
      let caixas = await CaixasLib.buscarCaixaControle(
        this.user.empresa_id,
        moment().format("YYYY-MM-DD"),
        null,
        { noClosed: true }
      );
      if (
        Array.isArray(caixas)
          ? caixas.length == 0
          : Object.isObject(caixas)
          ? !caixas?.id
          : false
      ) {
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'> <b>Não há nenhum caixa aberto, abra o caixa e tente novamente!</b> </span>`,
          icon: "error",
          allowEscapeKey: true,
        });
        return false;
      }
      // //console.log('this.venda', this.venda);
      this.venda.caixaControle = caixas[0];
      return true;
    },
    onNew() {
      this.pacienteSelecionado = {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },

        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
        paciente: 1,
      };
      // //console.log("ON NEWW");
      this.$bvModal.show("modal-paciente-form");
    },
    async onEdit(paciente) {
      let res = await EntidadesLib.buscaPorCampo({
        id: paciente.id,
        showFull: true,
      });
      // //console.log("EDITANDO", res);
      if (res && res.length > 0) {
        this.openModal(res[0]);
      }
    },
    openModal(paciente) {
      this.pacienteSelecionado = paciente;
      this.$bvModal.show("modal-paciente-form");
      paciente;
    },
    closeModalPaciente(paciente) {
      //console.log("close modal 1", this.pacienteSelecionado);
      this.$refs.selectPaciente.forceSearch();

      if (paciente) {
        this.pacienteSelecionado = paciente;
        this.selecionarCliente(paciente);
      }
      if (
        !this.pacienteSelecionado ||
        !this.pacienteSelecionado.cpf ||
        this.pacienteSelecionado.cpf == "" ||
        (!Validadores.string.validarCPF(this.pacienteSelecionado.cpf) &&
          !Validadores.string.validarCNPJ(this.pacienteSelecionado.cpf))
      ) {
        this.$refs.selectPaciente.clearPaciente();
      }
      this.$bvModal.hide("modal-paciente-form");
      this.$refs.selectPaciente.forceSearch();
    },
    calcDescNovo() {
      //fuuunçção para achar o desconto
      const pagamentos = this.venda.pagamentos;
      const verificaDescontoFunction = (desc) => {
        // let desc = conf.personalizarPorForma;
        // let fidel = conf.fidelizacoes;
        let formaPg =
          (this.venda.funcionarioAtivado && desc.formaDePagamento == "DEF") ||
          (desc.formaDePagamento === "Convenio" &&
            this.venda.convenioAtivado &&
            this.venda.convenioAtivado.convenio_id &&
            desc.empresa &&
            desc.empresa.id &&
            desc.empresa.id == this.venda.convenioAtivado.convenio_id) ||
          (desc.formaDePagamento === "Convenio" &&
            this.venda.convenioAtivado &&
            this.venda.convenioAtivado.convenio_id &&
            desc.empresa.id === -1) ||
          desc.formaDePagamento === "Todas";

        console.log("achou formapg", formaPg);

        let empresa =
          (this.venda.funcionarioAtivado && desc.formaDePagamento == "DEF") ||
          (desc.empresa &&
            pagamentos &&
            pagamentos.length > 0 &&
            pagamentos.some(
              (pg) => desc.empresa && pg.entidadeId == desc.empresa.id
            )) ||
          (desc.empresa &&
            desc.empresa.id === this.tempFormaPagamento?.entidadeId) ||
          (desc.empresa &&
            this.venda.convenioAtivado &&
            desc.empresa.id === this.venda.convenioAtivado.convenio_id) ||
          (desc.empresa && desc.empresa.id == -1);

        return formaPg && empresa;
      };
      //zerar descontos / valores
      for (const element of this.venda.itens) {
        console.log("element", element);
        try {
          element.desconto = null;
          element.vl_desconto = 0;
          element.valor_desconto = 0;
          element.descontoPorForma = {
            aplicado: false,
            desconto: null,
          };
        } catch {
          element;
        }

        if (element.tipoVenda !== "Exame") {
          console.log("reiniciou valores do item");
          element.valor_original = element?.exameSelecionado?.valor_venda || 0;
          element.valor_venda = element?.exameSelecionado?.valor_venda || 0;

          element.valor_custo = element?.exameSelecionado?.valor_custo || 0;
        } else {
          // element.valor_original = element.solicitacaoExame?.exames.
          if (element.solicitacaoExame && element.solicitacaoExame.exames) {
            for (let exame of element.solicitacaoExame.exames) {
              exame.valor_desconto = 0;
              exame.valor_venda = exame.exameSelecionado.valor_venda;
              exame.valor_custo = exame.exameSelecionado.valor_custo;
              exame.valor_original = exame.exameSelecionado.valor_venda;
            }
          }
        }
      }
      //buscar e aplicar exames
      for (let item of this.venda.itens) {
        //se for solicitacaçaõ de exame
        if (item.solicitacaoExame && item.solicitacaoExame.exames) {
          console.log("is solicitacaoExame", item.solicitacaoExame);
          item.valor_desconto = 0;
          item.valor_original = 0;
          item.valor_venda = 0;
          item.valor_custo = 0;
          for (let exame of item.solicitacaoExame.exames) {
            if (exame.configuracoes) {
              if (
                exame.configuracoes.fidelizacoes &&
                this.venda.fidelizacao &&
                exame.aceita_desconto == 1
              ) {
                // let fide = this.venda.fidelizacao.exameSelecionado.fidelizcao;
                let fidelID = this.venda.fidelizacao.fidelizacao_id;

                let hasFidel = exame.configuracoes.fidelizacoes.find(
                  (x) => x.id == fidelID
                );
                if (hasFidel) {
                  exame.valor_original = exame.valor_venda;
                  exame.valor_desconto =
                    exame.valor_venda - hasFidel.valor_venda;
                  exame.valor_custo = hasFidel.valor_custo;
                  exame.valor_venda =
                    exame.valor_original - exame.valor_desconto;
                }
              }
              if (
                exame.configuracoes.personalizarPorForma &&
                exame.personalizar_por_forma === 1
              ) {
                // console.log("is solicitacaoExame POR FORMA");

                let hasDescontoPgto = exame.configuracoes.personalizarPorForma
                  .sort((a, b) => {
                    if (
                      a.formaDePagamento === "Convenio" &&
                      a.empresa?.id === this.venda.convenioAtivado?.convenio_id
                    ) {
                      return -1;
                    }
                    if (
                      b.formaDePagamento === "Convenio" &&
                      b.empresa?.id === this.venda.convenioAtivado?.convenio_id
                    ) {
                      return 1;
                    }
                    return 0;
                  })
                  .find((desc) => verificaDescontoFunction(desc));
                console.log("hasDescontoPgto", hasDescontoPgto);
                if (hasDescontoPgto) {
                  exame.valor_original = exame.valor_venda;
                  if (hasDescontoPgto.valorDeCusto) {
                    exame.valor_desconto =
                      exame.valor_venda - exame.valor_custo;
                    exame.valor_venda = exame.valor_custo;
                  } else {
                    exame.valor_desconto =
                      exame.valor_venda - hasDescontoPgto.valor_venda;
                    exame.valor_venda = hasDescontoPgto.valor_venda;
                    exame.valor_custo = hasDescontoPgto.valor_custo;
                  }
                }
              }
            }
            item.valor_desconto += exame.valor_desconto || 0;
            item.valor_venda += exame.valor_venda || 0;
            item.valor_custo += exame.valor_custo || 0;
            item.valor_original += exame.valor_original || exame.valor_venda;
          }
        } else if (
          item.exameSelecionado &&
          item.exameSelecionado.configuracoes &&
          (item.exameSelecionado.aceita_desconto == 1 ||
            item.exameSelecionado.personalizar_por_forma == 1)
        ) {
          if (
            item.exameSelecionado.configuracoes.fidelizacoes &&
            this.venda.fidelizacao &&
            item.exameSelecionado.aceita_desconto == 1
          ) {
            let fidelID = this.venda.fidelizacao.fidelizacao_id;
            let hasFidel =
              item.exameSelecionado.configuracoes.fidelizacoes.find(
                (x) => x.id == fidelID
              );
            // //console.log("FIDEL", hasFidel, item);
            if (hasFidel) {
              item.valor_original = item.valor_venda;
              item.valor_desconto = item.valor_venda - hasFidel.valor_venda;
              item.valor_custo = hasFidel.valor_custo;
              item.valor_venda = item.valor_original - item.valor_desconto;
            }
          }
          if (
            item.exameSelecionado.configuracoes.personalizarPorForma &&
            item.exameSelecionado.personalizar_por_forma == 1
          ) {
            console.log("exame personalizarPorForma hasDescontoPgto");
            let hasDescontoPgto =
              item.exameSelecionado.configuracoes.personalizarPorForma
                .sort((a, b) => {
                  if (
                    a.formaDePagamento === "Convenio" &&
                    a.empresa?.id === this.venda.convenioAtivado?.convenio_id
                  ) {
                    return -1;
                  }
                  if (
                    b.formaDePagamento === "Convenio" &&
                    b.empresa?.id === this.venda.convenioAtivado?.convenio_id
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .find((desc) => verificaDescontoFunction(desc));
            if (hasDescontoPgto) {
              item.valor_original = item.valor_venda;
              if (hasDescontoPgto.valorDeCusto) {
                item.valor_desconto = item.valor_venda - item.valor_custo;
                item.valor_venda = item.valor_custo;
              } else {
                item.valor_desconto =
                  item.valor_venda - hasDescontoPgto.valor_venda;
                item.valor_venda = hasDescontoPgto.valor_venda;
                item.valor_custo = hasDescontoPgto.valor_custo;
              }
            }
          }
        }
      }
    },
    calcularDescontos() {
      let a = true;
      if (a == a) {
        console.log("start calc");
        this.calcDescNovo();
        return;
      }
      //console.log("chamou calcular descontos", this.venda.itens);
      for (const element of this.venda.itens) {
        // //console.log("element", element);
        try {
          element.desconto = null;
          element.vl_desconto = 0;
          element.valor_desconto = 0;
          element.descontoPorForma = {
            aplicado: false,
            desconto: null,
          };

          if (element.tipoVenda !== "Exame") {
            // //console.log("reiniciou valores do item");
            element.valor_original =
              element?.exameSelecionado?.valor_venda || 0;
            element.valor_venda = element?.exameSelecionado?.valor_venda || 0;

            element.valor_custo = element?.exameSelecionado?.valor_custo || 0;
          }

          //console.log("calcularDescontos: ", element);
        } catch (e) {
          e;
          //console.log(e);
        }
      }
      //FUNC PARA LOCALIZAR REGRA DE DESCONTO
      const pagamentos = this.venda.pagamentos;

      const verificaDescontoFunction = (desc) => {
        //console.log("verifincando o desc", desc);
        let formaPg =
          (this.venda.funcionarioAtivado && desc.formaDePagamento == "DEF") ||
          (desc.formaDePagamento === "Convenio" &&
            this.venda.convenioAtivado &&
            this.venda.convenioAtivado.convenio_id &&
            desc.empresa &&
            desc.empresa.id &&
            desc.empresa.id == this.venda.convenioAtivado.convenio_id) ||
          desc.formaDePagamento === "Todas" ||
          (desc.formaDePagamento === "Convenio" &&
            this.venda.convenioAtivado &&
            this.venda.convenioAtivado.convenio_id &&
            desc.empresa.id === -1);
        // desc.formaDePagamento === this.tempFormaPagamento?.forma ||
        // pagamentos.some(
        //   (pagamento) => pagamento.forma === desc.formaDePagamento
        // );

        let empresa =
          (this.venda.funcionarioAtivado && desc.formaDePagamento == "DEF") ||
          (desc.empresa &&
            pagamentos &&
            pagamentos.length > 0 &&
            pagamentos.some(
              (pg) => desc.empresa && pg.entidadeId == desc.empresa.id
            )) ||
          (desc.empresa &&
            desc.empresa.id === this.tempFormaPagamento?.entidadeId) ||
          (desc.empresa &&
            this.venda.convenioAtivado &&
            desc.empresa.id === this.venda.convenioAtivado.convenio_id) ||
          (desc.empresa && desc.empresa.id == -1);

        return formaPg && empresa;
      };

      for (const item of this.venda.itens.filter((x) => !x.solicitacaoExame)) {
        ////console.log("pagamentos", pagamentos);
        //console.log(
        //   "find descontos 01",
        //   this.venda.convenioAtivado,
        //   item.exameSelecionado.configuracoes.personalizarPorForma,
        //   item.exameSolicitado &&
        //     item.exameSolicitado.configuracoes &&
        //     item.exameSelecionado.configuracoes.personalizarPorForma
        // );

        const desconto =
          item.exameSelecionado &&
          item.exameSelecionado.configuracoes &&
          item.exameSelecionado.configuracoes.personalizarPorForma
            ? item.exameSelecionado.configuracoes.personalizarPorForma.find(
                (desc) => verificaDescontoFunction(desc)
              )
            : null;

        //console.log("finded descontos 1", desconto);

        // //console.log("achou desconto persoanlizado", desconto);
        if (desconto) {
          if (
            parseFloat(item.valor_venda ? item.valor_venda : 0) >
            parseFloat(desconto.valor_venda ? desconto.valor_venda : 0)
          ) {
            desconto.desconto = (
              parseFloat(item.valor_venda ? item.valor_venda : 0) -
              parseFloat(desconto.valor_venda ? desconto.valor_venda : 0)
            ).toFixed(2);
          } else {
            desconto.desconto = (
              parseFloat(desconto.valor_venda ? desconto.valor_venda : 0) -
              parseFloat(item.valor_venda ? item.valor_venda : 0)
            ).toFixed(2);
          }
          if (desconto.valorDeCusto && desconto.valorDeCusto === true) {
            desconto.desconto = desconto.valor_custo - item.valor_venda;
          }
          desconto.desconto = (parseFloat(desconto.desconto) * -1).toFixed(2);

          if (desconto.acumulativo) {
            item.valor_desconto += desconto.desconto;

            item.valor_venda =
              item.valor_venda > item.valor_desconto
                ? item.valor_venda - item.valor_desconto
                : item.valor_desconto - item.valor_venda;

            item.desconto = "Valor Venda:" + item?.valor_venda || 0;
            item.valor_custo = desconto.valor_custo;
            //  //console.log('isAcumulativo, item.valor_desconto --> ', item.valor_desconto);
          } else {
            item.desconto = "Valor Venda:" + desconto?.valor_venda || 0;

            item.valor_desconto = desconto.desconto;

            item.valor_custo = desconto.valor_custo;
          }

          item.descontoPorForma = {
            aplicado: true,
            desconto,
          };

          item.valor_venda = desconto.valor_venda;

          // //console.log(
          //   "11111111111111111111111111111",
          //   item,
          //   item.valor_venda,
          //   desconto.valor_venda
          // );
        }

        // //console.log("concluiu personalizar por forma");
      }

      //console.log(
      // "findin desc of solicExames",
      // this.venda.itens.filter((x) => x.solicitacaoExame != null)
      //);
      for (const itemPai of this.venda.itens.filter(
        (x) => x.solicitacaoExame != null
      )) {
        // const pagamentos = this.venda.pagamentos;
        ////console.log("pagamentos", pagamentos);
        //console.log(
        // "find descontos",
        // this.venda.convenioAtivado
        //item.exameSelecionado.configuracoes.personalizarPorForma
        //    );

        if (
          itemPai &&
          itemPai.solicitacaoExame &&
          itemPai.solicitacaoExame.exames
        ) {
          for (let ex of itemPai.solicitacaoExame.exames) {
            const desconto =
              ex.configuracoes && ex.configuracoes.personalizarPorForma
                ? ex.configuracoes.personalizarPorForma.find((desc) =>
                    verificaDescontoFunction(desc)
                  )
                : null;
            if (desconto) {
              if (
                parseFloat(ex.valor_venda ? ex.valor_venda : 0) >
                parseFloat(desconto.valor_venda ? desconto.valor_venda : 0)
              ) {
                desconto.desconto = (
                  parseFloat(ex.valor_venda ? ex.valor_venda : 0) -
                  parseFloat(desconto.valor_venda ? desconto.valor_venda : 0)
                ).toFixed(2);
              } else {
                desconto.desconto = (
                  parseFloat(desconto.valor_venda ? desconto.valor_venda : 0) -
                  parseFloat(ex.valor_venda ? ex.valor_venda : 0)
                ).toFixed(2);
              }
              if (desconto.valorDeCusto && desconto.valorDeCusto === true) {
                desconto.desconto = ex.valor_venda - desconto.valor_custo;
                desconto.valor_venda = ex.valor_venda - desconto.desconto;
              }
              desconto.desconto = (parseFloat(desconto.desconto) * -1).toFixed(
                2
              );

              if (desconto.acumulativo) {
                ex.valor_desconto += desconto.desconto;

                ex.valor_venda =
                  ex.valor_venda > ex.valor_desconto
                    ? ex.valor_venda - ex.valor_desconto
                    : ex.valor_desconto - ex.valor_venda;

                ex.desconto = "Valor Venda:" + ex?.valor_venda || 0;
                ex.valor_custo = desconto.valor_custo;
                //  //console.log('isAcumulativo, item.valor_desconto --> ', item.valor_desconto);
              } else {
                ex.desconto = desconto?.valor_venda || 0;

                ex.valor_desconto = desconto.desconto;

                ex.valor_custo = desconto.valor_custo;
              }

              ex.descontoPorForma = {
                aplicado: true,
                desconto,
              };

              ex.valor_venda = desconto.valor_venda;
            }
          }
          if (
            itemPai &&
            itemPai.solicitacaoExame &&
            itemPai.solicitacaoExame.exames.filter((x) => x.descontoPorForma)
          ) {
            itemPai.descontoPorForma = itemPai.solicitacaoExame.exames.reduce(
              (ret, vl) => {
                ret.aplicado = vl.descontoPorForma.aplicado;
                ret.formaDePagamento = vl.descontoPorForma.formaDePagamento;
                ret.desconto.valorDeCusto = vl.descontoPorForma.valorDeCusto;
                ret.desconto.valor_venda +=
                  vl.descontoPorForma.desconto.valor_venda;
                ret.desconto.valor_custo +=
                  vl.descontoPorForma.desconto.valor_custo;
                return ret;
              },
              {
                aplicado: false,
                desconto: {
                  formaDePagamento: "",
                  valorDeCusto: false,
                  valor_custo: 0,
                  valor_venda: 0,
                },
              }
            );
            itemPai.desconto = itemPai.solicitacaoExame.exames.reduce(
              (ret, vl) => {
                ret += vl.desconto;
                return ret;
              },
              0
            );
          }
        }
      }
      this.$forceUpdate();

      // //console.log("iniciou fidelização");
      if (this.venda.fidelizacao) {
        let fide = this.venda.fidelizacao.exameSelecionado.fidelizcao;
        let fidelID = this.venda.fidelizacao.fidelizacao_id;

        // //console.log("fidelizacao", fide, this.venda);
        if (fide.desconto) {
          let descontos = fide.desconto;
          if (typeof descontos === "string") {
            descontos = JSON.parse(descontos);
          }
          // //console.log("descontos", descontos);
          ////console.log('itens', this.venda.itens);
          for (let item of this.venda.itens) {
            //   //console.log("o item: ", item);
            let podeContinuar =
              !item.entidadeSelecionada ||
              (item.entidadeSelecionada &&
                item.entidadeSelecionada.aceita_fidelizacao &&
                item.entidadeSelecionada.aceita_fidelizacao == 1 &&
                !item.solicitacaoExame &&
                (!item.descontoPorForma.aplicado ||
                  item.descontoPorForma?.desconto?.acumulativo));

            //  //console.log("pode continuar?", podeContinuar);
            if (podeContinuar) {
              let descontoEncontrado = descontos.find(
                (x) => x.tipo_venda == item.tipoVenda
              );
              // //console.log("it", item, descontoEncontrado);
              if (descontoEncontrado) {
                item.desconto =
                  descontoEncontrado.desconto_tipo == "P"
                    ? descontoEncontrado.desconto_valor + "%"
                    : "R$ " + descontoEncontrado.desconto_valor;

                // item.valor_desconto +=
                //   descontoEncontrado.desconto_tipo == "V"
                //     ? (item.valor_venda - descontoEncontrado.desconto_valor)
                //     : item.valor_venda *
                //       (descontoEncontrado.desconto_valor / 100);

                // item.valor_venda -= item.valor_desconto;
              }
            }
          }
        }
        // //console.log("lista de itens", this.venda.itens, fide);
        let listaItensComConfig = this.venda.itens.filter(
          (x) =>
            /*(!x.entidadeSelecionada ||
              (x.entidadeSelecionada &&
                x.entidadeSelecionada.aceita_fidelizacao &&
                x.entidadeSelecionada.aceita_fidelizacao == 1)) &&*/
            x.exameSelecionado &&
            (!x.descontoPorForma?.aplicado ||
              x.descontoPorForma?.desconto?.acumulativo) &&
            x.exameSelecionado.aceita_desconto === 1 &&
            x.exameSelecionado.configuracoes?.fidelizacoes?.some((y) => {
              //  //console.log("bsc", y, fidelID);
              return y.id == fidelID;
            })
          //&& !x.solicitacaoExame
        );
        //  //console.log("listaItensComConfig------>", listaItensComConfig);
        for (let item of listaItensComConfig) {
          let vl_venda = item.exameSelecionado.configuracoes.fidelizacoes.find(
            (y) => y.id == fidelID
          ).valor_venda;
          let desc =
            (vl_venda > item.exameSelecionado.valor_venda
              ? vl_venda - item.exameSelecionado.valor_venda
              : item.exameSelecionado.valor_venda - vl_venda) * -1;
          //   //console.log('desc', desc);
          item.valor_desconto = desc;
          //    //console.log('isAcumulativo, item.valor_desconto --> ', item.valor_desconto);
          // item.valor_venda = item.exameSelecionado.valor_venda - item.valor_desconto;

          item.valor_custo =
            item.exameSelecionado.configuracoes.fidelizacoes.find(
              (y) => y.id == fidelID
            ).valor_custo;
        }
      }
      for (const item of this.venda.itens) {
        // //console.log('valor_desconto final', item.valor_desconto);
        item.desconto = null;
        // //console.log("item antes", item);
        if (item.tipoVenda !== "Exame") {
          item.valor_venda =
            (item?.exameSelecionado?.valor_venda || 0) - item.valor_desconto;
        }
        if (item.valor_desconto > 0) {
          item.desconto = "Valor Venda:" + item?.valor_venda || 0;
        }
        // //console.log("item depois", item);
      }
      this.$forceUpdate();
      //  //console.log("concluiu calcularValores");
      // //console.log(this.venda.itens);
    },
    selecionarFidelizacao(fidel) {
      ////console.log("chamou selecionarFidelizacao", fidel);
      if (typeof fidel.vencido !== "undefined" && fidel.vencido === true) {
        this.$swal(
          "Cartão Vencido",
          "Você não pode utilizar um cartão vencido!",
          "error"
        );
        return;
      }

      if (fidel.cancelado) {
        this.$swal(
          "Cartão Cancelado",
          "Você não pode utilizar um cartão cancelado!",
          "error"
        );
        return;
      }
      if (this.venda.fidelizacao == fidel) {
        this.venda.fidelizacao = null;
        //alert(2)
      } else {
        this.venda.fidelizacao = fidel;
        //alert(1)
      }
    },
    addItem() {
      this.itemEdit = null;
      this.$bvModal.show("modal-add-item");
    },
    async addPagamento() {
      if (!this.venda.caixaControle || !this.venda.caixaControle?.id) {
        let temCx = await this.buscarCaixa();
        if (!temCx) return;
      }
      this.pagamentoEdit = null;
      //    this.descontosPorForma = this.venda.itens.filter(item=>item.personalizar_por_forma).map(item=>item?.configuracoes?.personalizarPorForma)
      this.$bvModal.show("modal-forma-pagamento");
    },
    closePagamento() {
      this.setTempForma(null);
      this.calcularDescontos();
      this.$bvModal.hide("modal-forma-pagamento");
    },
    adicionarPagamento(pagamento) {
      // //console.log("chamou adicionarPagamento");
      if (this.pagamentoEdit) {
        let it = this.venda.pagamentos.findIndex(
          (x) => x.idInt == pagamento.idInt
        );
        this.$set(this.venda.pagamentos, it, Object.assign(it, pagamento));
        // this.venda.pagamentos[it] = Object.assign(it, pagamento);
        // this.$forceUpdate();
      } else {
        //  //console.log('added pagamento');
        const _pagamento = Object.assign({}, pagamento);
        _pagamento.idInt =
          this.venda.pagamentos.length <= 0
            ? 1
            : this.venda.pagamentos.sort((a, b) => {
                return a.idInt > b.idInt ? -1 : 1;
              })[0].idInt + 1;
        this.venda.pagamentos.push(_pagamento);
      }

      this.closePagamento();
    },
    excluirPagamento(pagamento) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Tem certeza que deseja remover este pagamento?",
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não",
      }).then((x) => {
        if (x.isConfirmed) {
          this.pagamentosExc.push(Object.assign({}, pagamento));
          this.venda.pagamentos = this.venda.pagamentos.filter(
            (x) => x != pagamento
          );

          this.setTempForma(null);
          this.calcularDescontos();
        }
      });
    },
    editarPagamento(pagamento) {
      this.pagamentoEdit = pagamento;
      this.$bvModal.show("modal-forma-pagamento");
    },
    async adicionarItem(item) {
      // //console.log("ITEM RECEBIDO", item.valor_venda, item);

      if (!this.itemEdit) {
        item.idInt =
          this.venda.itens.length <= 0
            ? 1
            : this.venda.itens.sort((a, b) => {
                return a.idInt > b.idInt ? -1 : 1;
              })[0].idInt + 1;
        ////console.log('O ITEEEEEM', item);
        if (item && item.tipoVenda == "Fidelização") {
          item.fidelizacao_id = item.exameSelecionado.fidelizcao.id;
          this.fidelizacoes.push(item);
        }
        // //console.log("adicionado", item);
        this.venda.itens.push(item);
      } else {
        let it =
          this.venda.itens[
            this.venda.itens.findIndex((x) => x.idInt == item.idInt)
          ];
        // //console.log(this.itens.find(x=>x.idInt==item.idInt));
        this.venda.itens[
          this.venda.itens.indexOf(
            this.venda.itens.find((x) => x.idInt == item.idInt)
          )
        ] = Object.assign(it, item);
      }
      this.itemEdit = null;
      this.calcularDescontos();
      // this.$forceUpdate();
      await this.$nextTick();
      if (
        item.tipoVenda == "Fidelização" &&
        !this.venda.fidelizacao &&
        this.fidelizacoes.length > 0
      ) {
        this.selecionarFidelizacao(this.fidelizacoes[0]);
      }
      setTimeout(() => {
        this.calcularDescontos();
      }, 200);

      this.$bvModal.hide("modal-add-item");
    },
    closeModalItens() {
      this.itemEdit = null;
      this.$bvModal.hide("modal-add-item");
      this.calcularDescontos();
    },
    editarItem(item) {
      this.itemEdit = item;
      this.$bvModal.show("modal-add-item");
    },
    excuirItem(item) {
      // //console.log("exc',i", item);
      this.$swal({
        title: "Você tem certeza?",
        text: "Tem certeza que deseja remover este item?",
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não",
      }).then((x) => {
        if (x.isConfirmed) {
          //this.venda.itens.find(x=>x.idInt == item.idInt).rowStatus = "deleted";
          if (item.renovarFidelizacao) {
            const idx = this.fidelizacoesEncontradas.findIndex(
              (f) => f.fidelizacao_id === item.fidelizacao_id
            );
            // //console.log("idx vai atualizar", idx);
            if (idx > -1) {
              this.$set(
                this.fidelizacoes,
                idx,
                Object.assign({}, this.fidelizacoesEncontradas[idx])
              );
              this.venda.fidelizacao = null;
            }
          }
          this.itensExc.push(Object.assign({}, item));
          this.venda.itens = this.venda.itens.filter((x) => x != item);
          this.calcularDescontos();
        }
      });
    },
    async salvarVenda() {
      console.log("venda", this.venda);
      if (
        (this.venda && this.venda.cliente && !this.venda.cliente.cpf) ||
        !Validadores.string.validarCPF(this.venda.cliente.cpf)
      ) {
        this.$swal({
          title: "Atenção",
          html: `<b>Informe um CPF válido para o cliente</b>`,
          icon: "error",
          allowEscapeKey: true,
        });
        return;
      }

      if (this.saving || this.loading) return;
      let erros = [];
      if (!this.venda.cliente || this.venda.cliente.id <= 0) {
        erros.push("Informe um Cliente/Paciente!");
      }
      if (!this.venda.itens || this.venda.itens.length <= 0) {
        erros.push("Informe ao menos um item na venda.");
      }
      if (
        this.venda.vl_total > 0 &&
        (!this.venda.pagamentos || this.venda.pagamentos.length <= 0)
      ) {
        erros.push("Informe ao menos uma forma de pagamento na venda.");
      }
      if (
        this.venda.vl_total &&
        this.venda.vl_pago &&
        Math.ceil(this.venda.vl_total) > 0 &&
        parseInt(Math.ceil(this.venda.vl_total)) !=
          parseInt(Math.ceil(this.venda.vl_pago))
      ) {
        erros.push(
          "O valor total não bate com o total das formas de pagamento. Favor Corrigir!"
        );
      }
      if (!this.venda.caixaControle || !this.venda.caixaControle?.id) {
        let temCx = await this.buscarCaixa();
        if (!temCx) return;
      }
      if (erros.length > 0) {
        let errorDesc = await erros.reduce((ret, vl) => {
          ret += vl + "<hr/>";
          return ret;
        }, "");
        this.$swal({
          title: "Atenção",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errorDesc}</span>`,
          icon: "error",
          allowEscapeKey: true,
        });
      } else {
        this.saving = true;
        this.loading = true;

        const errorAlert = () => {
          this.$swal({
            title: "Houve um problema",
            html: `<b>Erro ao executar a operação</b>`,
            icon: "error",
          });
        };

        try {
          let result = await VendaLib.storeVenda(this.venda);
          // //console.log("vnd", this.venda);
          // //console.log(result);
          if (!result?.success || !result) {
            ////console.log(result);
            errorAlert();
          } else {
            if (result.success) {
              this.venda.id = result.data[0];

              await this.$swal({
                title: "Concluído",
                icon: "success",
                text: "Venda salva com sucesso!",
              }).then((x) => {
                // //console.log("venda emitiu que concluiu", x);
                if (x.isConfirmed) {
                  this.$emit("concluido", result.data);
                  // if (this.horario) {
                  //   this.$emit("venda", this.venda);
                  // }
                }
              });
            }
          }
        } catch (e) {
          //console.log("Error", e);
          errorAlert();
        }
        this.loading = false;
        this.saving = false;
      }
    },
    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/venda.scss";
</style>
