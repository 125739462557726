import { render, staticRenderFns } from "./SelectExames.vue?vue&type=template&id=044ebf94&scoped=true&"
import script from "./SelectExames.vue?vue&type=script&lang=js&"
export * from "./SelectExames.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044ebf94",
  null
  
)

export default component.exports