import axios from "@/utils/axios.js";

const getResumido = async (
  id,
  pagination = null,
  filters = {
    withoutConvenio: undefined,
    status: undefined,
    alfabeto: undefined,
    geral: undefined,
    crm: undefined,
  },
  tipo
) => {
  let entidades = [];
  let pag = {};
  const { withoutConvenio, status, alfabeto, geral, crm } = filters;
  let result = (
    await axios.get("/entidades/resumo", {
      params: {
        id,
        pagination,
        withoutConvenio,
        status,
        alfabeto,
        geral,
        tipo,
        crm,
      },
    })
  ).data;
  //console.log("result,", result);
  entidades = result.Entidades;
  pag = result.pagination;
  return { entidades, pagination: pag };
};

const getSimples = async (filters) => {
  try {
    return (await axios.get("/entidades/getSimples", { params: filters })).data;
  } catch (err) {
    //console.log("erro ao getSimples", err);
    return [];
  }
}

const get = async (tipo = "", pagination = null, id = "", filters = {}) => {
  let entidades = [];
  let pag = {};
  //console.log("tipo recebido no get entidades", tipo);

  const { alfabeto, geral, withoutConvenio, status, especialidade_id, withAgendasFuturas } = filters;
  if(!pagination && !id)return;
  try {
    const result = await axios.get("/entidades/get", {
      params: {
        tipo,
        pagination,
        id,
        alfabeto,
        geral,
        withoutConvenio,
        status,
        especialidade_id,
        withAgendasFuturas
      },
    });
     //console.log(result);
    entidades = result.data.data;
    pag = result.data.pagination;
  } catch (err) {
    throw new Error(err.response.data.error);
  }

  return { entidades, pag };
};

const getById = async (id) => {
  return (await axios.get("/entidades/get", { params: { id } })).data;
};

const getFuncionariosConvenio = async (id) => {
  let funcionarios = [];

  await axios
    .get("/entidades/convenio/funcionarios", { params: { convenio_id: id } })
    .then((result) => {
      funcionarios = result.data;
    })
    .catch((err) => {
      return err;
    });

  return funcionarios.data;
};

const getByEspecialidade = async (especialidade, somenteMedicosAtivos =false) => {
  try {
    // //console.log('called getByEspecialidade', especialidade);
    let res = (await axios.get(`/entidades/porEspecialidade/${especialidade}?somenteMedicosAtivos=` + somenteMedicosAtivos, {
      params: {
      somenteMedicosAtivos:null,
    }}))
      .data;
    
    // //console.log('meds especs', res);
    return res;
  } catch (error) {
    //console.log("Erro no método getByEspecialidae", error);
  }
};

const store = async (data, isDependente = undefined) => {
  try {
    return (
      await axios.post("/entidades/store", data, { params: { isDependente } })
    ).data;
  } catch (error) {
    //console.log(error);
  }
};

const del = async (data) => {
  let retorno;
  await axios.post("/entidades/delete", data).then((res) => {
    // //console.log(data.data)
    retorno = res.data;
  });
  return retorno;
};

const buscaPorNome = async (nome, tipo, withLimit = null) => {
  //console.log("buscando", nome, tipo);
  let result = (
    await axios.post("/entidades/buscaNome", {
      nome,
      cpf: nome,
      searchWithOR: true,
      searchWithLIKE: true,
      tipo,
      withLimit,
      cartao: nome,
    })
  ).data;
  // //console.log('result,', result);
  if(result && result.length>0){
    result = result.filter(x=>x.status>0)
  }
  return result;
};
const buscaPorCampo = async (campos) => {
  // //console.log('filtro', campos);
  let result = (await axios.post("/entidades/buscaNome", campos)).data;
  // //console.log('result,', result);
 
  return result;
};

const getPacienteHistorico = async ({ paciente_id = "", nome = "" }) => {
  let result = (
    await axios.post(`/pacientes/historico`, { id: paciente_id, nome })
  ).data;
  return result;
};

const unificarCPF = async (cpf, idCorreto, nome = null) => {
  let result = (await axios.post("/entidades/unificarCPF", { cpf, idCorreto, nome }))
    .data;
  // //console.log('result,', result);

  return result;
};

const unificarCadastro = async (id, lista) => {
  return (await axios.post("/entidades/unicifarCadastro", { id, lista })).data;
}

const resinc = async (id) => {
  try {
    return (await axios.post("/entidades/resinc", { id })).data;
  } catch (err) {
    //console.log("erro", err);
    return false;
  }
}

const getCreditos = async (id) => {
  try {
    return (await axios.post("/entidades/creditos", { id })).data;
  } catch (err) {
    //console.log("erro", err);
    return false;
  }
}

export default {
  getCreditos,
  get,
  store,
  del,
  getFuncionariosConvenio,
  buscaPorNome,
  getById,
  buscaPorCampo,
  getResumido,
  getByEspecialidade,
  getPacienteHistorico,
  unificarCPF,
  getSimples,
  unificarCadastro,
  resinc
};
