import axios from '../utils/axios';

const get = async (id="") => {
    let result
 await axios.get('bandeiras', { params: { id } }).then(res=>{
    result = res.data
 }).catch(err=>{
     if (err.response.data) {
         throw err.response.data
     }else{
         throw new Error('Erro ao buscar bandeiras!')
     }
 })
    
    return result;
}

const store = async (data) => {
    
    let result = await axios.post('bandeiras', data).then(res=>{
       
         return res
     }).catch(err=>{
        if (err.response.data) {
            throw err.response.data
        }else{
            throw new Error('Erro ao inserir bandeira!')
        }
     })
     return result
}

const del = async (id) => {
    
    let result = await axios.delete(`bandeiras/${id}`).then(res=>{
       
         return res
     }).catch(err=>{
        if (err.response.data && result.data.error) {
            throw err.response.data.error.message
        }else{
            throw new Error('Erro ao excluir bandeira!')
        }
     })
     return result
}

export default {
    get,
    store,
    del
}