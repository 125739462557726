<template>
  <div class="caixa">
    <span v-for="grupo in listagem.lancamentos" :key="`cx_grp${grupo.grupo}`">
      <div class="grupo">{{ grupo.grupo }}</div>
      <div class="row pr-4 pl-4">
        <div
          v-for="lancamento in grupo.lista"
          :key="`cx_lanc${lancamento.id}`"
          class="col-12 col-lg-12 col-xl-6 p-2"
        >
         <VendaCard @input="onSelect($event)" :lancamento="lancamento" :value="value" />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import VendaCard from "./VendaCard.vue";
export default {
  components:{
    VendaCard
  },
  props: {
    listagem: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods:{
    onSelect(venda){
      // console.log(venda);
      //console.log('value', this.value);
      if(this.value?.id === venda?.id){this.$emit('input', {}); return;}
      this.$emit('input', venda)
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/caixa.scss";
.selected {
  background-color: $primary;
  color: white;
}
</style>
