<template>
  <div>
    <b-modal
      v-if="printNow"
      id="modal-print-gruia"
      no-enforce-focus
      no-fade
      hide-backdrop
      hide-footer
      size="lg"
      @hide="
        printNow = false;
        $emit('close');
      "
    >
      <template #modal-title>
        <b-btn variant="none" @click="print"><b-icon-printer /> Imprimir</b-btn>
        <b-btn variant="none" @click="download"
          ><b-icon-download /> Baixar</b-btn
        >
        <b-btn variant="none" @click="sendWpp"
          ><b-icon-whatsapp /> Enviar via Whatsapp</b-btn
        >
      </template>
      <div id="printDiv">
        <print-component
          :filename="`Guia `"
          :printNow="printNow"
          @done="$emit('done')"
          :titulo="`CONTRATO DE FIDELIZAÇÃO`"
          :show="true"
          style="max-width: 500px"
        >
          <div
            v-if="item && item.contrato && item.contrato.content"
            v-html="item.contrato.content"
          ></div>
        </print-component>
      </div>
    </b-modal>
    <b-modal id="modal-wpp" hide-footer title="Enviar guia para whatsapp">
      <b-form-group label="Número">
        <vue-simple-suggest
          :suggestion-click="true"
          v-model="wppNumber"
          :list="telefonesDisponiveis"
          :filter-by-query="true"
          ref="select-num-wpp"
          mode="select"
          :controls="{
            selectionUp: [38, 33],
            selectionDown: [40, 34],
            select: [13, 36],
            showList: [40],
            hideList: [27, 35, 13],
          }"
        >
          <input
            class="form-control optional-custom-input"
            v-model="wppNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="off"
            :id="`txtField${Math.random()}`"
            :name="`txtField${Math.random()}`"
          />
        </vue-simple-suggest>
        <b-alert v-if="wppAlert.show" :variant="wppAlert.type">
          {{ wppAlert.message }}
        </b-alert>
        <botoes-salvar-cancelar
          :okText="'Enviar'"
          :okIcon="'fab fa-whatsapp'"
          @salvar="enviarWpp"
          :saving="enviandoWpp"
          @cancelar="() => $bvModal.hide('modal-wpp')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../Print/PrintComponent.vue";
import moment from "moment";
import FidelizacaoLib from "../../libs/FidelizacoesLib";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import WhatsappService from "../../utils/WhatsappService";
import { jsPDF } from "jspdf";
import FilesLib from "../../libs/FilesLib";
import axios from "@/utils/axios.js";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default {
  components: {
    PrintComponent,
    BotoesSalvarCancelar,
  },
  props: {
    id: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      printNow: false,
      loading: true,
      data: moment().format("DD/MM/YYYY"),
      item: null,
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "warning",
        message: "aguarde...",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    async open() {
      this.printNow = true;
      await this.$nextTick();
      this.$bvModal.show("modal-print-gruia");
    },
    async carregar() {
      this.loading = true;
      console.log("carregando", this.id);
      this.item = await FidelizacaoLib.getFidelizacaoByID(this.id);
      if (this.item) {
        this.item = this.item.data;
        if (this.item.contrato && typeof this.item.contrato === "string") {
          this.item.contrato = JSON.parse(this.item.contrato);
        }
        console.log("res", this.item);
        this.open();
      }
    },
    async sendWpp() {
      // console.log("res", res);

      this.$bvModal.show("modal-wpp");
    },
    baseConv(string) {
      return btoa(string);
    },
    async download() {
      const doc = new jsPDF();
      let image = await this.$html2canvas(document.getElementById("printDiv"), {
        type: "dataURL",
      });
      doc.addImage(image, "JPEG", 10, 10);
      await doc.save("contrato_" + this.item.codigo + ".pdf");
    },
    async enviarWpp() {
      this.wppAlert.show = true;

      if (this.wppNumber.trim() == "" || this.wppNumber.length < 14) {
        this.wppAlert.type = "danger";
        this.wppAlert.message = "Informe um número válido";
      }
      this.enviandoWpp = true;
      const doc = new jsPDF();
      let image = await this.$html2canvas(document.getElementById("printDiv"), {
        type: "dataURL",
      });
      doc.addImage(image, "JPEG", 10, 10);
      let res = await doc.output("dataurlstring");
      const file = dataURLtoFile(res, "contrato.pdf");
      let fileUploaded = await FilesLib.uploadFile(file);
      console.log("pdf", fileUploaded);
      if (
        fileUploaded &&
        fileUploaded.status == 200 &&
        fileUploaded.data.success &&
        fileUploaded.data.file
      ) {
        console.log(
          "file",
          `${axios.defaults.baseURL}downloadFile/${fileUploaded.data.file}?token=${this.$store.state.auth.user.token}`
        );
        let res1 = await WhatsappService.enviarTextoSimples(
          this.wppNumber,
          "PROTOCOLO DE ENTREGA DO PROGRAMA FIDELIDADE SAÚDE SOCIAL"
        );
        let res2 = await WhatsappService.enviarDocumento(
          this.wppNumber,
          `${axios.defaults.baseURL}downloadFile/${fileUploaded.data.file}?token=${this.$store.state.auth.user.token}`,
          ""
        );
        console.log("ressss", res1, res2);
        if (res1 && res2) {
          this.$swal("Enviado com sucesso!", "", "success");
          this.$bvModal.hide("modal-wpp");
        }
      }
      // try {

      // } catch (error) {
      // console.log("ERROR ON SEND WPP", error);
      // }
      this.enviandoWpp = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
