<template>
  <div class="itemCard">
    <div class="num">.{{ num }}</div>
    <div class="titulo">
      {{ item.exameSelecionado ? item.exameSelecionado.descricao : "" }}
      <span v-if="item.exameSelecionado && item.exameSelecionado.fidelizcao">
        {{ item.exameSelecionado.fidelizcao.nome }}
      </span>
      <small>
        <small>
          {{ item.tipoVenda }}
          {{
            item.entidadeSelecionada
              ? ` - ${item.entidadeSelecionada.razao_social}`
              : ""
          }}
        </small>

        <small v-if="item.dependenteSelecionado">
          <br />

          <b>Paciente: </b>
          {{
            item.dependenteSelecionado.Nome ||
              item.dependenteSelecionado.razao_social
          }}
          <br />
        </small>

        <small v-if="item.solicitacaoExame">
          <b>Data:</b> {{ item.solicitacaoExame.data | moment("DD/MM/YYYY")
          }}<br />
          <b>Médico Solicitante:</b> {{ item.solicitacaoExame.medicoNome
          }}<br />
          <b-table
            small
            size="sm"
            :fields="[
              { key: 'nome', label: 'Exame' },
              { key: 'valor_venda', label: 'Valor', class: 'text-right' },
            ]"
            :items="item.solicitacaoExame.exames"
          >
            <template #cell(valor_venda)="row">
              <div class="text-right" :style="row.item.valor_desconto?'text-decoration: line-through; color:red;font-style: italic; ':''">
                {{ row.item.valor_original || row.item.valor_venda | currencyWithMask }}
              </div>
              <div class="text-right" v-if="row.item.valor_desconto">
                {{  row.item.valor_venda | currencyWithMask }}
              </div>
            </template>
          </b-table>
        </small>
      </small>
    </div>

    <div class="valor">
      <div
        v-if="
          item.valor_desconto ||
            (typeof item.desconto != 'undefined' &&
              item.desconto &&
              item.desconto != 'R$ undefined')
        "
        :class="
          'desconto ' + (item.valor_desconto * -1 > 0 ? 'text-success' : '')
        "
      >
        <span class="lin">{{ item.valor_original | currencyWithMask }}</span
        ><br />
        {{ item.valor_desconto * -1 > 0 ? "+" : "" }}
        {{ (item.valor_desconto * -1) | currencyWithMask }}
      </div>

      {{ item.valor_venda | currencyWithMask }}
    </div>

    <div class="options">
      <a href="#" class="text-warning mr-4" @click.prevent="editar"
        ><i class="fa fa-edit"></i> Editar</a
      >
      <a href="#" @click.prevent="remover"
        ><i class="fa fa-trash"></i> Remover</a
      >
    </div>

    <span
      class="text-muted"
      v-if="
        item.exameSelecionado && item.exameSelecionado.personalizar_por_forma
      "
    >
      * Esse item tem <b>desconto/acrescimo por forma de pagamento</b>
      <b-btn @click="openDescontosPersonalizados" class="mx-1" size="sm">
        Clique Aqui</b-btn
      >
      para visualiza-los
    </span>
    <span
      class="text-muted"
      v-if="
        item.descontoPorForma &&
          item.descontoPorForma.aplicado &&
          !item.descontoPorForma.desconto.acumulativo &&
          hasFidel
      "
    >
      * A fidelização não foi aplicada pois já há um outro desconto aplicado
    </span>

    <span
      class="text-muted"
      v-if="item.descontoPorForma && item.descontoPorForma.aplicado"
    >
      * Desconto/acrescimo de
      <b class="text-success">
        {{ `R$ ${item.descontoPorForma.desconto.desconto * -1}` }}
      </b>
      aplicado por conta da forma de pagamento ser
      <b>
        {{ `${item.descontoPorForma.desconto.formaDePagamento}` }}
      </b>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
    num: Number,
    hasFidel: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    editar() {
      this.$emit("editar", this.item);
    },
    remover() {
      this.$emit("remover", this.item);
    },
    openDescontosPersonalizados() {
      this.$emit("open-descontos", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/venda.scss";
</style>
