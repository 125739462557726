<template>
  <div>
    <small class="text-muted">
      *Selecione uma letra para filtrar
    </small>
    <div class="row ">
      <div class="col d-flex justify-content-center ">
        <ul class="pagination pagination-sm">
          <li
            class="page-item"
            :class="[{ active: value === char }, { disabled }]"
            v-for="char of alphabet"
            :key="char"
            @click="onCharClick"
          >
            <a class="page-link" href="#">{{ char }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alphabet: Array.apply(null, { length: 26 }).map((x, i) =>
        String.fromCharCode(65 + i)
      ),
    };
  },
  methods: {
    onCharClick(evt) {
      if (!evt || this.disabled) return;
      evt.preventDefault();
      const char = evt.target.innerText;
      if (char === this.value) {
        this.$emit("input", "");
        return;
      }
      this.$emit("input", char);
    },
  },
};
</script>

<style lang="scss">
.pagination{
 flex-flow: row wrap;
}
</style>
