export default function (html, tempo = 5, landScape = false) {
  let imp = window.open("", "imp", landScape ?"width=1000,height=600":"width=800,height=600");

    // let onload = () => {
    //   const e = document.createElement("div");
    //   e.innerHTML = "&lt;script&gt; window.addEventListener('load', function(){ alert(1) }, false);  t&lt;/script&gt;";
    //   return e.childNodes[0].nodeValue;
    // };

    imp.document.write(
        `
        <html style='widht:auto !important; height: auto !important; max-width:auto !important; max-height: auto !important; min-width: auto !important; min-height: auto !important'>
          <head>
            ${window.document.head.innerHTML}
            <style>              
                *{
                  -webkit-print-color-adjust: exact; 
                  print-color-adjust: exact;
                }
                html,body{
                  overflow: auto !important;
                  position: relative;
                  top: auto !important;
                  left:auto !important;
                  right:auto !important;
                  bottom:auto !important;
                  widht:auto !important; height: auto !important; max-width:auto !important; max-height: auto !important; min-width: auto !important; min-height: auto !important
                  print-color-adjust: exact;
                }
                tr, td{
                  -webkit-print-color-adjust: exact !important;  
                  print-color-adjust: exact;
                }
               @media print {
                 body {-webkit-print-color-adjust: exact; print-color-adjust: exact;}
                 tr.bg-light {
                       background-color: #f8f9fa !important;
                    -webkit-print-color-adjust: exact; 
                    print-color-adjust: exact;
                }
                tr.bg-light2 {
                       background-color: #ddd !important;
                    -webkit-print-color-adjust: exact; 
                    print-color-adjust: exact;
                }
                tr.bg-light3 {
                       background-color: #ccc !important;
                    -webkit-print-color-adjust: exact; 
                    print-color-adjust: exact;
                }
                 @page {
                  ${landScape ? `
                  -webkit-transform: scale(0.85);  /* Saf3.1+, Chrome */
                    -moz-transform: scale(0.85);  /* FF3.5+ */
                      -ms-transform: scale(0.85);  /* IE9 */
                      -o-transform: scale(0.85);  /* Opera 10.5+ */
                          transform: scale(0.85);
                          `: ``}
                    size: ${landScape ? 'landscape' :'A4'};
                    -webkit-print-color-adjust: exact; 
                    print-color-adjust: exact;
                    tr{
                      -webkit-print-color-adjust: exact; 
                    }
                    td{
                      -webkit-print-color-adjust: exact; 
                    }
                  }
                  -webkit-print-color-adjust: exact; 
                    tr{
                      -webkit-print-color-adjust: exact; 
                    }
                    td{
                      -webkit-print-color-adjust: exact; 
                    }
                 
              }
            </style>
            

          </head>
          <body style='widht:auto !important; height: auto !important; max-width:auto !important; max-height: auto !important; min-width: auto !important; min-height: auto !important'>
            <span id='tempo' style='z-index:999999999 !important; position:fixed; width:100%; height:100%; top:0; left:0; bottom:0; right:0; background-color:#00000080; font-weight:bold; padding-top:50px; text-align:center; font-size:90px; color:white;'></span>
            ${html}
          </body>
        </html>
        `
    );
    // imp.document.body.style.overflow='auto';
    let conta = !tempo?5:tempo;
    let inter = setInterval(() => {
        conta--;
        imp.document.querySelector('#tempo').innerHTML = 'AGUARDE <br/>' + conta;
        if (conta <= 0) {
            clearInterval(inter);
            imp.document.querySelector('#tempo').style.display = 'none';
            setTimeout(() => {
                imp.print();
                imp.close();
            }, 100)
        }
    }, 900)
}