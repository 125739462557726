var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('b-spinner'),_vm._v(" Carregando...")],1):_vm._e(),(_vm.devolucaoObj)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-3 col-sm-6"},[_c('b',[_vm._v("Código")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.id)+" ")]),_c('div',{staticClass:"col-12 mt-3 col-sm-6"},[_c('b',[_vm._v("Data")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.devolucaoObj.created_at,"DD/MM/YYYY HH:mm"))+" ")]),_c('div',{staticClass:"col-12 mt-3 col-sm-6"},[_c('b',[_vm._v("Valor Total")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(_vm.devolucaoObj.vl_total))+" ")]),_c('div',{staticClass:"col-12 mt-3 col-sm-6"},[_c('b',[_vm._v("Valor Estornar")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(_vm.devolucaoObj.vl_estorno))+" ")]),_vm._m(0),_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Usuário")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.usuario)+" ")]),_c('div',{staticClass:"col-12 mt-3"},[_c('b',[_vm._v("Cliente")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.Cliente)+" ")]),_c('div',{staticClass:"col-12 mt-3"},[_c('b',[_vm._v("Fornecedor")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.Fornecedor)+" ")]),_vm._m(1)])]),_c('div',{staticClass:"col-12 col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-"},[_c('b',[_vm._v("Motivo")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.motivo)+" ")]),_vm._m(2),_c('div',{staticClass:"col-12"},[_c('b',[_vm._v("Tipo de Devolução")]),_c('br'),_vm._v(" "+_vm._s(_vm.devolucaoObj.tipo_devolucao)+" ")]),_vm._m(3)]),(
          _vm.devolucaoObj &&
          _vm.devolucaoObj.creditos &&
          _vm.devolucaoObj.creditos.length > 0
        )?_c('div',[_c('h4',[_vm._v("Créditos Gerados")]),_c('b-table',{attrs:{"items":_vm.devolucaoObj.creditos,"fields":[
            { key: 'id', label: 'Código' },
            { key: 'created_at', label: 'Data' },
            { key: 'valor', label: 'Valor' },
            { key: 'vl_usado', label: 'Usado' },
          ]},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.created_at,"DD/MM/YYYY"))+" ")]}},{key:"cell(valor)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(row.item.valor))+" ")]}},{key:"cell(vl_usado)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(row.item.valor_usado))+" ")]}}],null,false,2580283319)})],1):_vm._e(),(
          _vm.devolucaoObj &&
          _vm.devolucaoObj.caixa_devolucao &&
          _vm.devolucaoObj.caixa_devolucao.length > 0
        )?_c('div',{staticClass:"mt-2"},[_c('h4',[_vm._v("Caixa Devolução")]),_c('b-table',{attrs:{"items":_vm.devolucaoObj.caixa_devolucao,"fields":[
            { key: 'dt_caixa', label: 'Data' },
            { key: 'empresa', label: 'Clínica' },
            { key: 'usuarioAbriu', label: 'Usuário' },
          ]},scopedSlots:_vm._u([{key:"cell(dt_caixa)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_caixa,"DD/MM/YYYY HH:mm"))+" ")]}}],null,false,4054354065)})],1):_vm._e(),_vm._m(4)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('h4',[_vm._v("Pagamento Descontado")]),_c('hr')])
}]

export { render, staticRenderFns }