import axios from "@/utils/axios.js";

const get = async (dataDe, dataAte) => {
  // console.log('datade', dataDe, 'dataate', dataAte)
  let solicitacoes = [];

  await axios
    .get("/solicitacoes/get", { params: { dataDe, dataAte } })
    .then((result) => {
      solicitacoes = result.data;
    })
    .catch((err) => {
      console.log("erro ao buscar as solicitacoes", err);
    });

  return solicitacoes.data;
};
const store = async (data) => {
  // console.log('data no store', data)
  // console.log(data);
  return (await axios.post("/solicitacoes/store", data)).data;
};

const agendarSolicitacao = async (data) => {
  return (await axios.post("/solicitacoes/agendar", data)).data;
};

const del = async (data) => {
  // console.log(data);
  return await axios.post("/solicitacoes/delete", data);
};

const buscaPorNome = async (nome) => {
  return (await axios.post('/solicitacoes/busca', nome)).data;
}

//ecma script
export default {
  get,
  store,
  agendarSolicitacao,
  del,
  buscaPorNome
};
