<template>
  <div>
    <!-- {{solicitacao.dt_utilizacao}}
    {{!!notAllowed}} -->

    <div class="row">
      <div class="col-6">
        <b-form-group>
          <label>Data da Consulta</label>
          <b-form-input
            :disabled="notAllowed"
            v-model="solicitacao.data"
            :state="validateState('data')"
            type="date"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group>
          <label>Horário da Consulta</label>
          <b-form-input
            :disabled="notAllowed"
            v-model="solicitacao.hora_consulta"
            :state="validateState('hora_consulta')"
            type="time"
            step="0"
            locale="br"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <select-medico-especialidade
      lockMedico
      @especialidade="solicitacao.especialidade_id = $event"
      :disabledEspecialidade="notAllowed"
      :disabledMedico="notAllowed"
      @medico="solicitacao.medico_id = $event"
      @medicoComplete="entidade = $event"
      :medico_id="solicitacao.medico_id"
      :especialidade_id="solicitacao.especialidade_id"
      :stateMedico="validateState('medico_id')"
      :stateEspecialidade="validateState('especialidade_id')"
    ></select-medico-especialidade>

    <div class="row">
      <div class="col"></div>
    </div>
    <small
      class="text-muted"
      v-if="laboratorioSelecionado.id && laboratorioSelecionado.razao_social"
    >
      * Somente exames do laboratorio
      <b class="bg-light">{{ laboratorioSelecionado.razao_social }}</b>
    </small>
    <div class="row">
      <div class="col">
        <b-form-group>
          <label>Exame</label>

          <select-exames
            :entidadeId="laboratorioSelecionado.id"
            :disabled="notAllowed"
            :searchTabs="['Exame']"
            :getWithFilters="false"
            tipo="Exame"
            :showBotaoAdd="false"
            :showBotaoEdit="false"
            @select="addExame"
          ></select-exames>
          <!-- <v-select
            :options="examesDisponiveis"
            @input="addExame"
            label="nome"
          ></v-select> -->
          <!-- <small class="text-muted">
              * Digite ao menos 3 letras para começar a busca.
          </small> -->
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-table
          :items="examesFiltradas"
          :fields="campos"
          small
          outlined
          show-empty
          class="shadow"
        >
          <!-- <template #cell(obs)="{item}">
            <b-form-input v-model="item.obs"></b-form-input>
          </template> -->
          <template #empty>
            <div class="text-center">Nenhum exame por aqui! 🧪</div>
          </template>
          <template #cell(actions)="{item}">
            <b-btn
              :disabled="notAllowed"
              variant="transparent"
              @click="removeExame(item)"
            >
              <i class="fas fa-times"></i>
            </b-btn>
          </template>
        </b-table>
        <b-form-invalid-feedback
          :force-show="
            typeof validateState('exames') == 'boolean' &&
              !validateState('exames')
          "
          >Informe ao menos um exame.</b-form-invalid-feedback
        >
      </div>
    </div>
    <div class="row" v-if="solicitacao.exames.length > 0">
      <div class="col">
        <b-form-group>
          <label>Observação</label>
          <b-form-textarea
            :disabled="notAllowed"
            v-model="solicitacao.obs"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
    <div v-if="!!solicitacao.id" class="row">
      <div class="col">
        <b-btn @click="callPrintExame">
          <i v-if="!solicitacao.printLoad" class="fas fa-print"></i>
          <b-spinner small v-if="solicitacao.printLoad"></b-spinner> Imprimir
          Exame</b-btn
        >
      </div>
    </div>
    <div
      class="row justify-content-center border rounded  p-2  m-2"
      v-if="solicitacao.dt_utilizacao && solicitacao.venda_id"
    >
      <h4 class="col-12 text-muted text-center mt-1">
        Solicitação já feita
      </h4>
      <b-btn size="" variant="primary" @click="openVenda" class="col-6">
        <i class="fas fa-shopping-basket"></i> Visualizar Venda
      </b-btn>
    </div>

    <BotoesSalvarCancelar2
      v-if="showButtons && !notAllowed"
      @salvar="salvar"
      :saveDisabled="notAllowed"
      @cancelar="$emit('close')"
    />

    <imprimir-exame
      :id="solicitacao.id"
      v-if="printExame"
      @close="closePrintExame()"
      @done="donePrint()"
    />

    <b-modal id="modal-venda" size="lg" hide-footer no-enforce-focus>
      <VendaDetail
        readonly
        @close="closeVenda"
        ref="vendaDetails"
        :vendaId="solicitacao.venda_id"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import ExamesLib from "../../../../libs/ExamesLib";
import SolicitacaoExamesLib from "../../../../libs/SolicitacaoExamesLib";
import SelectMedicoEspecialidade from "../../../common/SelectMedicoEspecialidade.vue";
import BotoesSalvarCancelar2 from "../../../common/BotoesSalvarCancelar.vue";
import SelectExames from "../../../common/SelectExames.vue";
import Validadores from "../../../../helpers/Validadores";
import ImprimirExame from "./ImprimirExame.vue";
import VendaDetail from "@/components/Caixas/Vendas/VendaDetail.vue";
// import { required, minLength } from "vuelidate/lib/validators";
export default {
  props: {
    solicitacao_edit: Object,
    paciente: Object,
    horario: Object,
    insideVenda: { type: Boolean, default: false },
    laboratorio: {
      type: Object,
      default: () => {
        return { id: null };
      }
    },
    showButtons: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectMedicoEspecialidade,
    BotoesSalvarCancelar2,
    SelectExames,
    ImprimirExame,
    VendaDetail
  },
  // mixins:{
  //   validationMixin
  // },
  // validations:{
  //   solicitacao: {
  //       data:{required},
  //       medico_id: {required},
  //       especialidade_id: {required},
  //       paciente_id: {required},
  //       obs: "",
  //       exames:{minLength:minLength(1)},
  //     },
  // },
  data() {
    return {
      laboratorioSelecionado: {
        id: null,
        razao_social: ""
      },
      campos: [
        // {
        //   key: "exameId",
        //   label: "Código",
        //   class: "text-center",
        // },
        {
          key: "nome",
          label: "Exame",
          class: "text-center text-nowrap"
        },
        {
          key: "descricao",
          label: "Descrição",
          class: "text-center"
        },
        // {
        //   key: "obs",
        //   label: "Observação",
        //   class: "text-center",
        // },
        {
          key: "actions",
          label: "",
          class: "text-center"
        }
      ],
      examesDisponiveis: [],

      solicitacao: {
        data: moment().format("YYYY-MM-DD"),
        hora_consulta: moment().format("HH:mm"),
        medico_id: null,
        especialidade_id: null,
        paciente_id: this.paciente ? this.paciente.id : null,
        obs: "",
        horario_id: this.horario?.id,
        exames: []
      },
      printExame: false,
      //user: this.$store.state.auth.user,
      hasErrors: [],
      entidade: null
    };
  },
  methods: {
    async carregaExames() {
      this.examesDisponiveis = (await ExamesLib.get("Exame")).map(ex => {
        return { ...ex, exameId: ex.id };
      });
    },
    async callPrintExame() {
      this.solicitacao.printLoad = true;

      this.$forceUpdate();
      await this.$nextTick();
      setTimeout(async () => {
        this.itemSelected = this.solicitacao;
        await this.$nextTick();
        this.printExame = true;
        await this.$nextTick();
      }, 500);
    },
    openVenda() {
      if (!this.solicitacao.venda_id) return;
      this.$bvModal.show("modal-venda");
    },
    closeVenda() {
      this.$bvModal.show("modal-venda");
    },
    closePrintExame() {
      this.printExame = false;
      this.$swal
        .fire({
          title: "Deseja fechar a solicitação?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Não.",
          confirmButtonText: " Sim."
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.$emit("close");
          }
        });
    },

    donePrint() {
      // console.log("DONE PRINT");
      this.solicitacao.printLoad = false;
    },
    setSolicitacao(exame) {
      this.solicitacao = Object.assign(this.solicitacao, exame);
      
    },
    addExame(exame) {
      if (!exame) return;
      if (
        this.solicitacao.exames
          .filter(e => e._rowStatus !== "deleted" || !e._rowStatus)
          .some(ex => ex.id == exame.id || ex.exameId == exame.id)
      ) {
        this.showToast(
          "error",
          "Este exame já foi adicionado à solicitação!",
          3500
        );
        return;
      }
      this.solicitacao.exames.push({
        exameSelecionado: exame,
        ...exame,
        exameId: exame.id,
        _rowStatus: "added"
      });
    },
    removeExame(_exame) {
      let i = this.solicitacao.exames.findIndex(
        ex => ex.itemId == _exame.itemId
      );
      if (!this.solicitacao.exames[i]._rowStatus) {
        this.$set(this.solicitacao.exames[i], "_rowStatus", "deleted");
      } else {
        //        this.solicitacao.exames.splice(i, 1);
        this.solicitacao.exames = this.solicitacao.exames.filter(
          x => x != _exame
        );
      }
    },
    validateState(campo) {
      let hasError = this.hasErrors.find(x => x.campo == campo);

      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find(x => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map(error => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();
      await this.$nextTick();

      this.addOrUpdateError(
        "data",
        !Validadores.string.IsNullOrEmpty(this.solicitacao.data)
      );

      this.addOrUpdateError(
        "hora_consulta",
        !Validadores.string.IsNullOrEmpty(this.solicitacao.hora_consulta)
      );

      this.addOrUpdateError(
        "especialidade",
        !!this.solicitacao.especialidade_id
      );

      this.addOrUpdateError("medico", !!this.solicitacao.medico_id);

      this.addOrUpdateError("exames", this.solicitacao.exames.length > 0);
      //console.log(this.hasErrors);
      return {
        success: !this.hasErrors.some(e => e.state === false),
        messages: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>\n ${this.hasErrors
          .filter(x => x.state == false)
          .map(
            error => error.campo.charAt(0).toUpperCase() + error.campo.slice(1)
          )
          .reduce((it, vl) => {
            it += vl + " <hr />";
            return it;
          }, "")}`
      };
    },
    async salvar() {
      delete this.solicitacao.printLoad;
      this.$forceUpdate();
      await this.$nextTick();
      let validacao = await this.validar();
      console.log("validacao", validacao);
      if (!validacao.success) {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: validacao.messages
        });
        return;
      }
      // console.log(this.insideVenda, this.solicitacao);
      if (
        !this.insideVenda ||
        (this.insideVenda && this.solicitacao.id && this.solicitacao.id > 0)
      ) {
        try {
          let result = await SolicitacaoExamesLib.store(this.solicitacao);
          console.log("reuslt da solicitacao exame: ", result);
          this.solicitacao.id = result?.data?.data;
          if (result.status == 200 || result.status == 201) {
            this.$emit("salvar", this.solicitacao);
            this.$emit("salvou", {
              close: false,
              id: this.solicitacao.id,
              cb: this.setSolicitacao
            });

            this.showToast("success", "Solicitação salva com sucesso!");
            await this.$swal
              .fire({
                title: "Deseja imprimir a solicitação?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Não.",
                confirmButtonText: '<i class="fas fa-print"></i> Sim.'
              })
              .then(async result => {
                if (result.isConfirmed) {
                  await this.callPrintExame();
                } else {
                  this.$emit("salvou");
                }
              });
          }
        } catch (error) {
          console.log("erro ao inserir", error);
          this.showToast("error", "Alguma coisa deu errado :/\n" + error, 3600);
        }
      } else {
        //console.log('AAAAAAAAAAAAAAAAAAAAAAA');

        this.solicitacao.medicoNome = this.entidade
          ? this.entidade.razao_social
          : "";
        this.solicitacao.lbl =
          moment(this.solicitacao.data).format("DD/MM/YYYY") +
          " | " +
          this.solicitacao.medicoNome;
        this.$emit("salvar", this.solicitacao);
      }
      this.$emit("salvou", { close: false, id: this.solicitacao?.id });
    }
  },
  async created() {
    // this.carregaExames();
    this.$forceUpdate();
    await this.$nextTick();
    if (this.laboratorio && this.laboratorio?.id) {
      this.laboratorioSelecionado = Object.assign(
        this.laboratorioSelecionado,
        this.laboratorio
      );
    }
    if (
      (this.solicitacao_edit && this.solicitacao_edit.id) ||
      (this.insideVenda && this.solicitacao_edit)
    ) {
      this.solicitacao = JSON.parse(JSON.stringify(this.solicitacao_edit));
    }
  },
  watch: {
    // solicitacao_edit: {
    //   deep: true,
    //   handler() {
    //     console.log("solicitacao changed", this.solicitacao_edit);
    //      this.$nextTick(()=>{
    //         this.solicitacao = this.solicitacao_edit;
    //      })
    //   },
    // },
  },
  computed: {
    examesFiltradas() {
      return this.solicitacao.exames.filter(exame => {
        return exame._rowStatus
          ? exame._rowStatus == "deleted"
            ? false
            : true
          : true;
      });
    },
    notAllowed() {
      return (
        (this.user.isMedico &&
          this.user.entidade.id != this.solicitacao.medico_id) ||
        // moment().isAfter(moment(this.solicitacao?.data).add(1, "day")) ||
        (this.solicitacao?.dt_utilizacao && this.solicitacao?.venda_id)
      );
    }
  }
};
</script>

<style></style>
