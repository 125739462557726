import axios from '../utils/axios';

const get = async ({ paciente_id = "", id="" }) => {

    let result = [];
    await axios.get('pacientes/exames', { params: { paciente_id, id } }).then(res => {
        result = res.data

    }).catch(err => {
        if (err.response.data) {
            throw err.response.data
        } else {
            throw new Error('Erro ao buscar exames!')
        }
    })

    return result;
}

const store = async (data) => {

    let result = await axios.post('pacientes/exames', data).then(res => {

        return res
    }).catch(err => {
        if (err.response.data) {
            throw err.response.data
        } else {
            throw new Error('Erro ao inserir exames!')
        }
    })
    return result
}

const del = async (id) => {

    let result = await axios.delete(`pacientes/exames/${id}`).then(res => {

        return res
    }).catch(err => {
        if (err.response.data && result.data.error) {
            throw err.response.data.error.message
        } else {
            throw new Error('Erro ao excluir exames!')
        }
    })
    return result
}

export default {
    get,
    store,
    del
}