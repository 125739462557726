<template>
  <div>
    <!-- {{notaFiscal}} -->
    <div class="container">
      <div class="row nota-detalhes">
        <div class="col border rounded mx-2 py-3">
          <div class="separator">Detalhes</div>
          <div class="d-flex p-2 justify-content-between">
            <div><b>Código: </b> {{ notaFiscal.id }}</div>

            <div><b>Id Plug.: </b> {{ notaFiscal.id_envio }}</div>

            <div><b>Venda: </b> {{ notaFiscal.venda_id }}</div>
          </div>

          <div name="Tomador (Cliente)">
            <div class="row mt-4">
              <div class="col">
                <div class="separator">Tomador (Cliente)</div>
              </div>
            </div>
            <!-- <div class="row mt-1" >
              <div class="col">
                <b-form-group>
                  <label>Endereços</label>
                  <b-form-select size="sm"></b-form-select>
                  <small class="text-muted">* Todos endereços cadastrados do cliente, por padrão é selecionado o primeiro</small>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label>Telefones</label>
                  <b-form-select size="sm"></b-form-select>
                   <small class="text-muted">* Todos telefones cadastrados do cliente, por padrão é selecionado o primeiro</small>
                </b-form-group>
              </div>
            </div> -->
            <div class="row mt-1">
              <div class="col-12 col-sm-7">
                <b-form-group>
                  <label>Razão Social</label>
                  <b-form-input
                    placeholder="Razão Social"
                    size="sm"
                    v-model="notaFiscal.tomador.razao_social"
                    :disabled="!letEdit"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label>CPF</label>
                  <b-form-input
                    placeholder="CPF"
                    size="sm"
                    v-model="notaFiscal.tomador.cpf"
                    :disabled="!letEdit"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-5">
                <b-form-group>
                  <label>Telefone / Celular</label>
                  <div class="row">
                    <div class="col-3 pr-0 mr-0">
                      <b-form-input
                        :disabled="!letEdit"
                        class="text-center"
                        v-model="notaFiscal.tomador.telefone.ddd"
                        size="sm"
                      ></b-form-input>
                    </div>
                    <div class="col pl-0 ml-1">
                      <b-form-input
                        :disabled="!letEdit"
                        class="text-center"
                        size="sm"
                        v-model="notaFiscal.tomador.telefone.numero"
                      ></b-form-input>
                    </div>
                  </div>
                </b-form-group>
              </div>

              <div class="col-12 col-sm-5">
                <b-form-group>
                  <label>CEP</label>
                  <b-form-input
                    :disabled="!letEdit"
                    v-model="notaFiscal.tomador.endereco.cep"
                    placeholder="CEP"
                    @focusout="searchCEP"
                    v-mask="'#####-###'"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12 col-sm-2 mr-1 pr-0">
                <b-form-group>
                  <label>Cód.</label>
       
                  <b-btn
                    v-if="
                      !notaFiscal.tomador.endereco.codCidade 
                    "
                    @click="handlerCodCidade"
                    variant="transparent"
                  >
                    <i class="fas fa-exclamation-circle text-danger"></i>
                  </b-btn>
                  <b-form-input
                    :disabled="!letEdit"
                    placeholder="Cód. Cidade"
                    v-model="notaFiscal.tomador.endereco.codCidade"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-sm pl-sm-1">
                <b-form-group>
                  <label>Cidade</label>
                  <b-form-input
                    :disabled="!letEdit"
                    v-model="notaFiscal.tomador.endereco.cidade"
                    placeholder="Cidade"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-3 pl-sm-1">
                <b-form-group>
                  <label>UF</label>
                  <b-form-select
                    :disabled="!letEdit"
                    :options="['SP', 'MG']"
                    :value="notaFiscal.tomador.endereco.uf"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12 col-sm-3 ">
                <b-form-group>
                  <label>Tipo Log.</label>

                  <b-form-select
                    :disabled="!letEdit"
                    :v-model="notaFiscal.tomador.endereco.tipoLogradouro"
                    :value="notaFiscal.tomador.endereco.tipoLogradouro"
                    :options="tiposLogradouro"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-5 ml-sm-0 pl-sm-1">
                <b-form-group>
                  <label>Logradouro</label>
                  <b-form-input
                    :disabled="!letEdit"
                    placeholder="Logradouro"
                    size="sm"
                    v-model="notaFiscal.tomador.endereco.logradouro"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class=" col-4 ">
                <b-form-group>
                  <label>Número</label>
                  <b-form-input
                    :disabled="!letEdit"
                    v-model="notaFiscal.tomador.endereco.numero"
                    placeholder="Número"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12 col-sm-7">
                <b-form-group>
                  <label>Bairro</label>
                  <b-form-input
                    :disabled="!letEdit"
                    v-model="notaFiscal.tomador.endereco.bairro"
                    placeholder="Bairro"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-5">
                <b-form-group>
                  <label>Complemento</label>
                  <b-form-input
                    :disabled="!letEdit"
                    v-model="notaFiscal.tomador.endereco.complemento"
                    placeholder="Complemento"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>

          <div name="Valores">
            <div class="row">
              <div class="col">
                <div class="separator">Valores</div>
              </div>
            </div>

            <div class="d-flex ">
              <b-form-group class="mr-5">
                <label> Valor Total</label>
                <money
                  :disabled="!letEdit"
                  placeholder="Valor Total"
                  class="form-control form-control-sm"
                  v-bind="money"
                  v-model="notaFiscal.valor"
                  size="sm"
                ></money>
              </b-form-group>

              <b-form-group class="mr-2">
                <label> % Aliquota</label>
                <money
                  placeholder="Aliquota"
                  :disabled="!letEdit"
                  class="form-control form-control-sm"
                  v-bind="pct"
                  v-model="notaFiscal.aliquota"
                  size="sm"
                ></money>
              </b-form-group>

              <b-form-group>
                <label> Valor ISS</label>
                <money
                  placeholder="Valor ISS"
                  class="form-control form-control-sm"
                  v-bind="money"
                  v-model="notaFiscal.valor_iss"
                  size="sm"
                  disabled
                ></money>
              </b-form-group>
            </div>
          </div>

          <div name="Infos">
            <div class="row mt-1">
              <div class="col">
                <div class="separator">Infos</div>
              </div>
            </div>
            <div class="d-flex p-2 justify-content-center">
              <div class="" v-if="notaFiscal.usuarioSolicitou">
                <b> <i class="fas fa-check"></i> Usuário Solicitou: </b>
                {{ notaFiscal.usuarioSolicitou }}
              </div>
              <div v-if="notaFiscal.usuario_cancelou">
                <b> <i class="fas fa-ban"></i> Usuário Cancelou: </b> Guilherme
                Teixeira Ais
              </div>
            </div>
          </div>
        </div>
        <div class="col border rounded mx-2  p-3">
          <div class="border rounded">
            <div class="d-flex   p-2 text-center">
              <div><b>Data de Criação: </b> 19/10/2021</div>

              <div><b>Data de Envio: </b> 19/10/2021</div>

              <div><b>Data do Retorno: </b> 19/10/2021</div>
            </div>
            <div class="d-flex align-items-center  p-2 text-center">
              <div class="col">
                <label
                  >Status Atual
                  <i
                    v-if="situacao && notaFiscal.status !== situacao"
                    class="fas fa-circle"
                    style="font-size: 10px;"
                  ></i>
                </label>

                <b-form-select
                  class="text-center "
                  :value="notaFiscal.status"
                  @input="situacao = $event"
                  :options="[
                    'CONCLUIDO',
                    'SOLICITADO',
                    'REJEITADO',
                    'ERRO',
                    'PROCESSANDO',
                    'ENVIADO',
                    'CANCELADO',
                    'CANCELAR',
                    'AGUARDE',
                    'ALTERADO',
                  ]"
                  size="sm"
                ></b-form-select>
              </div>
              <b-btn
                v-if="situacao && notaFiscal.status !== situacao"
                @click="updateNota"
                variant="transparent"
                class="mt-auto"
                size="sm"
              >
                <i class="fas fa-save"></i>
              </b-btn>
              <div class="col">
                <b>Mensagem Atual: </b> {{ notaFiscal.msg }}
              </div>
            </div>
          </div>

          <div name="Logs">
            <div class="row mt-4">
              <div class="col">
                <div>
                  <b-btn
                    @click="getStatusNota"
                    class="d-block ml-auto"
                    size="sm"
                  >
                    <i class="fas fa-sync-alt"></i> Atualizar</b-btn
                  >
                  <small class="text-muted d-flex justify-content-end">
                    *Força a verificação do status da nota.
                  </small>
                </div>
                <div v-if="showResolicitar">
                  <b-btn
                    @click="resolicitar"
                    class="d-block ml-auto mt-2"
                    
                    size="sm"
                    variant="info"
                  >
                    <i class="fas fa-redo"></i> Re-Solicitar</b-btn
                  >
                  <small class="text-muted d-flex justify-content-end">
                    *Nota fiscal demorando muito pra solicitar? Re-solicite ela clicando aqui...
                  </small>
                </div>

                <div class="separator">Logs</div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <b-table
                  :fields="campos"
                  :items="notaFiscal.logs"
                  show-empty
                  outlined
                  small
                >
                  <template #cell(data)="{item}">
                    {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                  </template>
                  <template #empty>
                    <div class="text-center text-muted d-flex flex-column p-2">
                      <div class="justify-content-center py-2 shadow">
                        <img
                          width="150px"
                          height="125px"
                          :src="
                            require('@/assets/img/undraw_No_data_re_kwbl.svg')
                          "
                          alt=""
                        />
                        <p class="mt-2">
                          Nada aqui...
                        </p>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-content-end border-top mt-3">
      <div class="col d-flex  pt-2">
        <b-btn
          :disabled="!['ERRO', 'REJEITADO'].includes(notaFiscal.status)"
          variant="warning"
          @click="onAlterar"
          class="mr-3"
        >
          <i class="fas fa-edit"></i>
          Alterar
        </b-btn>
        <b-btn
          variant="info"
          class="mr-3"
          :disabled="notaFiscal.status !== 'ALTERADO'"
          @click="onReenviar"
        >
          <i class="fas fa-retweet"></i>
          Reenviar
        </b-btn>
        <b-btn
          variant="danger"
          :disabled="notaFiscal.status !== 'CONCLUIDO'"
          class="mr-auto"
          @click="onCancelar"
        >
          <i class="fas fa-ban"></i>
          Cancelar
        </b-btn>

        <b-btn
          variant="success"
          :disabled="notaFiscal.status !== 'CONCLUIDO' || !notaFiscal.pdfUrl"
          class="mr-3"
          @click="downloadPDF(notaFiscal.pdfUrl)"
        >
          <i class="fas fa-print"></i> Imprimir</b-btn
        >
        <b-btn variant="transparent" class="" @click="$emit('close')">
          <i class="fas fa-door-closed"></i> Fechar</b-btn
        >

        <!-- <b-btn variant="danger">
              <i class="fas fa-ban"></i> Limpar
            </b-btn> -->
      </div>
    </div>
  </div>
</template>

<script>
import NotasFiscaisLib from "../../libs/NotasFiscaisLib";
//import axios from "axios";
import { buscarCEP } from "../../utils/cep";
import EnderecosLib from "../../libs/EnderecosLib";
export default {
  props: {
    itemId: [Number, String],
    itemObj: Object,
  },
  data() {
    return {
      tiposLogradouro: [
        "Alameda",
        "Avenida",
        "Chácara",
        "Colônia",
        "Condomínio",
        "Estância",
        "Estrada",
        "Fazenda",
        "Praça",
        "Prolongamento",
        "Rodovia",
        "Rua",
        "Sítio",
        "Travessa",
        "Vicinal",
        "Eqnp",
      ],
      notaFiscal: {
        tomador: {
          cpfCnpj: "",
          inscricaoMunicipal: "",
          inscricaoEstadual: "",
          razaoSocial: "",
          telefone: {
            ddd: "",
          },
          endereco: {
            tiposLogradouro: "",
            logradouro: "",
            numero: "",
            complemento: "",
            tipoBairro: "",
            bairro: "",
            codCidade: "",
            uf: "",
            cidade: "",
            cep: "",
          },
        },
        tomador_id: null,
        venda_id: null,
        valor: null,
        aliquota: null,
        valor_iss: null,
        xml: null,
        xml_proc: null,
        pdf: null,
        status: "",
        id_envio: null,
        protocolo: null,
        msg: "",
        logs: [],
        prestador: {},
        empresa_id: null,
      },
      situacao: "",
      pct: {
        prefix: "% ",
        precision: 2,
        decimal: ",",
        masked: false,
      },
      letEdit: false,
      money: {
        prefix: "R$ ",
        precision: 2,
        decimal: ",",
        masked: false,
      },
      campos: [
        { key: "data", label: "Data", sortable: true, class: "text-center" },
        { key: "msg", label: "Mensagem", class: "text-center" },
        { key: "status", label: "Status", class: "text-center" },
      ],
    };
  },
  computed:{
    showResolicitar(){
      const isAtSolicitado = this.notaFiscal.status === "SOLICITADO"

      return isAtSolicitado
    }
  },
  methods: {
    async updateNota() {
      this.$forceUpdate();
      await this.$nextTick();
      this.notaFiscal.status = this.situacao;
      try {
        let result = await NotasFiscaisLib.updateNFE(this.notaFiscal);
        this.$emit("carregar");
    //    console.log(result);
        if (result) {
          this.showToast("success", "Status da nota atualizada com sucesso!");
        }
      } catch (error) {
        console.log(error);
      }
    },
    resolicitar(){
        this.onReenviar()
    },
    async searchCEP({ target: { value: cep } }) {
    //  console.log(cep);
      if (typeof cep === "string" && cep.replace("-", "").length === 8) {
        try {
          const enderecoFinded = await buscarCEP(cep.replace("-", ""));
          if (enderecoFinded) {
            if (enderecoFinded.street.length > 0) {
              this.notaFiscal.tomador.endereco.logradouro =
                enderecoFinded.street;
            }

            if (enderecoFinded.city.length > 0) {
              this.notaFiscal.tomador.endereco.cidade = enderecoFinded.city;
            }
            if (enderecoFinded.neighborhood.length > 0) {
              this.notaFiscal.tomador.endereco.bairro =
                enderecoFinded.neighborhood;
            }
            if (enderecoFinded.state) {
              this.notaFiscal.tomador.endereco.uf = enderecoFinded.state;
            }

            await this.handlerCodCidade();
          }
        } catch (error) {
          console.log("Erro ao buscar CEP", error);
        }
      }
    },
    async handlerCodCidade() {
      let cidade = this.notaFiscal.tomador.endereco.cidade;
      let uf = this.notaFiscal.tomador.endereco.uf;
    //  console.log("cidade", cidade, "uf", uf);
      if (cidade && uf) {
        let codMunicipio = (await EnderecosLib.IBGE.getMunicipios(uf, cidade))
          ?.codMunicipio;
        if (codMunicipio) {
          this.$set(
            this.notaFiscal.tomador.endereco,
            "codCidade",
            codMunicipio
          );
        } else {
          this.$swal
            .fire({
              title: "O Código do Municipio está faltando",
              icon: "warning",
              html:
                "Não encontramos o código do municipio, provávelmente o UF ou o nome da cidade foi digitado incorretamente.",
              showCancelButton: true,
              confirmButtonText: "Clique aqui para buscar",
            })
            .then((e) => {
              this.notaFiscal.status = "ERRO";
              if (e.isConfirmed) {
                window.open("https://cidades.ibge.gov.br/brasil/panorama");
              }
            });
        }
      }
      this.$forceUpdate;
      await this.$nextTick();
    },
    async downloadPDF(url) {
      window.open(url)
      // await axios
      //   .get(url, { responseType: "blob" })
      //   .then((res) => {
      //     const blob = new Blob([res.data], { type: "application/pdf" });
      //     const link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.target = "_blank";
      //     link.click();
      //     URL.revokeObjectURL(link.href);
      //   })
      //   .catch((err) => {
      //     console.log("Erro ao baixar PDF ->", err);
      //     this.showToast(
      //       "error",
      //       "Há algum problema com o PDF dessa nota, consulte o T.I",
      //       3500
      //     );
      //   });
    },
    getStatusNota() {
      this.$socket.emit("venda:getStatusNFSE", this.notaFiscal.id);
    },
    async onAlterar() {
      this.situacao = "ALTERADO";
      await this.updateNota();
    },
    async onReenviar() {
      this.situacao = "ENVIADO";
      this.$socket.emit("venda:reenviarNFSE", this.notaFiscal.id);
    },
    async onCancelar() {
      await this.$swal
        .fire({
          title: "Você tem certeza que deseja cancelar esta nota fiscal?",
          icon: "warning",
          html:
            "Será <b>solicitado o cancelamento da nota</b> e isso <b>não poderá ser desfeito</b>.",
          showCancelButton: true,
          confirmButtonText: "Sim. Solicitar cancelamento!",
          cancelButtonText: "Não.",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
         
            let result = await NotasFiscaisLib.cancelarNFSE(this.notaFiscal.id)
            console.log('resultado do cancelamento: ', result)
            this.$emit('carregar')
          }
        });
    },
  },

  mounted() {
    this.$forceUpdate();
    this.$nextTick(() => {
      if (this.itemObj) {
        this.notaFiscal = Object.assign({}, this.itemObj);
      }
    });
  },
  watch: {
    "notaFiscal.valor"() {
      this.notaFiscal.valor_iss =
        parseFloat(this.notaFiscal.valor) *
        (parseFloat(this.notaFiscal.aliquota) / 100);
    },
    "notaFiscal.aliquota"() {
      this.notaFiscal.valor_iss =
        parseFloat(this.notaFiscal.valor) *
        (parseFloat(this.notaFiscal.aliquota) / 100);
    },
    "notaFiscal.status"() {
      if (["ERRO", "REJEITADO"].includes(this.notaFiscal.status)) {
        this.letEdit = true;
      } else {
        this.letEdit = false;
      }
    },
    itemObj() {
      this.notaFiscal = Object.assign({}, this.itemObj);
    },
  },
};
</script>

<style lang="scss">
.nota-detalhes {
  font-size: 14px;
}
.separator {
  font-size: 17px;
  color: #0a0a0a;
  display: flex;

  align-items: center;
  &::before {
    content: "";

    flex: 1;
    height: 1px;
    background: #dfdfdf;
    margin-right: 16px;
  }
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #dfdfdf;
    margin-left: 16px;
  }
}
</style>
