<template>
  <div>
    <b-form-group>
      <label>
        <img
          v-if="icon"
          src="../../../src/assets/img/icones/menu/card_security_40px_dark.png"
        />
        Bandeiras
      </label>
      <v-select
        :options="bandeiras"
        :reduce="customReduce"
        label="nome"
        :value="value"
        @input="handleInput"
        :disabled="disabled"
      >
        <template #option="{ nome, debito, credito }">
          <h4 style="margin: 0">{{ nome }}</h4>
          <small class="">
              Aceita: 
                     <small class="mr-2" v-if="!!credito"><i class="fas fa-money-check"></i> Credito</small>
          <small  v-if="!!debito"><i class="fas fa-money-check-alt"></i> Debito</small>
          </small>
   
        </template>

        <template #search="{ attributes, events }">
          <input
            :class="['vs__search rounded']"
            v-bind="attributes"
            v-on="events"
          />
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Nenhum resultado para <em>{{ search }}</em
            >.
          </template>
          <em style="opacity: 0.5" v-else
            >Não encontramos nenhuma bandeira 😕.</em
          >
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<script>
import CartaoBandeirasLib from "../../libs/CartaoBandeirasLib";
export default {
  props: {
    value: Number,
    disabled: { type: Boolean, deafult: false },
    icon: { type: Boolean, deafult: true },
    customReduce: { type: Function, default: (i) => i.id },
  },
  data() {
    return {
      bandeiras: [],
    };
  },
  methods: {
    async carregaBandeiras() {
      this.bandeiras = await CartaoBandeirasLib.get();
      // this.empresa = this.empresas[0];
    },
    handleInput(bandeira) {
      this.$emit("input", bandeira);
      
      let idx 
      if(['string', 'number'].includes(typeof bandeira)) this.bandeiras.findIndex(b=>b.id == bandeira)
      if(typeof bandeira == 'object'){
        for (const key of Object.keys(bandeira)) {
          if(key == 'id')idx = this.bandeiras.findIndex(b=>b.id == bandeira[key])
        }
      }
      if(idx !== -1){
        this.$emit("bandeiraComplete", this.bandeiras[idx]);
      }else{
        this.$emit("bandeiraComplete", null);
      }
     
    },
  },
  async mounted() {
    await this.carregaBandeiras();
  },
};
</script>

<style></style>
