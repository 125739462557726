import { render, staticRenderFns } from "./SelectMedicoEspecialidade.vue?vue&type=template&id=68bae733&"
import script from "./SelectMedicoEspecialidade.vue?vue&type=script&lang=js&"
export * from "./SelectMedicoEspecialidade.vue?vue&type=script&lang=js&"
import style0 from "./SelectMedicoEspecialidade.vue?vue&type=style&index=0&id=68bae733&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports