<template>
  <div>
    <!-- {{receitas}} -->
    <div class="container-fluid">
      <div class="p-5 text-center" v-if="loading">
        <b-spinner /><br />
        aguarde...
      </div>
      <div class="text-right">
        <b-button variant="primary" @click="novo">
          <i class="fa fa-plus" /> Adicionar Receita
        </b-button>
      </div>

      <EmptyState v-if="receitas.length == 0">
        <h3>
          Nenhuma receita encontrada!
        </h3>
        <p>
          Clique em
          <b-button class="mx-1" variant="primary" @click="novo">
            <i class="fa fa-plus" /> Adicionar Receita
          </b-button>
          para solicitar.
        </p>
      </EmptyState>

      <div v-if="!loading" class="listaItens">
        <paciente-item-card
          @click.native.prevent="editar(receita)"
          :tipo="'Receita'"
          v-for="receita in receitas"
          :key="`receita_${receita.id}`"
          :objeto="receita"
          :btExcluir="true"
          @excluir="excluir"
        />
      </div>

      <b-modal id="modal-add-receita" size="lg" title="Solicitar Receita">
        <template #modal-footer="{cancel}">
          <b-btn variant="primary" @click="salvar">
            <i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn variant="secondary" @click="cancel">
            <i class="fas fa-ban"></i> Cancelar</b-btn
          >
        </template>
        <paciente-receita-tipo
          :horario_id="horario_id"
          :receita_edit="receita_edit"
          :medico_id="medico_id"
          :especialidade_id="especialidade_id"
          @salvou="onSalvou"
          :directSave="directSave"
          :paciente="paciente"
          ref="PacienteReceitaTipo"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import PacienteItemCard from "../PacienteItemCard.vue";
import PacienteReceitaTipo from "./PacienteReceitaTipo.vue";
//import moment from "moment";
import EmptyState from "../../../common/EmptyState.vue";
import PacienteReceitasLib from "../../../../libs/PacienteReceitasLib";
export default {
  name: "PacienteReceitas",
  components: { PacienteItemCard, PacienteReceitaTipo, EmptyState },
  props: {
    paciente: Object,
    horario_id: [Number, String],
    venda_id: [Number, String],
    local: { type: Array, default: null },
    directSave: {
      type: Boolean,
      default: true,
    },
    medico_id: [Number, String],
    especialidade_id: [Number, String],
  },

  created() {
    this.carregaReceitas();
  },
  data() {
    return {
      loading: false,
      loadingSecundario: false,
      receitas: [],
      receita_edit: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    novo() {
      this.receita_edit = {};
      this.$bvModal.show("modal-add-receita");
    },
    salvar() {
      this.$refs.PacienteReceitaTipo.salvar();
    },
    onSalvou(options = { id: null, close: true, cb: () => {}, data: null }) {
      console.log("onSalvou has been called", options);
      if (!this.directSave && options.data) {
        this.$bvModal.hide("modal-add-receita");
        const receita = Object.assign({}, options.data);

        if (!receita._idInterno) {
          receita._idInterno = this.receitas.length + 1;
          this.receitas.push(receita);
        } else {
          const i = this.receitas.findIndex(
            (r) => r._idInterno === receita._idInterno
          );
          if (i > -1) {
            this.$set(
              this.receitas,
              i,
              Object.assign(this.receitas[i], receita)
            );
          }
        }
        this.$emit("receita", this.receitas);
        return;
      }
      if (typeof options.close == "boolean" && options.close === true) {
        this.$bvModal.hide("modal-add-receita");
      }
      this.carregaReceitas(options.id, options.cb);
      this.$emit("salvou");
    },
    editar(receita) {
      this.receita_edit = receita;
      this.$bvModal.show("modal-add-receita");
    },
    async carregaReceitas(id = null, cb = null) {
      if (this.local) {
        this.receitas = Object.assign(this.receitas, this.local);
        return;
      }
      try {
        console.log("carregando...");
        this.receitas = await PacienteReceitasLib.get({
          paciente_id: this.paciente?.id,
          horario_id: this.horario_id,
          venda_id: this.venda_id,
        });
        if (id) {
          const mergeObj = this.receitas.find((rec) => rec.id === id);
          if (mergeObj) {
            cb(mergeObj);
          }
        }
      } catch (error) {
        console.log(error);
        // this.showToast("error", "Erro ao carregar pacientes!");
      }
    },
    excluir(id) {
      this.$swal({
        title: "Tem certeza?",
        text: "Ao excluir a receita não será possível recupera-la",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Excluir!",
        cancelButtonText: "Cancelar",
      }).then(async (x) => {
        if (x.isConfirmed) {
          try {
            let res = await PacienteReceitasLib.del(id);
            console.log("ok", res);
            if (res && res.data) {
              res = res.data;
              if (res.success) {
                this.$swal({
                  title: "Excluido com sucesso!",
                  icon: "success",
                  toast: true,
                  timer: 2000,
                  position: "top-end",
                  showConfirmButton: false,
                });
                this.local = null;
                this.carregaReceitas();
              } else {
                this.$swal(
                  "Atenção",
                  "Erro ao excluir! " + (res.message ? res.message : ""),
                  "error"
                );
                this.local = null;
                this.carregaReceitas();
              }
            }
          } catch (error) {
            console.log("ERR", error);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
