<template>
  <div>
    <div class=" my-2 p-2 border rounded" v-if="consultorio">
      <div class="d-flex justify-content-center mt-3 border rounded ">
        <b-form-radio-group
          :checked="valueConsultorio"
          @input="handleConsultorio"
          :disabled="isDisabled"
          class="d-flex justify-content-between"
          :options="[
            { text: 'Clinica', value: 0 },
            { text: 'Consultorio', value: 1 },
          ]"
          @change="
            () => {
              if (!!this.isConsultorio) handleInput(null);
            }
          "
        >
        </b-form-radio-group>
      </div>

      <div class="row justify-content-center" v-if="isConsultorio == 0">
        <div class="col-12 mt-3">
          <label> <i class="fas fa-clinic-medical"></i> {{ label }} </label>
          <v-select
            :options="clinicas"
            :reduce="(clinic) => clinic.id"
            label="nome"
            :value="value"
            @input="handleInput"
            @change="handleInput"
            :disabled="isDisabled"
            :multiple="multiple"
          >
            <template #search="{ attributes, events }">
              <input
                :class="['vs__search rounded']"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nenhum resultado para <em>{{ search }}</em
                >.
              </template>
              <em style="opacity: 0.5" v-else
                >Não encontramos nenhuma clinica 😕.</em
              >
            </template>
          </v-select>
        </div>
        <small class="text-muted" v-if="descriptionConsultorio != ''">
          {{ descriptionConsultorio }}
        </small>
      </div>
    </div>
    <b-form-group v-if="!consultorio">
      <label> <i class="fas fa-clinic-medical"></i> {{ label }} </label>
      <v-select
        :options="clinicas"
        :reduce="(clinic) => clinic.id"
        label="nome"
        :value="value"
        :multiple="multiple"
        @input="handleInput"
        :disabled="isDisabled"
        :class="{ vs__sm: size == 'sm' }"
      >
        <template #option="{nome}">
          <small class="font-sm">{{ nome }}</small>
        </template>
        <template #search="{ attributes, events }">
          <input
            :class="['vs__search rounded']"
            v-bind="attributes"
            v-on="events"
          />
        </template>
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            Nenhum resultado para <em>{{ search }}</em
            >.
          </template>
          <em style="opacity: 0.5" v-else
            >Não encontramos nenhuma clinica 😕.</em
          >
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<script>
import EmpresasLib from "@/libs/EmpresasLib";
export default {
  props: {
    value: [String, Number, Array],
    valueConsultorio: [String, Number],
    disabled: { type: Boolean, deafult: false },
    multiple: { type: Boolean, deafult: false },
    consultorio: { type: Boolean, deafult: false },
    descriptionConsultorio: {
      type: [String],
      deafult:
        "* Irá selecionar o primeiro consultório registrado no endereço do médico.",
    },
    size: [String],
    label: { type: [String], default: "Clinica" },
    withConsultorio: { type: Boolean, default: false },
    autoBlock: {
      type: Boolean,
      default: true,
    },
      clinicaInicial:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clinicas: [],
      isConsultorio: 0,
    };
  },
  methods: {
    async carregaClinicas() {
      this.$forceUpdate()
      await this.$nextTick()
      this.clinicas = [];
      if (this.withConsultorio) {
        this.clinicas.push({
          id: -1,
          nome: "Consultório Médico",
        });
      }
      this.clinicas = [
        ...this.clinicas,
        ...(await EmpresasLib.getEmpresasGrupo(null,false))?.map((emp) => {
          return { ...emp, nome: emp.fantasia + " (" + emp.sigla + ")" };
        }),
      ];

      if (!this.value && !this.isConsultorio && !this.user.isMedico && this.clinicaInicial) {
        const clinicaInicial =
          this.clinicas.find(
            (clinic) =>
              clinic.id ==
              (this.user.clinica_id
                ? this.user.clinica_id
                : this.user.empresa_id)
          ) || this.clinicas[0];
        if (clinicaInicial.id) this.handleInput(clinicaInicial.id);
      }
    },
    handleInput(clinica) {
      if (this.isConsultorio) {
        this.$emit("input", null);
        return;
      }
      this.$emit("input", clinica);
      console.log("send", clinica)
    },
    handleConsultorio(cons) {
      this.isConsultorio = cons;
      this.$emit("consultorio", cons);
    },
  },
  async mounted() {
    await this.$nextTick()
     this.$forceUpdate()
     this.carregaClinicas();
  },
  computed: {
    // user() {
    //   return this.$store.state.auth.user;
    // },
    isDisabled() {
      return this.autoBlock
        ? ["secretaria"].includes(this.user.perfil) || this.disabled
        : this.disabled;
    },
  },
};
</script>

<style></style>
