import axios from '@/utils/axios.js';

const get = async () => {
    let medicamentos = [];

    await axios.get('/medicamentos/get').then((result) => {
        
        medicamentos = result.data
    })

    return medicamentos.data;
}

const store = async (data) => {
    try {
        return (await axios.post('/medicamentos/store', data)).data;
    } catch (error) {
        console.log(error)
    }

}

const del = async (data) => {
    return (await axios.post('/medicamentos/delete', data)).data;
}
export default {
    get, store, del
}