import axios from "../utils/axios";

const get = async () => {
  let result;
  await axios
    .get("fidelizacoes")
    .then((res) => {
      result = res.data;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao buscar fidelizações!");
      }
    });

  return result;
};

const store = async (data) => {
  let result = await axios
    .post("fidelizacoes", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao inserir fidelizações!");
      }
    });
  return result;
};

const del = async (id) => {
  let result = await axios
    .delete(`fidelizacoes/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data.error;
      } else {
        throw new Error("Erro ao excluir fidelizações!");
      }
    });
  return result;
};

const aplicaDescontoById = async ({ id = "", valor = "", tipo = "" }) => {
  let descontos;
  await axios
    .get("fidelizacoes", { params: { id } })
    .then((res) => {
      descontos = res.data[0].desconto;
    })
    .catch((err) => {
      if (err.response.data) {
        throw err.response.data;
      } else {
        throw new Error("Erro ao buscar fidelizações!");
      }
    });

  for (const desconto of descontos) {
    // //console.log(
    //   "o desconto: ",
    //   desconto,
    //   "o valor",
    //   parseFloat(valor ? valor : "0")
    // );
    let valorD;
    if (desconto.tipo_venda == tipo) {
      if (desconto.desconto_tipo == "P") {
        valorD =
          parseFloat(valor ? valor : "0") -
          parseFloat(valor ? valor : "0") * (desconto.desconto_valor / 100);
      }

      if (desconto.desconto_tipo == "V") {
        valorD = parseFloat(valor ? valor : "0") - desconto.desconto_valor;
      }
    }
    return valorD;
  }
};

const aplicaDescontoByObj = ({ fidelizacao, valor = "", tipoProduto = "" }) => {
  let valorD = {
    valorOld: valor,
    valor: 0,
    desconto: "",
    descontoAplicado: 0,
  };
  for (const desconto of fidelizacao.desconto) {
    // //console.log("fidelizacao", fidelizacao);
    if (desconto.tipo_venda == tipoProduto) {
      if (desconto.desconto_tipo == "P") {
        valorD.valor =
          parseFloat(valorD.valorOld ? valorD.valorOld : "0") -
          parseFloat(valorD.valorOld ? valorD.valorOld : "0") *
            (desconto.desconto_valor / 100);

        valorD.desconto = `%${desconto.desconto_valor}`;
        valorD.descontoAplicado = `R$ ${((valorD.valorOld
          ? parseFloat(valorD.valorOld)
          : 0) - (valorD.valor ? parseFloat(valorD.valor) : 0)).toFixed(2)}`;
      }

      if (desconto.desconto_tipo == "V") {
        valorD.valor =
          parseFloat(valorD.valorOld ? valorD.valorOld : "0") -
          desconto.desconto_valor;
        valorD.desconto = `R$ ${desconto.desconto_valor}`;
        valorD.descontoAplicado = `R$ ${((valorD.valorOld
          ? parseFloat(valorD.valorOld)
          : 0) - (valorD.valor ? parseFloat(valorD.valor) : 0)).toFixed(2)}`;
      }
    }
  }

  return valorD;
};

const getFidelizacoesPaciente = async (entidade_id) => {
  try {
    //console.log('buscando fidelsz', entidade_id)
    return (await axios.get(`fidelizacoes/paciente/${entidade_id}`)).data;
  } catch (error) {
    //console.log("Erro ao buscar fidelizações do paciente", error);
  }
};

const getHistoricoRenovacoes = async (fidelidade_id) => {
  try {
    return (await axios.get(`fidelizacoes/historico/${fidelidade_id}`)).data;
  } catch (error) {
    //console.log("Erro ao buscar histórico do paciente", error);
  }
}

const getFidelizacaoByID = async (entidade_fidelizacao_id) => {
  try {
    return (await axios.get(`fidelizacoes/getByID/${entidade_fidelizacao_id}`))
      .data;
  } catch (error) {
    //console.log("Erro ao buscar fidelizações do paciente", error);
  }
};
const resync = async (codigo) => {
  try {
    if(!codigo)throw new Error('Código da fidelização é obrigatório!')
    return (await axios.get(`fidelizacoes/resync/${codigo}`))
  } catch (error) {
    //console.log("Erro ao forçar sincronização fidelizações do paciente", error);
  }
};

export default {
  get,
  getFidelizacoesPaciente,
  store,
  del,
  aplicaDescontoById,
  aplicaDescontoByObj,
  getFidelizacaoByID,
  resync,
  getHistoricoRenovacoes
};
