<template>
  <div>
    <div class="row" v-if="senderObj">
      <div class="col-12 col-sm-4">
        <label>Tipo de Envio</label>
        <b-input disabled v-model="senderObj.type"></b-input>
        <b-btn variant="primary" @click="openConfigs">
          <i class="fa fa-cogs"></i> Configuração
        </b-btn>
      </div>
      <div class="col-12 col-sm-4">
        <label>Mensagem</label>
        <b-form-textarea
          v-model="senderObj.message"
          style="min-width:300px"
          disabled
        ></b-form-textarea>
      </div>
      <div class="col-12 col-sm-4">
        <label>Opções de Resposta</label>
        <table class="table">
          <thead>
            <tr>
              <td>Opção</td>
              <td>Resposta</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ans in senderObj.answers" :key="`answers_${ans.numero}`">
              <td>{{ ans.numero }}</td>
              <td>{{ ans.resposta }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <hr />
        <div class="row">
          <div class="col-12 col-sm-6">
            <h4>Lista de Contatos (0 total)</h4>
          </div>
          <div class="col-12 col-sm-6 text-right">
            <b-btn variant="primary"
              ><i class="fa fa-check mr-2"></i>Verificar Disponibilidade</b-btn
            >
            <b-btn variant="success"
              ><i class="fa fa-paper-plane mr-2"></i>Disparar
              Selecionados</b-btn
            >
          </div>
        </div>
        <b-table
          :items="senderObj.list"
          :fields="[
            { key: 'sel', label: '' },
            { key: 'pic', label: '' },
            { key: 'name', label: 'Nome' },
            { key: 'number', label: 'Telefone' },
            { key: 'hasWhats', label: 'Whatsapp?' },
            { key: 'wid', label: 'Enviado' },
            { key: 'widR', label: 'Respondido' },
          ]"
          hover
          select-mode="single"
          selectable
          @row-selected="selectedROW"
          ref="tbListContact"
        >
          <template #head(sel)>
            <b-checkbox v-model="selAll"></b-checkbox>
          </template>
          <template #cell(sel)="row">
            <b-checkbox v-model="row.item.sel" :value="1"></b-checkbox>
          </template>
          <template #cell(number)="row">
            {{ row.item.number }}
            {{ row.item.number2 ? " | " + row.item.number2 : "" }}
          </template>
          <template #cell(hasWhats)="row">
            <b-icon-check v-if="row.item.hasWhats == 1"></b-icon-check>
            <b-icon-question v-if="row.item.hasWhats == 0"></b-icon-question>
          </template>
        </b-table>
        <hr />
        <div class="bg-light p-4">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="card card-body text-center">
                <b>Total de Contatos</b>
                <h2>
                  {{ totalContacts }}
                </h2>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="card card-body text-center">
                <b>Contatos possui Whatsapp</b>
                <h2>{{ totalContactsHasWhats }}</h2>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="card card-body text-center">
                <b>Enviados</b>
                <h2>{{ totalContactsSended }}</h2>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="card card-body text-center">
                <b>Respondidos</b>
                <h2>{{ totalContactsAnswer }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="xl"
      id="modal-whats-config"
      hide-footer
      title="Configuração do Whatsapp"
    >
      <whatsapp-configuration></whatsapp-configuration>
    </b-modal>
  </div>
</template>

<script>
import { buscarWhatsappConfigs } from "../../libs/WhatsappLib";
import { buscarWhatsappSender } from "../../libs/WhatsappLib";
import WhatsappConfiguration from "./WhatsappConfiguration.vue";
export default {
  components: { WhatsappConfiguration },
  props: {
    listOfContacts: Array,
    typeOfSend: String,
    referenceId: Number,
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      senderObj: null,
      senderList: null,
      config: null,
      selAll: false,
    };
  },
  watch: {
    selAll: function() {
      this.senderObj.list.forEach((it) => {
        it.sel = this.selAll ? 1 : 0;
      });
    },
  },
  computed: {
    totalContacts() {
      return this.senderObj.list.length;
    },
    totalContactsHasWhats() {
      return this.senderObj.list.filter((x) => x.hasWhats == 1).length;
    },
    totalContactsSended() {
      return this.senderObj.list.filter((x) => x.send_date).length;
    },
    totalContactsAnswer() {
      return this.senderObj.list.filter((x) => x.anser_date).length;
    },
  },
  methods: {
    async fillConfig(type) {
      let configs = await buscarWhatsappConfigs();
      if (configs && configs.success && configs.data) {
        return configs.data.find((x) => x.type == type);
      }
    },
    async load() {
      let res = await buscarWhatsappSender(this.referenceId, this.typeOfSend);
      if (res && res.success) {
        this.senderObj = res.data[0];
        this.senderObj.config = await this.fillConfig(this.senderObj.type);
      } else {
        console.log("a", this.listOfContacts);
        let config = await this.fillConfig(this.typeOfSend);
        if (!config) {
          this.$swal("Atenção", "Nenhuma configuração cadastrada! Favor cadastrar antes de continuar!", "error");
          this.openConfigs();
        }
        //if (config) {
        this.senderObj = {
          type: this.typeOfSend,
          config,
          message: config?config.text:'',
          answers: config?config.answers:'',
          list: this.listOfContacts.map((it) => ({
            sel: 0,
            entidade_id: it.entidade_id,
            name: it.name,
            number: it.number,
            number2: it.number2,
            hasWhats: 0,
            status: "pending",
            wid: "",
          })),
        };
        //}
      }
    },
    selectedROW(it) {
      if (it && it.length > 0) {
        let has = this.senderObj.list.find(
          (x) => x.name == it[0].name && x.number == it[0].number
        );
        if (has) {
          has.sel = has.sel == 0 ? 1 : 0;
        }
      }
      this.$refs.tbListContact.clearSelected();
    },
    openConfigs() {
      this.$bvModal.show("modal-whats-config");
    },
  },
};
</script>

<style lang="scss" scoped></style>
