
const buscarCEP = async(cep)=>{
    const axios = require('axios');
    cep = cep.replace(/\D/g, "");
    try {
        const result = await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
    
        if (result && result.data) {
            return result.data;
        } else {
            console.log("ERRO", result)
            return null;
        }
    } catch (err) {
        console.log("ERRON NONONO", err);
        try {
            const axios2 = require('axios');            
            const result = await axios2.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (result && result.data) {
                if (result.data.cep) {
                    return {
                        cep: result.data.cep,
                        state: result.data.uf,
                        city: result.data.localidade,
                        neighborhood: result.data.bairro,
                        street: result.data.logradouro
                    }
                }
            }
        } catch (err) {
            return null
        }
        return null
    }
}

const buscarUFs = async()=>{
    const axios = require('axios');
    const result = await axios.get(`https://brasilapi.com.br/api/ibge/uf/v1`);
    if(result && result.data){
        return result.data;
    }else{
        return null;
    }
}


module.exports = {
    buscarCEP,
    buscarUFs
}