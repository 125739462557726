var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.printNow)?_c('b-modal',{attrs:{"id":"modal-print-gruia","no-enforce-focus":"","no-fade":"","hide-backdrop":"","hide-footer":"","size":"lg"},on:{"hide":function($event){_vm.printNow = false;
      _vm.$emit('close');}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.print}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1),_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.download}},[_c('b-icon-download'),_vm._v(" Baixar")],1),_c('b-btn',{attrs:{"variant":"none"},on:{"click":_vm.sendWpp}},[_c('b-icon-whatsapp'),_vm._v(" Enviar via Whatsapp")],1)]},proxy:true}],null,false,333360855)},[_c('div',{attrs:{"id":"printDiv"}},[_c('print-component',{staticStyle:{"max-width":"500px"},attrs:{"filename":`Guia `,"printNow":_vm.printNow,"titulo":`CONTRATO DE FIDELIZAÇÃO`,"show":true},on:{"done":function($event){return _vm.$emit('done')}}},[(_vm.item && _vm.item.contrato && _vm.item.contrato.content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.contrato.content)}}):_vm._e()])],1)]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-wpp","hide-footer":"","title":"Enviar guia para whatsapp"}},[_c('b-form-group',{attrs:{"label":"Número"}},[_c('vue-simple-suggest',{ref:"select-num-wpp",attrs:{"suggestion-click":true,"list":_vm.telefonesDisponiveis,"filter-by-query":true,"mode":"select","controls":{
          selectionUp: [38, 33],
          selectionDown: [40, 34],
          select: [13, 36],
          showList: [40],
          hideList: [27, 35, 13],
        }},model:{value:(_vm.wppNumber),callback:function ($$v) {_vm.wppNumber=$$v},expression:"wppNumber"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wppNumber),expression:"wppNumber"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],staticClass:"form-control optional-custom-input",attrs:{"autocomplete":"off","id":`txtField${Math.random()}`,"name":`txtField${Math.random()}`},domProps:{"value":(_vm.wppNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.wppNumber=$event.target.value}}})]),(_vm.wppAlert.show)?_c('b-alert',{attrs:{"variant":_vm.wppAlert.type}},[_vm._v(" "+_vm._s(_vm.wppAlert.message)+" ")]):_vm._e(),_c('botoes-salvar-cancelar',{attrs:{"okText":'Enviar',"okIcon":'fab fa-whatsapp',"saving":_vm.enviandoWpp},on:{"salvar":_vm.enviarWpp,"cancelar":() => _vm.$bvModal.hide('modal-wpp')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }