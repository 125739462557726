var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemCard"},[_c('div',{staticClass:"num"},[_vm._v("."+_vm._s(_vm.num))]),_c('div',{staticClass:"titulo"},[_vm._v(" "+_vm._s(_vm.item.exameSelecionado ? _vm.item.exameSelecionado.descricao : "")+" "),(_vm.item.exameSelecionado && _vm.item.exameSelecionado.fidelizcao)?_c('span',[_vm._v(" "+_vm._s(_vm.item.exameSelecionado.fidelizcao.nome)+" ")]):_vm._e(),_c('small',[_c('small',[_vm._v(" "+_vm._s(_vm.item.tipoVenda)+" "+_vm._s(_vm.item.entidadeSelecionada ? ` - ${_vm.item.entidadeSelecionada.razao_social}` : "")+" ")]),(_vm.item.dependenteSelecionado)?_c('small',[_c('br'),_c('b',[_vm._v("Paciente: ")]),_vm._v(" "+_vm._s(_vm.item.dependenteSelecionado.Nome || _vm.item.dependenteSelecionado.razao_social)+" "),_c('br')]):_vm._e(),(_vm.item.solicitacaoExame)?_c('small',[_c('b',[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.item.solicitacaoExame.data,"DD/MM/YYYY"))),_c('br'),_c('b',[_vm._v("Médico Solicitante:")]),_vm._v(" "+_vm._s(_vm.item.solicitacaoExame.medicoNome)),_c('br'),_c('b-table',{attrs:{"small":"","size":"sm","fields":[
            { key: 'nome', label: 'Exame' },
            { key: 'valor_venda', label: 'Valor', class: 'text-right' },
          ],"items":_vm.item.solicitacaoExame.exames},scopedSlots:_vm._u([{key:"cell(valor_venda)",fn:function(row){return [_c('div',{staticClass:"text-right",style:(row.item.valor_desconto?'text-decoration: line-through; color:red;font-style: italic; ':'')},[_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(row.item.valor_original || row.item.valor_venda))+" ")]),(row.item.valor_desconto)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(row.item.valor_venda))+" ")]):_vm._e()]}}],null,false,1071345610)})],1):_vm._e()])]),_c('div',{staticClass:"valor"},[(
        _vm.item.valor_desconto ||
          (typeof _vm.item.desconto != 'undefined' &&
            _vm.item.desconto &&
            _vm.item.desconto != 'R$ undefined')
      )?_c('div',{class:'desconto ' + (_vm.item.valor_desconto * -1 > 0 ? 'text-success' : '')},[_c('span',{staticClass:"lin"},[_vm._v(_vm._s(_vm._f("currencyWithMask")(_vm.item.valor_original)))]),_c('br'),_vm._v(" "+_vm._s(_vm.item.valor_desconto * -1 > 0 ? "+" : "")+" "+_vm._s(_vm._f("currencyWithMask")((_vm.item.valor_desconto * -1)))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("currencyWithMask")(_vm.item.valor_venda))+" ")]),_c('div',{staticClass:"options"},[_c('a',{staticClass:"text-warning mr-4",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editar.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Editar")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remover.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Remover")])]),(
      _vm.item.exameSelecionado && _vm.item.exameSelecionado.personalizar_por_forma
    )?_c('span',{staticClass:"text-muted"},[_vm._v(" * Esse item tem "),_c('b',[_vm._v("desconto/acrescimo por forma de pagamento")]),_c('b-btn',{staticClass:"mx-1",attrs:{"size":"sm"},on:{"click":_vm.openDescontosPersonalizados}},[_vm._v(" Clique Aqui")]),_vm._v(" para visualiza-los ")],1):_vm._e(),(
      _vm.item.descontoPorForma &&
        _vm.item.descontoPorForma.aplicado &&
        !_vm.item.descontoPorForma.desconto.acumulativo &&
        _vm.hasFidel
    )?_c('span',{staticClass:"text-muted"},[_vm._v(" * A fidelização não foi aplicada pois já há um outro desconto aplicado ")]):_vm._e(),(_vm.item.descontoPorForma && _vm.item.descontoPorForma.aplicado)?_c('span',{staticClass:"text-muted"},[_vm._v(" * Desconto/acrescimo de "),_c('b',{staticClass:"text-success"},[_vm._v(" "+_vm._s(`R$ ${_vm.item.descontoPorForma.desconto.desconto * -1}`)+" ")]),_vm._v(" aplicado por conta da forma de pagamento ser "),_c('b',[_vm._v(" "+_vm._s(`${_vm.item.descontoPorForma.desconto.formaDePagamento}`)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }