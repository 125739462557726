import axios from "@/utils/axios.js";

const uploadFile = async (file) => {
  let result;
  let form = new FormData();
  form.append("file", file);
  // console.log(form    )
  await axios
    .post("/uploadFile", form, {
      header: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((data) => {
      // console.log('data no upload', data)
      if (data && data.data.success) {
        result = data;
      }
    })
    .catch((err) => {
      console.log("Erro ao enviar arquivo ", err);
      result = false;
    });

  return result;
};

const downlaodFile = (file, token) => {
  //await axios.get(`${axios.defaults.baseURL}downloadFile/${file}?token=${token}`).then(res=>console.log(res))
  if(typeof file ==='string' && file.trim().length>0 && token)return `${axios.defaults.baseURL}downloadFile/${file}?token=${token}`;
};

export default {
  uploadFile,
  downlaodFile,
};
