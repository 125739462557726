import axios from "@/utils/axios.js";

const get = async (id_agenda, paciente, dataAgenda, id_paciente) => {
  let horarios = [];
  // console.log('usou a horarioslib', id_agenda)
  await axios
    .get(`/horarios/get`, {
      params: { id_agenda, paciente, dataAgenda, id_paciente },
    })
    .then((result) => {
     // console.log(result);
      horarios = result.data;
    })
    .catch((err) => {
      console.log("erro ao buscar as horarios", err);
    });

  return horarios;
};
const store = async (data) => {
  // console.log(data)
  return await axios.post("/horarios/store", data);
};
const del = async (data) => {
  return (await axios.post("/horarios/delete", data)).data;
};

const vincularVenda = async (horario_id, venda_id, venda_item_id) => {
  try {
    return await axios.post("/horarios/vincularVenda", {
      horario_id,
      venda_id,
      venda_item_id,
    });
  } catch (error) {
    throw error?.response?.data
  }
};
//ecma script
export default {
  get,
  store,
  del,
  vincularVenda
};
