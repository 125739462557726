var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('barra-crud',{attrs:{"title":"Configurações do Whatsapp","botaoNovo":true,"onNovo":() => {
        _vm.openForm(null);
      }}}),_c('hr'),_c('b-table',{attrs:{"items":_vm.list,"fields":[
      { key: 'id', label: 'Código' },
      { key: 'name', label: 'Nome' },
      { key: 'type', label: 'Tipo' },
      { key: 'usuario', label: 'Usuário' },
      { key: 'opc', label: '' },
    ]},scopedSlots:_vm._u([{key:"cell(opc)",fn:function(row){return [_c('b-btn',{attrs:{"variant":"warning"}},[_c('i',{staticClass:"fa fa-edit",on:{"click":function($event){return _vm.openForm(row.item)}}}),_vm._v(" Editar ")]),_c('b-btn',{attrs:{"variant":"danger"}},[_c('i',{staticClass:"fa fa-trash",on:{"click":function($event){return _vm.edit(row.item)}}}),_vm._v(" Excluir ")])]}}])}),_c('b-modal',{attrs:{"id":"modal-add-edit-config-wpp","hide-footer":"","size":"lg"}},[(_vm.objEdit)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4"},[_c('label',[_vm._v("Código")]),_c('b-input',{attrs:{"disabled":""},model:{value:(_vm.objEdit.id),callback:function ($$v) {_vm.$set(_vm.objEdit, "id", $$v)},expression:"objEdit.id"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('label',[_vm._v("Nome")]),_c('b-input',{model:{value:(_vm.objEdit.name),callback:function ($$v) {_vm.$set(_vm.objEdit, "name", $$v)},expression:"objEdit.name"}})],1),_c('div',{staticClass:"col-12 col-sm-6"},[_c('label',[_vm._v("Tipo")]),_c('b-select',{attrs:{"options":['Agenda', 'Telemarketing']},model:{value:(_vm.objEdit.type),callback:function ($$v) {_vm.$set(_vm.objEdit, "type", $$v)},expression:"objEdit.type"}})],1),_c('div',{staticClass:"col-12 col-sm-12"},[_c('label',[_vm._v("Mensagem")]),_c('b-textarea',{model:{value:(_vm.objEdit.text),callback:function ($$v) {_vm.$set(_vm.objEdit, "text", $$v)},expression:"objEdit.text"}})],1),_c('div',{staticClass:"col-12 col-sm-12"},[_c('label',[_vm._v("Respostas")]),_c('b-table',{attrs:{"items":_vm.objEdit.answers,"fields":[
              { key: 'numero', label: 'Número' },
              { key: 'resposta', label: 'Resposta' },
              { key: 'opc', label: '' },
            ]},scopedSlots:_vm._u([{key:"cell(numero)",fn:function(row){return [_c('b-input',{attrs:{"type":"number"},model:{value:(row.item.numero),callback:function ($$v) {_vm.$set(row.item, "numero", $$v)},expression:"row.item.numero"}})]}},{key:"cell(resposta)",fn:function(row){return [_c('b-input',{attrs:{"type":"text"},model:{value:(row.item.resposta),callback:function ($$v) {_vm.$set(row.item, "resposta", $$v)},expression:"row.item.resposta"}})]}},{key:"cell(opc)",fn:function(row){return [_c('b-btn',{attrs:{"variant":"none"},on:{"click":function($event){return _vm.delAnswer(row.item)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}],null,false,2145070951)}),_c('div',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.addAnswer}},[_c('i',{staticClass:"fa fa-plus mr-1"}),_vm._v(" Adicionar Resposta ")])],1)],1)]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('i',{staticClass:"fa fa-save mr-1"}),_vm._v("Salvar")]),_c('b-btn',{on:{"click":function($event){return _vm.$bvModal.hide('modal-add-edit-config-wpp')}}},[_vm._v("Cancelar")])],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }