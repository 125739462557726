<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-5">
        <i class="fa fa-stethoscope ml-0" /> {{ agendaInfo.especialidadeNome }}
        <h4><i class="fa fa-user-md" /> {{ agendaInfo.EntidadeNome }}</h4>
      </div>
      <div class="col-12 col-sm-3">
        <b-icon-calendar /> {{ agendaInfo.data | moment("DD/MM/YYYY") }}<br />
        <b-icon-clock /> {{ agendaInfo.hora_inicio }}<br />
        <b-icon-shop /> {{ agendaInfo.clinic }}
      </div>
      <div class="col-12 col-sm-4 text-center">
        <div v-if="user.isMedico">
          <b-button
            v-if="!agendaInfo.at_inicio"
            @click="iniciarAtendimentoAgenda"
            variant="success"
            size="lg"
            ><b-icon-play-fill /> Iniciar Atendimento</b-button
          >
        </div>
        <div v-if="agendaInfo.at_inicio" class="text-left">
          <i class="fa fa-play" />
          {{ agendaInfo.at_inicio | moment("HH:mm:ss") }}
          <br />
          <i class="fa fa-business-time" /> {{ tempo }}
        </div>
        <b-button
          v-if="agendaInfo.at_inicio && !agendaInfo.at_termino"
          @click="finalizarAtendimentoAgenda"
          variant="danger"
          size="lg"
          ><b-icon-stop-fill /> Finalizar Atendimento</b-button
        >
        <div v-if="!agendaInfo.at_inicio">
          <i class="fa fa-times" /> Atendimento não iniciado pelo médico ainda.
        </div>
      </div>
    </div>

    <b-tabs fill v-model="filtro" class="invert mt-5">
      <b-tab v-if="!user.isMedico" title="">
        <template #title>
          <img
            src="@/assets/img/icones/menu/icons8_user_clock_40px.png"
            height="40"
          />

          Confirmados
        </template>
        <div class="row listaContainer">
          <div
            class="col-12 text-center p-5"
            v-if="!pendentes || pendentes.length <= 0"
          >
            Nenhum paciente aqui :(
          </div>
          <div class="col-12 lista">
            <atendimento-card
              :item="item"
              v-for="item in pendentes"
              :key="item.id"
              @click.native="openPaciente(item)"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Aguardando Atendimento">
        <template #title>
          <img src="@/assets/img/icones/menu/icons8_waiting_room_40px.png" />
          Aguardando Atendimento
        </template>

        <div class="row listaContainer">
          <div
            class="col-12 text-center p-5"
            v-if="!espera || espera.length <= 0"
          >
            Nenhum paciente aqui :(
          </div>
          <div class="col-12 lista">
            <atendimento-card
              :item="item"
              v-for="item in espera"
              :key="item.id"
              @click.native="openPaciente(item)"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Em Atendimento">
        <template #title>
          <img
            src="@/assets/img/icones/menu/icons8_physical_therapy_40px.png"
          />
          Em Atendimento
        </template>
        <div class="row listaContainer">
          <div
            class="col-12 text-center p-5"
            v-if="!emAtendimento || emAtendimento.length <= 0"
          >
            Nenhum paciente aqui :(
          </div>
          <div class="col-12 lista">
            <atendimento-card
              :item="item"
              v-for="item in emAtendimento"
              :key="item.id"
              @click.native="openPaciente(item)"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Finalizados">
        <template #title>
          <img src="@/assets/img/icones/menu/icons8_todo_list_40px.png" />
          {{ user.isMedico ? "Concluído" : "Finalizados" }}
        </template>
        <div class="row listaContainer">
          <div
            class="col-12 text-center p-5"
            v-if="!atendimentoConcluido || atendimentoConcluido.length <= 0"
          >
            Nenhum paciente aqui :(
          </div>
          <div class="col-12 lista">
            <atendimento-card
              :item="item"
              v-for="item in atendimentoConcluido"
              :key="item.id"
              @click.native="openPaciente(item)"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Concluído" v-if="!user.isMedico">
        <template #title>
          <img src="@/assets/img/icones/menu/icons8_checked_40px.png" />
          Concluído
        </template>
        <div class="row listaContainer">
          <div
            class="col-12 text-center p-5"
            v-if="!finalizados || finalizados.length <= 0"
          >
            Nenhum paciente aqui :(
          </div>
          <div class="col-12 lista">
            <atendimento-card
              :item="item"
              v-for="item in finalizados"
              :key="item.id"
              @click.native="openPaciente(item)"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Resumo do Atendimento">
        <template #title>
          <img src="@/assets/img/icones/menu/activity_feed_40px.png" />
          Resumo do Atendimento
        </template>
        <div class="row listaContainer">
          <b-table
            class="shadow-sm"
            small
            style="font-size: 15px;"
            :fields="camposResumo"
            :items="resumoAtendimento"
            show-empty
          >
            <template #empty>
              <div class="text-center text-muted d-flex flex-column p-2">
                <div class="justify-content-center py-2 ">
                  <p class="mt-2">
                    Nada aqui...
                  </p>
                </div>
              </div>
            </template>
            <template #cell(at_ordem)="{item}">
              <p class="text-muted ">
                <b class="">{{ item.at_ordem }}°</b>
              </p>
            </template>
            <template #cell(valor)="{item}">
              {{ item.valor | currencyWithMask }}
            </template>
          </b-table>
        </div>
      </b-tab>
    </b-tabs>

    <div class="row mt-2 mb-2" v-if="!user.isMedico">
      <div class="col-12">
        <b-button variant="primary" @click="abrirAgenda"
          ><b-icon-book-half /> Abrir Agenda</b-button
        >
      </div>
    </div>

    <b-modal
      id="modal-paciente"
      hide-footer
      size="lg"
      title="Atendimento"
      :modal-class="modalClass"
      :body-class="bodyClass"
      hide-header
      @hide="closeModal"
    >
      <atendimento-paciente
        :horario="horarioSelecionado"
        :horarios="lista"
        @updateHorario="updateHorario"
        @iniciarAtedimento="iniciarAtedimento"
        @finalizarAtedimento="finalizarAtedimento"
        @close="closeModal"
        @venda="onVenda($event)"
        @vinculou="onVinculou($event)"
        @voltarHorario="voltarHorario($event)"
        @concluirAtendimento="concluirAtendimento($event)"
        :agenda="agendaInfo"
      />
    </b-modal>
    <b-modal
      id="modal-show-agenda"
      hide-footer
      modal-class="modalMaior"
      @hide="joinRoom"
    >
      <agenda-horarios :idAgenda="agendaId" />
    </b-modal>
  </div>
</template>

<script>
// import BarraCrud from "../../../components/common/BarraCrud.vue";
// import Agendas from '../Agendas.vue';
import moment from "moment";
import AtendimentoCard from "./AtendimentoCard.vue";
import AtendimentoPaciente from "./AtendimentoPaciente.vue";
import AgendaHorarios from "../../../components/Agendas/AgendaHorarios.vue";

export default {
  components: {
    AtendimentoCard,
    AtendimentoPaciente,
    AgendaHorarios,

    // BarraCrud,
    // Agendas,
  },
  sockets: {
    connect() {
      this.joinRoom();
    },
    solicitarLista() {
      // console.log("solicitar");
      this.solicitarLista();
    },
    agenda(agenda) {
      this.$emit("changeAgenda", agenda);
    },
    lista(lista) {
      this.lista = lista.sort((a, b) => {
        return a.at_ordem && b.at_ordem && a.at_ordem > b.at_ordem ? 1 : -1;
      });
      console.log(this.lista);
      if (this.horarioSelecionado?.id) {
        let mergeHorario = this.lista.find(
          (h) => h.id == this.horarioSelecionado.id
        );
        //console.log('mergeHorario ---> ', mergeHorario);
        if (mergeHorario)
          this.horarioSelecionado = Object.assign(
            this.horarioSelecionado,
            mergeHorario
          );
      }
    },
  },
  props: {
    agendaId: Number,
    agendaInfo: Object,
  },
  mounted() {
    this.joinRoom();
    this.timer = setInterval(this.calculaTempo, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  data() {
    return {
      atendimento: null,
      camposResumo: [
        {
          key: "at_ordem",
          label: "Ordem do Atendimento",
          class: "text-center align-middle",
          sortable: true,
        },
        { key: "paciente", label: "Paciente", class: "align-middle" },
        {
          key: "receitas.quantidade",
          label: "Receitas",
          class: "text-center align-middle",
        },
        {
          key: "solicitacoesExame.quantidade",
          label: "Solicitação de Exames",
          class: "text-center",
        },
        {
          key: "encaminhamentos.quantidade",
          label: "Encaminhamento Médico",
          class: "text-center",
        },
        { key: "at_status", label: "Status", class: "text-center" },
        { key: "valor", label: "Valor", class: "text-center" },
        // { key: "actions", label: "" },
      ],
      tempo: "00:00",
      timer: null,
      lista: [],
      filtro: 0,
      horarioSelecionado: null,
      user: this.$store.state.auth.user,
    };
  },
  watch: {
    agendaId: function() {
      this.joinRoom();
    },
    agendaInfo: function() {
      this.calculaTempo();
    },
    horarioSelecionado: {
      deep: true,
      handler(value) {
        let status = value?.at_status;
        if (this.user.isMedico) {
          if (status === "FILA DE ESPERA") {
            this.filtro = 0;
          } else if (status === "EM ATENDIMENTO") {
            this.filtro = 1;
          } else if (status === "ATENDIMENTO CONCLUIDO") {
            this.filtro = 2;
          }
        } else {
          if (status === "PENDENTE") {
            this.filtro = 0;
          } else if (status === "FILA DE ESPERA") {
            this.filtro = 1;
          } else if (status === "EM ATENDIMENTO") {
            this.filtro = 2;
          } else if (status === "ATENDIMENTO CONCLUIDO") {
            this.filtro = 3;
          }
        }
      },
    },
  },
  computed: {
    pendentes: function() {
      //let filtro = this.filtro;
      // console.log(filtro, this.lista);
      return this.lista.filter(
        (x) => (x.confirmou === 1 && !x.at_status) || x.at_status === "PENDENTE"
      );
    },
    espera: function() {
      // let filtro = this.filtro;
      // console.log(filtro, this.lista);
      return this.lista.filter((x) => x.at_status === "FILA DE ESPERA");
    },
    emAtendimento: function() {
      //  let filtro = this.filtro;
      //   filtro;
      //console.log(filtro, this.lista);
      return this.lista.filter((x) => x.at_status === "EM ATENDIMENTO");
    },
    atendimentoConcluido: function() {
      // Status é um array, para que os médicos consigam ver não só os atendimentos concluídos, mas também os Finalizados.
      let status = ["ATENDIMENTO CONCLUIDO"];
      if (this.user.isMedico) {
        status.push("FINALIZADO");
      }
      return this.lista.filter((x) => status.includes(x.at_status));
    },
    finalizados: function() {
      return this.lista.filter((x) => x.at_status === "FINALIZADO");
    },
    listaFiltrada() {
      return [];
    },
    resumoAtendimento() {
      let statusNormalize = {
        "ATENDIMENTO CONCLUIDO": this.user.isMedico
          ? { nome: "CONCLUÍDO", cor: "success" }
          : { nome: "FINALIZADOS", cor: "warning" },
        FINALIZADO: { nome: "CONCLUÍDO", cor: "success" },
        "EM ATENDIMENTO": { nome: "EM ATENDIMENTO", cor: "info" },
        "FILA DE ESPERA": { nome: "AGUARDANDO ATENDIMENTO", cor: "danger" },
        PENDENTE: { nome: "CONFIRMADO", cor: "" },
      };

      let listaFiltrada = this.lista.filter((h) =>
        this.user?.isMedico && ["PENDENTE"].includes(h.at_status)
          ? false
          : true && h.resumoAtendimento
      );
      return listaFiltrada.map((h) => {
        return {
          ...h.resumoAtendimento,
          at_ordem: h.at_ordem,
          at_status: statusNormalize[h.at_status]?.nome || "",
          _rowVariant: statusNormalize[h.at_status]?.cor || "",
        };
      });
    },
    modalClass() {
      return this.horarioSelecionado &&
        this.horarioSelecionado?.compareceu == 1 &&
        this.horarioSelecionado?.id_paciente > 0 &&
        this.user.isMedico
        ? "modalMaiorFull"
        : "";
    },
    bodyClass() {
      return this.horarioSelecionado &&
        this.horarioSelecionado?.compareceu == 1 &&
        this.horarioSelecionado?.id_paciente > 0 &&
        this.user.isMedico
        ? "modalNoPadding"
        : "";
    },
  },
  methods: {
    abrirAgenda() {
      this.$bvModal.show("modal-show-agenda");
    },
    async iniciarAtendimentoAgenda() {
      if (!this.agendaInfo || !this.agendaInfo?.id || this.agendaInfo.at_inicio)
        return;
      await this.$swal({
        title: "Iniciar Atedimento",
        html: `Tem certeza que deseja iniciar o atendimento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonText: "Não, cancelar.",
        confirmButtonText: '<i class="fas fa-play"></i> Sim, iniciar.',
      }).then((re) => {
        if (re.isConfirmed) {
          this.$socket.emit(
            "atendimento:iniciarAtendimentoAgenda",
            this.agendaInfo.id
          );
        }
      });
    },
    async finalizarAtendimentoAgenda() {
      if (
        !this.agendaInfo ||
        !this.agendaInfo?.id ||
        !this.agendaInfo.at_inicio
      )
        return;
      await this.$swal({
        title: "Finalizar Atedimento",
        html: `Tem certeza que deseja finalizar o atendimento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonText: "Não, cancelar.",
        confirmButtonText: '<i class="fas fa-stop"></i> Sim, finalizar.',
      }).then((re) => {
        if (re.isConfirmed) {
          this.$socket.emit(
            "atendimento:finalizarAtendimentoAgenda",
            this.agendaInfo.id
          );
        }
      });
    },
    async onVenda(venda) {
      console.log("chamou onVenda no atendimentoLista", venda);
      venda;
      //this.closeModal();
      await this.$nextTick();
      this.$forceUpdate();
      // if (venda && venda?.id) {
      //   if (!this.horarioSelecionado.venda_id) {

      //     if (this.horarioSelecionado.at_status === 'PENDENTE') {
      //       this.horarioSelecionado.at_status = 'FILA DE ESPERA'
      //       this.horarioSelecionado.compareceu = 1
      //        this.updateHorario(this.horarioSelecionado)
      //     }else if(this.horarioSelecionado.at_status == 'EM ATENDIMENTO'){
      //       this.concluirAtendimento( this.horarioSelecionado)
      //     }
      //   }

      // }

      this.solicitarLista();
    },
    async onVinculou(venda) {
      //console.log("vinculou", venda);
      venda;
      await this.$nextTick();
      this.$forceUpdate();

      this.solicitarLista();
    },
    voltarHorario(horario) {
      if (!horario || !horario?.id) return;

      console.log(horario);
      this.$socket.emit("atendimento:voltarHorario", horario);
      // this.closeModal();
      // this.filtro = 0;
    },
    iniciarAtedimento(horario) {
      if (!horario || !horario?.id) return;
      this.$socket.emit("atendimento:iniciarAtendimento", horario);
    },
    finalizarAtedimento(horario) {
      this.$socket.emit("atendimento:finalizarAtendimento", horario);
    },
    concluirAtendimento(horario, venda = {}) {
      if (!horario.venda_id) {
        horario.venda_id = venda?.id;
      }
      this.$socket.emit("atendimento:concluirAtendimento", horario);
    },
    updateHorario(horario) {
      // console.log("update horario", horario);
      this.$socket.emit("atendimento:updateHorario", horario);
    },
    openPaciente(horarioSelecionado) {
      this.horarioSelecionado = horarioSelecionado;
      this.$bvModal.show("modal-paciente");
    },
    closeModal() {
      this.horarioSelecionado = null;
      this.$bvModal.hide("modal-paciente");
    },
    joinRoom() {
      this.$socket.emit("atendimento:joinRoom", this.agendaId);
      this.calculaTempo();
    },
    solicitarLista() {
      this.$socket.emit("atendimento:lista", this.agendaId);
    },
    calculaTempo() {
      if (this.agendaInfo.at_inicio && this.agendaInfo.at_termino) {
        let diffTime = moment.duration(
          moment(this.agendaInfo.at_termino).diff(
            moment(this.agendaInfo.at_inicio)
          )
        );

        this.tempo =
          (diffTime.hours() > 0
            ? (diffTime.hours() < 10 ? "0" : "") + diffTime.hours() + ":"
            : "") +
          (diffTime.minutes() < 10 ? "0" : "") +
          diffTime.minutes() +
          ":" +
          (diffTime.seconds() < 10 ? "0" : "") +
          diffTime.seconds();

        if (this.timer) {
          clearInterval(this.timer);
        }
        return;
      }

      if (this.agendaInfo.at_inicio) {
        var diffTime = moment.duration(
          moment(moment()).diff(moment(this.agendaInfo.at_inicio))
        );

        this.tempo =
          (diffTime.hours() > 0
            ? (diffTime.hours() < 10 ? "0" : "") + diffTime.hours() + ":"
            : "") +
          (diffTime.minutes() < 10 ? "0" : "") +
          diffTime.minutes() +
          ":" +
          (diffTime.seconds() < 10 ? "0" : "") +
          diffTime.seconds();
        // console.log(1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/atendimento.scss";
.hover_padrao {
  &:hover {
    transform: scale(102%);
    box-shadow: 0px 4px 17px 4px rgba(0, 0, 0, 0.11);
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>