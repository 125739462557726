<template>
  <div>
    <b-btn size="sm" @click="openForm" variant="primary">
      <i class="fas fa-plus"></i>
    </b-btn>

    <b-modal id="form-medico" hide-footer>
      <template #modal-title>
        <div>
          Buscar / Adicionar Médico
        </div>
      </template>
      <form-medico-resumido @added="onAdded" @close="closeForm" ></form-medico-resumido>
    </b-modal>
  </div>
</template>

<script>
import FormMedicoResumido from "./FormMedicoResumido.vue";
export default {
  components: {
    FormMedicoResumido,
  },
  data(){
    return{

    }
  },
  methods: {
    openForm(){
      this.$bvModal.show('form-medico')
    },
    closeForm(){
      this.$bvModal.hide('form-medico')

    },
    onAdded(medico){
      this.$emit('added', medico)
    }
  }
};
</script>

<style></style>
