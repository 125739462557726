<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-8 text-left">
        <table
          class="table table-sm"
          style="font-size: 12px"
        >
          <tbody>
            <tr>
              <th><b-icon-asterisk /> Venda Num.</th>
              <td v-if="!loading && info">
                {{ info.id }}
                <small
                  ><small>CR:{{ info.contas_receber_id }}</small></small
                >
              </td>
              <td v-else>
                <b-skeleton
                  class="p-1 mx-1"
                  width="200px"
                  height="100%"
                ></b-skeleton>
              </td>
            </tr>

            <tr>
              <th><b-icon-calendar-day /> Data/Hora</th>
              <td v-if="!loading">
                {{ info.data | moment("DD/MM/YYYY HH:mm:ss") }}
                <b-btn
                  variant="none"
                  :disabled="user.perfil != 'admin'"
                  @click="openEditData"
                >
                  <i class="fa fa-edit" />
                </b-btn>
              </td>
              <td v-else>
                <b-skeleton
                  class="p-1 mx-1"
                  width="200px"
                  height="100%"
                ></b-skeleton>
              </td>
            </tr>
            <tr>
              <th><b-icon-person /> Usuário Lançamento</th>
              <td v-if="!loading">{{ info.usuario }}</td>
              <td v-else>
                <b-skeleton
                  class="p-1 mx-1"
                  width="200px"
                  height="100%"
                ></b-skeleton>
              </td>
            </tr>
            <tr>
              <th><b-icon-shop /> Clínica</th>
              <td v-if="!loading">
                {{ info.clinica }}
                <b-btn
                  variant="none"
                  @click="
                    alterandoClinica = false;
                    $bvModal.show('modal-alterar-clinica');
                  "
                  ><i class="fa fa-edit"
                /></b-btn>
              </td>
              <td v-else>
                <b-skeleton
                  class="p-1 mx-1"
                  width="200px"
                  height="100%"
                ></b-skeleton>
              </td>
            </tr>
            <tr>
              <th><b-icon-person-badge-fill /> Paciente / Cliente</th>
              <td v-if="!loading">
                {{ info.Cliente }}
              </td>
              <td v-else>
                <b-skeleton
                  class="p-1 mx-1"
                  width="200px"
                  height="100%"
                ></b-skeleton>
              </td>
            </tr>
            <tr>
              <th><b-icon-card-heading /> Fidelização</th>
              <td></td>
            </tr>

            <tr>
              <td
                colspan="2"
                class="p-0 pt-2"
              >
                <table
                  class="table"
                  style="font-size: 11px"
                >
                  <thead>
                    <tr>
                      <th>Item/Produto</th>
                      <th class="text-center">Valor</th>
                      <th class="text-center">Desconto/Acresimo</th>
                      <th class="text-center">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item of info.itens"
                      :key="`itemVenda${item.id}`"
                    >
                      <td>
                        <small
                          ><small
                            ><i class="fa fa-user-md" /> {{ item.fornecedor }}
                            <br /> </small
                        ></small>
                        <small><i class="fas fa-clipboard"></i> </small>
                        <b>{{ item.descricao }}</b> <br />
                        <small
                          ><i class="fab fa-accessible-icon" />
                          {{ item.paciente }}</small
                        >
                        <!-- <b-btn
                          @click="editPacienteItem(item)"
                          variant="transparent"
                          size="sm"
                        >
                          <i class="fas fa-pen"></i>
                        </b-btn> -->
                      </td>
                      <td class="text-center">
                        {{ item.vl_unitario | currency }}
                      </td>
                      <td class="text-center">
                        {{ (item.vl_desconto * -1) | currency }}
                      </td>
                      <td class="text-center">
                        {{ item.vl_total | currency }}
                      </td>
                      <!-- {{item}} -->
                      <td
                        v-b-popover.hover.top="
                          item.gera_guia == 1
                            ? 'Imprimir Guia'
                            : `Foi desabilitado a impressão de guias para este(a) ${item.tipo_venda}!`
                        "
                      >
                        <b-btn
                          v-if="
                            ['Exame', 'Fidelização', 'Consulta'].includes(
                              item.tipo_venda
                            )
                          "
                          :disabled="item.gera_guia == 0"
                          variant="secondary"
                          size="sm"
                          block
                          @click="printItem(item)"
                        >
                          <!-- <div v-if="item.printLoad">
                            <b-spinner small />
                          </div>
                          <div v-else> -->
                          <i class="fa fa-print" />
                          <!-- </div> -->
                        </b-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        colspan="3"
                        class="text-right"
                      >
                        Total
                      </th>
                      <th class="text-center">
                        {{ info.vl_total | currencyWithMask }}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </td>
            </tr>
            <!---------NOTAS FISCAIS------->
            <tr>
              <th
                class="text-center"
                colspan="2"
              >
                <i class="fa fa-file-invoice-dollar" /> Nota Fiscal
              </th>
            </tr>
            <tr>
              <div class="d-flex">
                <div v-if="info.nf">
                  <b>Status: </b>
                  <small>
                    {{ info.nf.status }} -
                    {{
                      info.nf && info.nf.msg ? info.nf.msg.slice(0, 50) : ""
                    }}</small
                  >
                </div>
                <div v-else>
                  <small class="text-muted"> NFSE não solicitada </small>
                </div>
                <div class="ml-auto p-1">
                  <small
                    class="text-muted"
                    v-if="loadingNFSE.loading"
                  >
                    <b-spinner small></b-spinner> {{ loadingNFSE.msg }}
                  </small>
                </div>
              </div>
            </tr>
            <tr
              v-if="!readonly"
              class="d-flex justify-content-between"
            >
              <b-btn
                @click="solicitarNFE"
                class=""
                size="sm"
                variant="transparent"
                :disabled="info.nf || loadingNFSE.loading || info.cancelado"
              >
                <div v-if="!loadingNFSE.loading">
                  <i class="fas fa-plus"></i> <small>Solicitar</small>
                </div>
                <div v-if="loadingNFSE.loading">
                  <b-spinner small></b-spinner>
                  <small class="text-muted">Solicitando...</small>
                </div>
              </b-btn>

              <a
                v-if="info.nf"
                class="btn btn-sm"
                :class="{
                  disabled:
                    !info.nf ||
                    !info.nf.pdfUrl ||
                    info.nf.status !== 'CONCLUIDO',
                }"
                :href="info.nf.pdfUrl"
                @click.prevent="downloadPDF(info.nf.pdfUrl)"
                target="_blank"
                ref="imprimirPdf"
              >
                <i class="fas fa-print"></i> <small>Imprimir</small></a
              >

              <b-btn
                :disabled="!info.nf"
                size="sm"
                @click="openNFDetalhes"
                variant="transparent"
              >
                <i class="fas fa-info"></i> <small>Detalhes</small>
              </b-btn>
            </tr>
            <tr>
              <th
                class="text-center"
                colspan="2"
              >
                <i class="fa fa-signature" /> Receitas
              </th>
            </tr>
            <tr>
              <div class="">
                <div class="d-flex align-items-center p-2">
                  <!-- <b-spinner class="mr-2" small></b-spinner> -->
                  <!-- <small class="text-muted"> Carregando...</small> -->
                </div>

                <div
                  v-if="
                    Array.isArray(info.receitas) && info.receitas.length > 0
                  "
                >
                  <tr
                    v-for="receita of info.receitas"
                    :key="receita.id"
                  >
                    <td><b>Tipo: </b> {{ receita.tipo }}</td>
                    <td><b>Médico: </b> {{ receita.medicoNome }}</td>
                    <td>
                      <b-btn
                        @click="openReceita(receita)"
                        size="sm"
                        >Abrir
                      </b-btn>
                    </td>
                  </tr>
                </div>

                <!-- <b>Status: </b>
                  <small> {{ info.nf.status }} - {{ info.nf.msg }}</small>
                   -->
                <!-- {{ info.receitas }} -->

                <div v-else>
                  <small class="text-muted"> Nenhuma Receita</small>
                </div>
                <div class="ml-auto p-1">
                  <!-- <small class="text-muted" v-if="loadingReceita">
                    <b-spinner small></b-spinner>
                  </small> -->
                </div>
              </div>
            </tr>
            <tr
              v-if="!readonly"
              class="d-flex justify-content-between"
            >
              <b-btn
                class=""
                size="sm"
                variant="transparent"
              >
                <!-- <div v-if="!loadingReceita">
                  <i class="fas fa-plus"></i> <small>Lançar</small>
                </div>
                <div v-if="loadingReceita">
                  <b-spinner small></b-spinner>
                  <small class="text-muted">Solicitando...</small>
                </div> -->
              </b-btn>

              <!-- <a
                v-if="info.nf"
                class="btn btn-sm"
                :class="{ disabled: !info.nf || !info.nf.pdfUrl }"
                :href="info.nf.pdfUrl"
                @click.prevent="downloadPDF(info.nf.pdfUrl)"
                target="_blank"
                ref="imprimirPdf"
              >
                <i class="fas fa-print"></i> <small>Imprimir</small></a
              > -->
              <!-- 
              <b-btn
                :disabled="!info.nf"
                size="sm"
                @click="openNFDetalhes"
                variant="transparent"
              >
                <i class="fas fa-info"></i> <small>Detalhes</small>
              </b-btn> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-sm-4">
        <table class="table table-sm">
          <tr>
            <th colspan="2">
              <i class="fa fa-cash-register" /> Pagamentos
              <div
                v-if="info.pagamentos && info.pagamentos.length > 0"
                class="pl-2 float-right"
              >
                <small>
                  (Rec. ID: {{ info.pagamentos[0].recebimento_id }})
                </small>
              </div>
            </th>
          </tr>
          <tr>
            <td class="p-0 pt-1">
              <div
                class="border rounded p-2 shadow-sm mb-2"
                v-for="pgto in info.pagamentos"
                :key="`pgto_${pgto.id}`"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between w-100">
                    <div class="">
                      <b
                        >{{
                          pgto.forma == "DEF"
                            ? "Desconto em Folha"
                            : pgto.forma == "Crédito"
                            ? "Crédito de Cliente"
                            : pgto.forma
                        }}
                        <small class="">
                          <small>
                            <br />
                            (ID: {{ pgto.id }})
                          </small>
                        </small>
                      </b>
                      <small
                        class=""
                        v-if="pgto.forma === 'CARTAO'"
                      >
                        - <b>{{ pgto.dados_pagamento.tipo }}</b>
                      </small>
                    </div>

                    <div>
                      <b> {{ pgto.valor | currencyWithMask }}</b>
                    </div>
                  </div>
                  <div
                    v-if="
                      pgto.forma === 'CARTAO' && pgto && pgto.dados_pagamento
                    "
                  >
                    <small>
                      <b>Parcelas: </b> {{ pgto.dados_pagamento.parcelas }}
                      <br />
                      <b>Autorização: </b>
                      {{ pgto.dados_pagamento.autorizacao }} <b>CV: </b>
                      {{ pgto.dados_pagamento.documento }} <b>Bandeira: </b>

                      <span
                        v-if="
                          pgto &&
                          pgto.dados_pagamento &&
                          pgto.dados_pagamento.bandeira
                        "
                        >{{ pgto.dados_pagamento.bandeira.nome }}</span
                      >
                    </small>
                  </div>
                  <div v-if="pgto.forma === 'CONVENIO'">
                    <small>
                      <b>Parcelas: </b> {{ pgto.dados_pagamento.parcelas }}

                      <div>
                        <b>Empresa: </b>
                        {{ pgto.dados_pagamento.entidade.razao_social }}
                      </div>
                      <div>
                        <b>CPF/CNPJ:</b> {{ pgto.dados_pagamento.entidade.cpf }}
                      </div>
                      <b>Vale: </b> {{ pgto.dados_pagamento.vale }}
                    </small>
                  </div>
                  <div v-if="pgto.forma === 'DEF'">
                    <small>
                      <div>
                        <b>Funcionário: </b>
                        {{ pgto.dados_pagamento.entidade.razao_social }}
                      </div>
                      <div>
                        <b>CPF:</b> {{ pgto.dados_pagamento.entidade.cpf }}
                      </div>
                      <div><b>Vale: </b> {{ pgto.dados_pagamento.vale }}</div>
                      <div>
                        <b>Parcelas:</b> {{ pgto.dados_pagamento.parcelas }}
                      </div>
                    </small>
                  </div>
                  <div v-if="pgto.forma === 'PIX'">
                    <small v-if="pgto.dados_pagamento.pix">
                      <div>
                        <b>Identificador: </b>
                        {{ pgto.dados_pagamento.pix.txId }}
                      </div>
                      <div>
                        <b>Status: </b>
                        {{ pgto.dados_pagamento.pix.status }}
                      </div>
                    </small>
                  </div>
                </div>
                <b-btn
                  variant="warning"
                  size="sm"
                  block
                  v-if="user.perfil == 'admin' && pgto.forma == 'CONVENIO'"
                  @click="editPagamento(pgto)"
                  ><b-icon-pen /> Editar</b-btn
                >
              </div>
            </td>
          </tr>
        </table>

        <div
          class="card text-white bg-danger mb-3"
          style="max-width: 18rem"
          v-if="info.cancelado"
        >
          <h5 class="card-header">
            <i class="fas fa-ban"></i> Venda Cancelada
          </h5>
          <div class="card-body">
            <div>
              <i class="fas fa-user"></i> <b>Usuário: </b>
              {{ info.usuarioCancelou }}
            </div>
            <div>
              <i class="fas fa-calendar-alt"></i> <b>Data: </b>
              {{ info.dt_cancelado | moment("DD/MM/YYYY") }} às
              {{ info.dt_cancelado | moment("HH:mm:ss") }}
            </div>
            <div class="mt-2">
              <i class="fas fa-quote-left"></i> <b>Motivo: </b>
              <div class="text-center">
                {{ info.obs_cancelado }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
          v-if="info && info.devolucoes && info.devolucoes.length > 0"
        >
          <h5 class="card-header bg-danger text-white">
            <i class="fas fa-undo"></i> Devoluções
          </h5>
          <div class="card-body">
            <b-card
              class="py-2 mb-2 cardDev"
              style="font-size: 10px"
              v-for="devolucao in info.devolucoes"
              :key="'dev_' + devolucao.id"
              @click="openDevolucao(devolucao.id)"
            >
              <b>Código: </b>{{ devolucao.id }}
              <br />
              <b>Data: </b>
              {{ devolucao.created_at | moment("DD/MM/YY HH:mm") }}
              <br />
              <b>Usuário: </b> {{ devolucao.usuario }}
              <br />
              <b>Valor Estornado: </b>
              {{ devolucao.vl_estorno | currencyWithMask }}
              <br />
              <b>Tipo Estorno: </b> {{ devolucao.tipo_devolucao }}
              <br />
              <b>Motivo: </b> {{ devolucao.motivo }}
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button
          @click="openFichaCliente"
          class="btn float-left"
        >
          <i class="fa fa-id-card-alt" /> Ficha Cliente
        </button>

        <button
          v-if="!readonly"
          :disabled="disabledCancelarVenda"
          @click="cancelarVenda(vendaId)"
          class="btn float-left"
        >
          <i class="fa fa-ban" /> Cancelar Venda
        </button>
        <!-- <button @click="resnc" class="btn float-left">
          <i class="fa fa-recycle" /> Sincronizar
        </button> -->

        <button
          v-if="!readonly"
          :disabled="!user.isAdmin"
          @click="devolverVenda(vendaId)"
          class="btn float-left"
        >
          <i class="fa fa-undo" /> Devolução
        </button>

        <button
          class="btn"
          @click="close"
        >
          <i class="fa fa-door-open" /> Fechar
        </button>
      </div>
    </div>

    <imprimir-guia-consulta
      :item="itemSelected"
      v-if="printConsulta"
      @close="closePrintGuia()"
      @done="donePrint()"
    />
    <imprimir-guia
      :item="itemSelected"
      v-if="printGuia"
      @close="closePrintGuia()"
      @done="donePrint()"
    />

    <imprimir-contrato-fidelizacao
      v-if="
        printContrato &&
        itemSelected &&
        itemSelected.fidelizacao &&
        itemSelected.fidelizacao.entidade_fidelizacao_id
      "
      :id="itemSelected.fidelizacao.entidade_fidelizacao_id"
      @close="closePrintContrato()"
      @done="donePrint()"
    />

    <b-modal
      no-enforce-focus
      hide-footer
      size="xl"
      id="nota-fiscal-detalhes"
    >
      <template #modal-title>
        <div>Detalhe Nota Fiscal - {{ info.nf.id }}</div>
      </template>
      <nota-fiscal-detalhes
        :itemObj="info.nf"
        @close="closeNFDetalhes"
        :id="notaFiscal"
        @carregar="carregar"
        @getStatusNota="getStatusNota($event)"
        @reenviarNota="reenviarNota"
      />
    </b-modal>

    <b-modal
      id="ficha-paciente"
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-footer
      hide-header
      @close="closeFichaCliente"
    >
      <PacienteFicha
        :paciente_id="info.paciente_id"
        @close="closeFichaCliente"
      />
    </b-modal>

    <b-modal
      id="modal-receita"
      size="lg"
      hide-footer
      hide-header
      @close="closeReceita"
    >
      <paciente-receita-tipo
        readonly
        @close="closeReceita"
        :receita_edit="receitaSelecionada"
      ></paciente-receita-tipo>
    </b-modal>

    <b-modal
      id="modal-edit-paciente-item"
      v-if="itemToEdit && itemToEdit.id && itemToEdit.paciente_id"
    >
      <template #modal-title>
        <i class="fas fa-pen"></i> Editando paciente do item
      </template>
      <select-paciente
        :showBotaoRemove="false"
        :selecionado="itemToEdit.paciente_id"
        @paciente="tmpPaciente = $event"
      />
      <div v-if="tmpPaciente && tmpPaciente.id">
        <small class="d-flex justify-content-between">
          <div>
            <b> CPF/CNPJ: </b>

            {{ tmpPaciente.cpf }}
          </div>

          <div>
            <b> Qtd. Cartões: </b>
            {{ tmpPaciente.fidelizacoes.length }}
          </div>
        </small>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-btn
            variant="primary"
            @click="changePacienteItem"
            class="mr-3"
          >
            <i class="fas fa-save"></i> Trocar</b-btn
          >
          <b-btn
            variant="secondary"
            @click="closeEditPaciente"
          >
            <i class="fas fa-ban"></i> Fechar</b-btn
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      size="sm"
      id="modal-altera-data"
      hide-footer
      no-enforce-focus
      no-close-on-backdrop
      title="Alterar Data"
    >
      <b-input
        type="date"
        v-model="info.dataFormatada"
      />

      <botoes-salvar-cancelar
        @salvar="editData"
        @cancelar="closeEditData"
      />
    </b-modal>
    <b-modal
      id="modal-edit-pgto"
      hide-footer
      title="Editar Pagamento"
      size="lg"
    >
      <CaixaFormaPagamentoForm
        :notChange="true"
        v-if="pgtoEdit"
        :valorAberto="0"
        :edit="pgtoEdit.dados_pagamento"
        @salvar="salvarPagamento"
      />
    </b-modal>
    <b-modal
      id="modal-alterar-clinica"
      hide-footer
      title="Alterar Clínica"
      no-enforce-focus
      no-close-on-backdrop
    >
      <SelectClinicas
        :autoBlock="false"
        :consultorio="false"
        @consultorio="info.empresa_id = $event"
        :valueConsultorio="info.empresa_id"
        v-model="info.empresa_id"
      />
      <hr />
      <div class="text-right">
        <b-btn
          variant="success"
          @click="alterarClinica"
          :disabled="alterandoClinica"
        >
          <b-spinner
            small
            size="small"
            class="mr-1"
            v-if="alterandoClinica"
          />
          <b-icon-save
            class="mr-1"
            v-if="!alterandoClinica"
          />
          Alterar</b-btn
        >
        <b-btn
          variant="secondary"
          @click="$bvModal.hide('modal-alterar-clinica')"
          ><b-icon-x-circle class="mr-1" /> Cancelar</b-btn
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-devolver-venda"
      hide-footer
      title="Devolução"
    >
      <venda-devolucao
        :venda-id="info.id"
        @cancelar="$bvModal.hide('modal-devolver-venda')"
        @salvo="devolucaoSalva"
      ></venda-devolucao>
    </b-modal>

    <b-modal
      id="modal-devolucao-detail"
      size="lg"
      hide-footer
      title="Devolução"
    >
      <venda-devolucao-detail
        :devolucaoId="devolucaoOpenId"
      ></venda-devolucao-detail>
    </b-modal>
  </div>
</template>

<script>
import VendaLib from "../../../libs/VendaLib";
import ImprimirGuia from "../../Exames/ImprimirGuia.vue";
//import NotasFiscaisLib from "../../../libs/NotasFiscaisLib";
import NotaFiscalDetalhes from "../../NotasFiscais/NotaFiscalDetalhes.vue";
import SelectPaciente from "../../common/SelectPaciente.vue";
import PacienteReceitaTipo from "../../Entidades/Paciente/PacienteReceitas/PacienteReceitaTipo.vue";
import EntidadesLib from "../../../libs/EntidadesLib";
import ImprimirContratoFidelizacao from "../../Exames/ImprimirContratoFidelizacao.vue";
//import axios from "axios";
import { buscarCEP } from "../../../utils/cep";
import ImprimirGuiaConsulta from "../../Exames/ImprimirGuiaConsulta.vue";
import moment from "moment";
import BotoesSalvarCancelar from "../../common/BotoesSalvarCancelar.vue";
import CaixaFormaPagamentoForm from "../CaixaFormaPagamentoForm.vue";
import SelectClinicas from "../../common/SelectClinicas.vue";
import VendaDevolucao from "./VendaDevolucao.vue";
import VendaDevolucaoDetail from "./VendaDevolucaoDetail.vue";

export default {
  components: {
    ImprimirGuia,
    NotaFiscalDetalhes,
    PacienteFicha: () => import("../../Entidades/Paciente/PacienteFicha.vue"),
    ImprimirContratoFidelizacao,
    ImprimirGuiaConsulta,
    SelectPaciente,
    PacienteReceitaTipo,
    BotoesSalvarCancelar,
    CaixaFormaPagamentoForm,
    SelectClinicas,
    VendaDevolucao,
    // VendaDevolucaoDetail: ()=> import('./VendaDevolucaoDetail.vue'),
    VendaDevolucaoDetail,
  },
  props: {
    vendaId: Number,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.carregar();
    this.$socket.emit("venda:abrirNFSE", this.vendaId);
  },
  sockets: {
    // connect() {
    //   // //console.log("conectado");

    //   this.$socket.emit("joinVenda", this.info.id);
    // },
    success(msg) {
      this.showToast("success", msg);
    },
    error(msg) {
      msg = Array.isArray(msg)
        ? msg
        : typeof msg === "string" && msg.length > 0
        ? [msg]
        : ["Ocorreu algum erro inesperado ao emitir a nota."];
      let errorDesc = msg.reduce((ret, vl) => {
        ret += vl + "<hr/>";
        return ret;
      }, "");
      this.$swal({
        title: "Atenção",
        html: `<b>Não foi possível solicitar a emissão da nota fiscal, verifique: </b><hr/><span class='text-left'>${errorDesc}</span>`,
        icon: "error",
        allowEscapeKey: true,
      });
      //this.showToast("error", msg);
    },
    loadingNFSE(loading) {
      this.setLoading(loading);
    },
    async nfseInfo(nf) {
      // //console.log("notaInfo", nf);

      await this.onNfseInfo(nf);
      this.$emit("venda:nfse", this.info);
    },
    "nfse:callback"() {
      this.$socket.emit("venda:abrirNFSE", this.vendaId);
    },
  },
  data() {
    return {
      devolucaoOpenId: 0,
      empresa_id: 0,
      info: {
        nf: {},
        receitas: [],
      },
      tmpPaciente: {},
      itemToEdit: null,
      receitaSelecionada: {},
      notaFiscal: {},
      printGuia: false,
      printContrato: false,
      printConsulta: false,
      itemSelected: null,
      nfseId: null,
      loading: false,
      loadingNFSE: {
        loading: false,
        msg: "",
      },
      loadingReceitas: false,
      pgtoEdit: null,
      alterandoClinica: false,
    };
  },
  watch: {
    vendaId() {
      this.carregar();
    },
  },
  computed: {
    disabledCancelarVenda() {
      const isBefore = this.info.data
        ? moment(this.info.data).isBefore(moment().format("YYYY-MM-DD"), "days")
        : false;
      return (
        this.info?.cancelado === 1 ||
        (this.info?.devolucoes && this.info?.devolucoes.length > 0) ||
        (isBefore && !this.user.isAdmin)
      );
    },
  },
  methods: {
    openDevolucao(devolucaoOpenId) {
      this.devolucaoOpenId = devolucaoOpenId;
      console.log("dev", devolucaoOpenId);
      this.$bvModal.show("modal-devolucao-detail");
    },
    devolucaoSalva() {
      this.carregar();
      this.$bvModal.hide("modal-devolver-venda");
    },
    async alterarClinica() {
      this.alterandoClinica = true;
      try {
        let res = await VendaLib.alterarClinica(
          this.info.id,
          this.info.empresa_id
        );
        //console.log("res alt clin", res);
        if (res && !res.success) {
          this.$swal("Atenção!", res.message, "error");
        } else {
          await this.$swal("Concluído!", "Transferência OK", "success");
          this.$bvModal.hide("modal-alterar-clinica");
          this.carregar();
        }
      } catch (error) {
        //console.log("Erro ao alterar clinica", error);
      }
      this.alterandoClinica = false;
    },
    async editData() {
      try {
        if (
          this.info.dataFormatada &&
          moment(this.info.dataFormatada).isValid()
        ) {
          let res = await VendaLib.alterarData(
            this.info.id,
            this.info.dataFormatada
          );
          // //console.log(res);
          if (res === true) {
            this.$swal(
              "Sucesso",
              "Venda transferida para caixa do dia " +
                moment(this.info.dataFormatada).format("DD/MM/YYYY") +
                " com sucesso!",
              "success"
            );
            this.carregar();
            this.$bvModal.hide("modal-altera-data");
          } else {
            this.$swal(
              "Erro",
              "Houve um erro ao realizar a transferencia: " + res,
              "error"
            );
          }
        } else {
          this.$swal("Atenção", "Informe uma data válida!", "error");
        }
      } catch (ex) {
        this.$swal("Atenção", "Informe uma data válida!", "error");
      }
    },
    closeEditData() {
      this.$bvModal.hide("modal-altera-data");
    },
    openEditData() {
      this.$bvModal.show("modal-altera-data");
    },
    resnc() {
      VendaLib.resync(this.vendaId);
      this.$swal(
        "Solicitado!",
        "Re-sincornização solicitado, aguarde de 30 a 40 segundos para confirmar",
        "success"
      );
    },
    editPacienteItem(item) {
      // //console.log("editando o paciente do item: ", item);
      if (item) {
        this.itemToEdit = Object.assign({}, item);
        this.$forceUpdate();
        this.$nextTick(() => {
          this.$bvModal.show("modal-edit-paciente-item");
        });
      }
    },
    closeEditPaciente() {
      this.itemToEdit = null;
      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-paciente-item");
      });
    },
    changePacienteItem() {
      ////console.log(this.tmpPaciente)

      if (this.tmpPaciente?.id && this.itemToEdit?.id) {
        this.$swal({
          title: "Atenção",
          html: `Isso irá trocar o paciente de <b>${this.itemToEdit.paciente}</b> para <b>${this.tmpPaciente.razao_social}</b>`,
          icon: "error",

          showCancelButton: true,

          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
        }).then((e) => {
          if (e.isConfirmed) {
            const idxItem = this.info.itens.findIndex(
              (i) => i.id === this.itemToEdit.id
            );
            // //console.log('idx que vai atualizar',idxItem)
            ////console.log('item',this.info.itens[idxItem])
            this.$set(
              this.info.itens[idxItem],
              "paciente_id",
              this.tmpPaciente.id
            );
            this.$set(
              this.info.itens[idxItem],
              "paciente",
              this.tmpPaciente.razao_social
            );
            this.$set(this.info.itens[idxItem], "_rowStatus", "updated");
            this.itemToEdit = null;
            this.tmpPaciente = {};
            this.$socket.emit(
              "venda:atualizarItem",
              this.vendaId,
              this.info.itens[idxItem]
            );
            this.showToast(
              "success",
              "Paciente do item trocado com sucesso!",
              3500
            );
          }
        });
      }
    },
    async carregar() {
      this.loading = true;
      this.info = (
        await VendaLib.buscarVendas({ "vendas.id": this.vendaId })
      )[0];
      this.empresa_id = this.info.empresa_id;
      this.info.dataFormatada = moment(this.info.data).format("YYYY-MM-DD");
      this.loading = false;
      await this.carregarReceitas();
      console.log("info", this.info);
    },
    async carregarReceitas() {
      if (this.info.id) {
        this.loadingReceitas = true;
        try {
          this.info.receitas = await VendaLib.getReceitas(this.info.id);
        } catch (error) {
          //console.log("erro ao carregar receitas", error);
        }
        this.loadingReceitas = false;
      }
    },
    openReceita(receita) {
      receita;
      this.receitaSelecionada = Object.assign({}, receita);
      this.$bvModal.show("modal-receita");
    },
    closeReceita() {
      this.$bvModal.hide("modal-receita");
    },
    devolverVenda(id) {
      console.log(id);
      this.$bvModal.show("modal-devolver-venda");
    },
    cancelarVenda(id) {
      if (this.disabledCancelarVenda) return;
      this.$emit("cancelarVenda", id);
    },
    reenviarNota() {
      this.$socket.emit("venda:reenviarNFSE", this.info.nf.id);
    },
    async downloadPDF(url) {
      //console.log(url, url + "?token=" + this.user.token);
      window.open(url + "?token=" + this.user.token);
      // await axios
      //   .get(url, { responseType: "blob" })
      //   .then((res) => {
      //     const blob = new Blob([res.data], { type: "application/pdf" });
      //     const link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.target = "_blank";
      //     link.click();
      //     URL.revokeObjectURL(link.href);
      //   })
      //   .catch((err) => {
      //     //console.log("Erro ao baixar PDF ->", err);
      //     this.showToast(
      //       "error",
      //       "Há algum problema com o PDF dessa nota, consulte o T.I",
      //       3500
      //     );
      //   });
    },
    getStatusNota() {},
    setLoading(loading) {
      this.$set(this.loadingNFSE, "loading", loading.loading);
      this.$set(this.loadingNFSE, "msg", loading.msg);
    },
    openFichaCliente() {
      this.$bvModal.show("ficha-paciente");
    },
    closeFichaCliente() {
      this.$bvModal.hide("ficha-paciente");
    },
    async printItem(item) {
      if (!item.data && this.info.data) {
        item.data = moment(this.info.data).format("DD/MM/YYYY");
      }
      console.log("---", item);
      item.printLoad = true;
      this.printGuia = false;
      this.printContrato = false;
      this.printConsulta = false;

      await this.$forceUpdate();
      await this.$nextTick();

      setTimeout(async () => {
        this.itemSelected = item;

        await this.$nextTick();
        if (item.tipo_venda == "Exame") {
          this.printGuia = true;
        } else if (item.tipo_venda == "Fidelização") {
          this.printContrato = true;
        } else if (item.tipo_venda === "Consulta") {
          this.printConsulta = true;
        }
        await this.$nextTick();
      }, 5);
    },
    async onNfseInfo(nfse) {
      this.$forceUpdate();
      await this.$nextTick();
      this.$set(this.info, "nf", nfse);
      // this.info.nf = nfse
    },
    donePrint() {
      // //console.log("DONE PRINT");
      for (let it of this.info.itens) {
        if (it.printLoad) it.printLoad = false;
      }
    },
    closePrintGuia() {
      this.printGuia = false;
    },
    closePrintContrato() {
      this.printContrato = false;
    },
    closePrintConsulta() {
      this.printConsulta = false;
    },
    close() {
      this.$emit("close");
    },
    openNFDetalhes() {
      this.$bvModal.show("nota-fiscal-detalhes");
    },
    closeNFDetalhes() {
      this.$bvModal.hide("nota-fiscal-detalhes");
    },
    async validaNFE() {
      const validacao = {
        validou: true,
        message: [],
      };
      const cliente = (await EntidadesLib.getResumido(this.info.paciente_id))
        .entidades[0];
      //  //console.log("CLEINTE", cliente);
      if (cliente) {
        if (Array.isArray(cliente.enderecos) && cliente.enderecos.length == 0) {
          validacao.validou = false;
          validacao.message.push(
            "O cliente <b>não tem nenhum endereço</b> cadastrado, por favor, cadastre."
          );
        } else {
          const endereco = Object.assign({}, cliente.enderecos[0]);
          //  //console.log(endereco);
          if (!endereco.codCidade) {
            validacao.validou = false;
            validacao.message.push(
              `O cliente está com o endereço inválido. [Código do Municipio inválido]`
            );
          }
          if (!endereco.logradouro) {
            validacao.validou = false;
            validacao.message.push(
              `O cliente está com o endereço inválido. [Logradouro inválido]`
            );
          }
          if (endereco && endereco.cep) {
            let enderecoCEP = null;
            try {
              enderecoCEP = await buscarCEP(endereco.cep);
            } catch (error) {
              enderecoCEP = null;
            }

            if (!enderecoCEP) {
              validacao.validou = false;
              validacao.message.push(
                `O cliente está com o endereço inválido. [CEP inválido: ${endereco.cep}]`
              );
            }
          } else {
            validacao.validou = false;
            validacao.message.push(
              `O cliente está com o endereço inválido, por favor, verifique a ficha. `
            );
          }
        }
        if (cliente.telefones ? cliente.telefones.length === 0 : true) {
          validacao.validou = false;
          validacao.message.push(
            "O cliente não tem nenhum telefone cadastrado."
          );
        }
      } else {
        validacao.message.push("Erro no cliente");
      }
      validacao.validou = validacao.message.length === 0;
      return validacao;
    },

    async solicitarNFE() {
      const validar = await this.validaNFE();
      console.debug("validar", validar);
      if (!validar.validou) {
        let errorDesc = await validar.message.reduce((ret, vl) => {
          ret += vl + "<hr/>";
          return ret;
        }, "");
        this.$swal({
          title: "Atenção",
          html: `<b>Não foi possível solicitar a emissão da nota fiscal, verifique: </b><hr/><span class='text-left'>${errorDesc}</span>`,
          icon: "error",

          showCancelButton: true,

          confirmButtonText: "Abrir ficha",
          cancelButtonText: "Fechar",
        }).then((e) => {
          if (e.isConfirmed) {
            this.openFichaCliente();
          }
        });

        return;
      } else {
        this.loadingNFSE.loading = true;
        this.$socket.emit(
          "venda:solicitarNFSE",
          this.vendaId,
          this.info.paciente_id
        );
      }
      // const nfse = {
      //   paciente_id: this.info.paciente_id,
      //   venda_id: this.info.id,
      // };

      // try {
      //   let result = await NotasFiscaisLib.solicitarNFE(nfse);
      //   if (result) {
      //     this.showToast(
      //       "success",
      //       "Nota solicitada com sucesso!",
      //       3000,
      //       "Aguarde o processamento do servidor."
      //     );
      //   }
      //   //console.log(result);
      // } catch (error) {
      //   //console.log("Erro ao inserir nota", error);
      //   this.showToast(
      //     "error",
      //     `Erro ao solicitar nota`,
      //     3000,
      //     "Aguarde o processamento do servidor."
      //   );
      // }
    },
    editPagamento(pgto) {
      this.pgtoEdit = pgto;
      this.$bvModal.show("modal-edit-pgto");
    },
    async salvarPagamento(pgto) {
      let result = await VendaLib.alterarPagamento(
        this.info.id,
        this.pgtoEdit,
        pgto
      );
      if (result && result.success) {
        this.$swal("Concluído", "Pagamento alterado com sucesso!", "success");
        this.carregar();
      } else {
        this.$swal(
          "Erro",
          "Erro ao alterar:" + (result && result.message ? result.message : ""),
          "error"
        );
      }
      this.$bvModal.hide("modal-edit-pgto");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";

th {
  background-color: $primary;
  color: white;
}
.cardDev {
  &:hover {
    background-color: $primary;
    color: white;
    cursor: pointer;
  }
}
</style>
