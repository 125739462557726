<template>
  <div>
    <b-modal
      v-if="printNow"
      id="modal-print-gruia"
      no-enforce-focus
      no-fade
      hide-backdrop
      hide-footer
      size="lg"
      @hide="
        printNow = false;
        $emit('close');
      "
    >
      <template #modal-title>
        <b-btn variant="none" @click="print"><b-icon-printer /> Imprimir</b-btn>
        <b-btn variant="none" @click="download"
          ><b-icon-download /> Baixar</b-btn
        >
        <b-btn variant="none" @click="sendWpp"
          ><b-icon-whatsapp /> Enviar via Whatsapp</b-btn
        >
      </template>
      <div id="printDiv">
        <print-component
          :filename="`Guia ${item.paciente}`"
          v-if="item"
          :printNow="printNow"
          @done="$emit('done')"
          :titulo="`Encaminhamento de Exame/Consulta`"
          :show="true"
          style="max-width: 500px"
        >
          <div class="row mt-2 p-2">
            <div class="col-12">
              <b>Código:</b>
              {{ item.id }}
            </div>
            <div class="col-6">
              <b>Data:</b>
              {{ item.data   }}
            </div>
            <div class="col-6">
              <b>Impresso em:</b>
              {{ data }}
            </div>
            <div class="col-6">
              <b>Hora da Consulta:</b>
              {{ item.hora_consulta ? item.hora_consulta.slice(0, 5) : "" }}
            </div>
            <div class="col-6">
              <b>Data Consulta:</b>
              {{ item.data_consulta | moment("DD/MM/YYYY") }}
            </div>
            <div class="col-12 mt-1">
              <b>Médico:</b>
              {{ item.fornecedor }}
            </div>
            <div class="col-12 mt-1">
              <b>Paciente:</b>
              {{ item.paciente }}
            </div>
            <!-- <div class="col-12 mt-1">
            <b>Laboratório:</b>
            {{ item.fornecedor }}
          </div> -->
            <div
              class="col-12 mt-1"
              v-if="
                item.fornecedor_enderecos &&
                item.fornecedor_enderecos.length > 0
              "
            >
              <div
                v-for="(endereco, key) of item.fornecedor_enderecos.filter(
                  (e) => e.consultorio
                )"
                :key="key"
              >
                <b>Endereço {{ key + 1 }}:</b>
                {{ endereco.logradouro }},{{ endereco.numero }} -
                {{ endereco.bairro }}
                |
                {{ endereco.cidade }} - {{ endereco.uf }} <br />
                <small v-if="endereco.complemento && endereco.complemento != ''"
                  ><b>Complemento:</b> <br />
                  {{ endereco.complemento }}</small
                >
              </div>
            </div>
            <div
              class="col-12 mt-1"
              v-if="
                item.fornecedor_telefones &&
                item.fornecedor_telefones.length > 0
              "
            >
              <b>Telefone(s): </b>
              <span
                class="mr-2"
                v-for="tel in item.fornecedor_telefones"
                :key="`tel${tel.telefone}`"
              >
                {{ tel.telefone }}</span
              >
            </div>
          </div>
          <hr />
          <div class="row p-3">
            <div class="col-12 pb-1">
              <!-- <b>{{ item.exames.exames.indexOf(exame) + 1 }}.</b> -->
              {{ item.produto }}
              <!-- <br /><small
              v-if="item.descricao && item.descricao.length > 0"
              class="pl-3"
              >{{ item.descricao }}</small
            > -->
              <hr />
            </div>
          </div>
          <div
            class="row justify-content-end align-content-end"
            style="margin-top: 450px"
          >
            <div class="col-7 border-top">
              <b>{{ item.usuario }}</b>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr />
              <div class="row">
                <div class="col-8" style="font-size: 10px">
                  Para validar basta ler o QRcode ao lado ou abrir o link
                  <a
                    :href="`https://web.saudesocial.net/guia/${baseConv(
                      item.id
                    )}`"
                    target="blank"
                    >https://web.saudesocial.net/guia/{{ baseConv(item.id) }}</a
                  >
                  <div>
                    <b
                      >Esta guia tem validade de 30 dias após sua emissão. Após
                      vencimento não será possível solicitar estorno ou
                      reembolso, nem será elegível para troca ou
                      substituição.</b
                    >
                  </div>
                </div>
                <div class="col-4 text-right">
                  <div class="float-right">
                    <qr-code
                      :text="`https://web.saudesocial.net/guia/${baseConv(
                        item.id
                      )}`"
                      :size="50"
                      error-level="L"
                    >
                    </qr-code>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </print-component>
      </div>
    </b-modal>
    <b-modal id="modal-wpp" hide-footer title="Enviar guia para whatsapp">
      <b-form-group label="Número">
        <vue-simple-suggest
          :suggestion-click="true"
          v-model="wppNumber"
          :list="telefonesDisponiveis"
          :filter-by-query="true"
          ref="select-num-wpp"
          mode="select"
          :controls="{
            selectionUp: [38, 33],
            selectionDown: [40, 34],
            select: [13, 36],
            showList: [40],
            hideList: [27, 35, 13],
          }"
        >
          <input
            class="form-control optional-custom-input"
            v-model="wppNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="off"
            :id="`txtField${Math.random()}`"
            :name="`txtField${Math.random()}`"
          />
        </vue-simple-suggest>
        <b-alert v-if="wppAlert.show" :variant="wppAlert.type">
          {{ wppAlert.message }}
        </b-alert>
        <botoes-salvar-cancelar
          :okText="'Enviar'"
          :okIcon="'fab fa-whatsapp'"
          @salvar="enviarWpp"
          :saving="enviandoWpp"
          @cancelar="() => $bvModal.hide('modal-wpp')"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../Print/PrintComponent.vue";
import moment from "moment";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import WhatsappService from "../../utils/WhatsappService";
import { jsPDF } from "jspdf";
import FilesLib from "../../libs/FilesLib";
import axios from "@/utils/axios.js";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export default {
  components: {
    PrintComponent,
    BotoesSalvarCancelar,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    console.log("item", this.item);

    if (this.item) {
      this.$nextTick(() => {
        this.open();
      });
    }
  },
  data() {
    return {
      printNow: false,
      loading: true,
      data: moment().format("DD/MM/YYYY HH:mm"),
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "warning",
        message: "aguarde...",
      },
    };
  },
  watch: {
    printNow: function () {},
  },
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    async open() {
      this.printNow = true;
      await this.$nextTick();
      this.$bvModal.show("modal-print-gruia");
    },
    baseConv(string) {
      return btoa(string);
    },
    async sendWpp() {
      // console.log("res", res);

      this.$bvModal.show("modal-wpp");
    },
    async download() {
      const doc = new jsPDF();
      let image = await this.$html2canvas(document.getElementById("printDiv"), {
        type: "dataURL",
      });
      doc.addImage(image, "JPEG", 10, 10);
      await doc.save("guia_" + this.item.id + ".pdf");
    },
    async enviarWpp() {
      this.wppAlert.show = true;

      if (this.wppNumber.trim() == "" || this.wppNumber.length < 14) {
        this.wppAlert.type = "danger";
        this.wppAlert.message = "Informe um número válido";
      }
      this.enviandoWpp = true;
      const doc = new jsPDF();
      let image = await this.$html2canvas(document.getElementById("printDiv"), {
        type: "dataURL",
      });
      doc.addImage(image, "JPEG", 10, 10);
      let res = await doc.output("dataurlstring");
      const file = dataURLtoFile(res, "guia.pdf");
      let fileUploaded = await FilesLib.uploadFile(file);
      console.log("pdf", fileUploaded);
      if (
        fileUploaded &&
        fileUploaded.status == 200 &&
        fileUploaded.data.success &&
        fileUploaded.data.file
      ) {
        console.log(
          "file",
          `${axios.defaults.baseURL}downloadFile/${fileUploaded.data.file}?token=${this.$store.state.auth.user.token}`
        );
        let res1 = await WhatsappService.enviarTextoSimples(
          this.wppNumber,
          "Guia gerada com sucesso!\nPara visualizar a guia acesse o link " +
            `https://web.saudesocial.net/guia/${this.baseConv(this.item.id)}`
        );
        let res2 = await WhatsappService.enviarDocumento(
          this.wppNumber,
          `${axios.defaults.baseURL}downloadFile/${fileUploaded.data.file}?token=${this.$store.state.auth.user.token}`,
          ""
        );
        console.log("ressss", res1, res2);
        if (res1 && res2) {
          this.$swal("Enviado com sucesso!", "", "success");
          this.$bvModal.hide("modal-wpp");
        }
      }
      // try {

      // } catch (error) {
      // console.log("ERROR ON SEND WPP", error);
      // }
      this.enviandoWpp = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
