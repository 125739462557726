<template>
  <div class="list-check">
    <div class="item" v-if="withTodos" @click="checkAll()">
      Todos
      <b-icon-check
        class="icon"
        v-if="value.filter((x) => x.checked && x.checked === true).length <= 0"
      />
    </div>
    <div
      class="item"
      v-for="item in value"
      :key="`selection_lista${Math.random(92892)}_${Math.random(92892)}_${
        item[label]
      }`"
      @click="check(item)"
    >
      {{ item[label] }}
      <b-icon-check class="icon" v-if="item.checked === true" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Array,
    label: String,
    multiple: {
      type: Boolean,
      default: true,
    },
    withTodos: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      list: this.value,
    };
  },
  watch: {},
  computed: {},
  methods: {
    checkAll() {
      this.value.forEach((it) => {
        it.checked = false;
      });

      this.$forceUpdate();
      this.$emit("change", this.value);
    },
    check(item) {
      if (!this.multiple) {
        this.value.forEach((it) => {
          it.checked = false;
        });
      }
      if (!item.checked) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      this.$forceUpdate();
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-check {
  padding: 5px;
  .item {
    cursor: pointer;
    text-align: left;
    padding: 5px;
    border: 1px solid #ccc;
    margin-bottom: 3px;
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease;

    &:hover {
      border-color: #aaa;
      padding-left: 10px;
    }
    .icon {
      position: absolute;
      font-size: 30px;
      right: 10px;
      top: 1px;
      opacity: 0.8;
      color: red;
      transition: all 0.3s ease;
    }
  }
  .selected{
      padding-left: 10px;
  }
}
</style>