import axios from "@/utils/axios.js";

const get = async (tipo = "", entidade_id, exame_id, busca ="", pagination = null, alfabeto = "", withoutFidelizacoes = false) => {
  let result;
 // console.log('tipo',tipo);
  //console.log('exame', exame_id);
  let registros_por_pagina = null;
  let pagina_atual = null;
  if (pagination) {
    registros_por_pagina = pagination.registros_por_pagina;
    pagina_atual = pagination.pagina_atual
  }
  await axios
    .get("exames/get", { params: { tipo, entidade_id, exame_id, busca, registros_por_pagina, pagina_atual, alfabeto, withoutFidelizacoes } })
    .then((data) => {
      // console.log('examesss', { tipo, entidade_id }, data)
      result = data.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
   if(!pagination)return result.result;
  return result;
};

const getWithFilters = async ({nome="", id="", tipo = "", searchTipo=""}) => {
  let result 
  //console.log('getWithFilters', nome, id,tipo);
  await axios.get('exames/busca', {params: {nome, id, tipo, searchTipo}}).then(res=>{
    result = res.data
  }).catch(err=>{
    throw err.response.data
  })
  //console.log(result);
  return result
}

const store = async (data) => {
  let result;
  await axios
    .post("exames/store", data)
    .then(function(response) {
      result = response;
    })
    .catch(function(error) {
      throw new Error(error.response.data.error);
    });

  return result;
};

const del = async (id) => {
  let result;
  await axios
    .post("exames/delete", { id })
    .then((data) => {
      result = data;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });

  return result;
};

export default {
  get,
  store,
  del,
  getWithFilters
};
