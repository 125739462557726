<template>
  <div class="item">
    <div class="row pr-3">

   
      <div class="col-9 invert pr-0">
        <!-- <img src="@/assets/img/icones/menu/icons8_user_injured_40px.png" /> -->
           <span ><b v-if="item.at_ordem" class="" style="font-size:30px ! important">{{ item.at_ordem }}°</b></span> {{ item.paciente }}
      </div>
      <div class="col-3 text-right">
        <small>
        {{ item.horario | moment("HH:mm") }}
        </small>
      </div>
      <div class="col-12 status pt-2">
        <small>
          <span v-if="item.id_paciente" class="text-success">
            <i class="fa fa-check" /> Paciente informado
          </span>
          <span v-else class="text-danger"> <i class="fa fa-times" /> Paciente não informado </span>
        </small>
        <small>
          <span v-if="!item.compareceu||item.compareceu === 0" class="text-danger">
            <i class="fa fa-times" /> Não compareceu
          </span>
          <span v-if="item.compareceu === 1" class="text-success">
            <i class="fa fa-check" /> Compareceu
          </span>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/atendimento.scss";
</style>