<template>
  <div id="btBarraFind" ref="btBarraFind" :class="{ open: opened }">
    <i class="fa fa-search" id="btSearch" @click="setBusca()"></i>
    <input
    :disabled="disabled"
      type="text"
      :placeholder="
        minLength
          ? `digite ao menos ${minLength} letras para buscarmos...`
          : 'digite sua busca...'
      "
      id="txtBuscaG"
      ref="txtBuscaG"
      @keyup="change"
      v-model="busca"
      @blur="out()"
      class="txtBuscar"
    />
    <b-spinner small class="loadingBusca" v-if="loading" />
    <i
      class="fa fa-times"
      id="btCloseSearch"
      @click="clear()"
      style="visibility: hidden; font-size: 15px !important"
      :class="{ visible: busca != '' }"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    onFiltrar: Function,
    minLength: [Number, String],
    delay: {
      type: [Number, String],
      default: 500
    },
    opened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busca: "",
      loading: false,
      interval: null,
    };
  },
  methods: {
    change() {
      if (this.busca != "") {
        this.$refs.txtBuscaG.classList.add("opened");
      } else {
        this.$refs.txtBuscaG.classList.remove("opened");
      }
      //      if (!this.loading) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.loading = true;
      let me = () => {
        this.$forceUpdate();
        this.buscar();
      };
      this.interval = setTimeout(() => {
        // console.log(1, me);
        me();
      }, this.delay);
      //    }
    },
    out() {
      if (this.busca.trim() == "") {
        this.$refs.txtBuscaG.classList.remove("opened");
      }
    },
    async buscar() {
      this.$forceUpdate;
      await this.$nextTick();
      // console.log(
      //   "minLength on buscar",
      //   this.minLength,
      //   "length of busca on buscar",
      //   this.busca.trim().length
      // );
      if (this.minLength && this.$props.onFiltrar) {
        if(this.busca.trim().length === 0)   await this.$props.onFiltrar("");

        if (this.busca.trim().length >= this.minLength) {
          await this.$props.onFiltrar(this.busca);
        }
      } else {
        if (this.$props.onFiltrar) {
          await this.$props.onFiltrar(this.busca);
        }
      }

      this.loading = false;
    },
    clear() {
      this.busca = "";
      this.$refs.txtBuscaG.focus();
      this.buscar();
    },
    setBusca() {
      if (!this.$refs.txtBuscaG.classList.contains("opened"))
        this.$refs.txtBuscaG.classList.add("opened");

      // console.log(this.$refs.txtBuscaG.classList.contains("opened"));
      this.$refs.txtBuscaG.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.txtBuscar {
  border: none !important;
}
.loadingBusca {
  position: absolute;
  right: 50px;
  top: 25px;
  opacity: 0.4;
}
.visible {
  visibility: visible;
}
$barraW: 80%;
#txtBuscaG {
  width: 0px;
  transition: all 0.3s ease;
  background: none !important;
  background-color: none !important;
  padding: 10px;
  position: relative !important;
}
#txtBuscaG:focus {
  width: $barraW;
  border-bottom: 1px solid #ccc !important;
  #btBarraFind {
    width: 100%;
    height: 100;
    position: fixed;
    background-color: rgba(255, 255, 255, 10);
    display: block;
    margin: 0 auto;
    float: left;
  }
}

#btBarraFind {
  position: relative;
  margin: 0 auto;
  display: inline-block;
  text-align: right;
  flex-grow: 2;
  padding-right: 20px;
  padding-top: 10px;
}
#btBarraFind i {
  &:hover {
    //@include open();
  }
}

.opened {
  width: $barraW !important;
  border-bottom: 1px solid #ccc !important;
  #btBarraFind {
    width: 100%;
    height: 100;
    position: fixed;
    background-color: rgba(255, 255, 255, 10);
    display: block;
    margin: 0 auto;
    float: left;
  }
}
@mixin bts {
  font-size: 30px !important;
  text-align: center;
  color: #999;
  cursor: pointer;
  transition: all 0.3s ease;
}
#btSearch {
  @include bts;
  &:hover {
    #txtBuscaG {
      width: $barraW;
    }
  }
}
.open {
  #txtBuscaG {
    width: $barraW;
  }
}
#btCloseSearch {
  @include bts;
}

@media only screen and (max-width: 900px) {
}
</style>
