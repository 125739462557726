const axios = require('axios');
const url = "https://api.social.s-zap.com/token/d2b0b31d-1ebc-48d5-b09b-9894789a4129";
// const url = "https://api.z-api.io/instances/3AD53B587A39D06422FA8E7A33DE9B77/token/E5FD8EB4C9A84C951CA7A74B";




const enviarTextoSimples = async (phone, message) => {
    try {
        phone = "55" +phone.replace(/\D/g, "")
        return (await (axios.post(`${url}/send-text`, { phone, message }))).data;
    } catch (error) {
        console.log("ERROR ON SEND MESSAGE WHPP!");
        return false;
    }
}

const enviarImagem = async (phone, image, caption) => {
    try {
        phone = "55"+phone.replace(/\D/g, "")

        return (await (axios.post(`${url}/send-image`, { phone, image, caption }))).data;
    } catch (error) {
        console.log("ERROR ON SEND MESSAGE WHPP!");
        return false;
    }
}

const enviarDocumento = async (phone, document, caption) => {
    try {
        phone = "55" + phone.replace(/\D/g, "")

        return (await (axios.post(`${url}/send-document/pdf`, { phone, document, caption }))).data;
    } catch (error) {
        console.log("ERROR ON SEND MESSAGE WHPP!");
        return false;
    }
}

 

export default {
    enviarTextoSimples,
    enviarImagem,
    enviarDocumento
}