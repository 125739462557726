import axios from "@/utils/axios.js";


const buscarFideliacaoPorCodigo = async (codigo) => {
    try {
        return (await axios.post('/entidades/fidelizacao/buscarPorCodigo', { codigo })).data;
    } catch (err) {
        console.log("erro ao buscar fidelizacao por codigo", err);
        return null
    }

}
export default {
    buscarFideliacaoPorCodigo
}