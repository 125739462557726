<template>
  <div>
    <barra-crud
      title="Configurações do Whatsapp"
      :botaoNovo="true"
      :onNovo="
        () => {
          openForm(null);
        }
      "
    />
    <hr />
    <b-table
      :items="list"
      :fields="[
        { key: 'id', label: 'Código' },
        { key: 'name', label: 'Nome' },
        { key: 'type', label: 'Tipo' },
        { key: 'usuario', label: 'Usuário' },
        { key: 'opc', label: '' },
      ]"
    >
      <template #cell(opc)="row">
        <b-btn variant="warning">
          <i class="fa fa-edit" @click="openForm(row.item)"></i>
          Editar
        </b-btn>
        <b-btn variant="danger">
          <i class="fa fa-trash" @click="edit(row.item)"></i>
          Excluir
        </b-btn>
      </template>
    </b-table>

    <b-modal id="modal-add-edit-config-wpp" hide-footer size="lg">
      <div v-if="objEdit">
        <div class="row">
          <div class="col-12 col-sm-4">
            <label>Código</label>
            <b-input disabled v-model="objEdit.id"></b-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label>Nome</label>
            <b-input v-model="objEdit.name"></b-input>
          </div>
          <div class="col-12 col-sm-6">
            <label>Tipo</label>
            <b-select
              v-model="objEdit.type"
              :options="['Agenda', 'Telemarketing']"
            ></b-select>
          </div>
          <div class="col-12 col-sm-12">
            <label>Mensagem</label>
            <b-textarea v-model="objEdit.text"></b-textarea>
          </div>
          <div class="col-12 col-sm-12">
            <label>Respostas</label>
            <b-table
              :items="objEdit.answers"
              :fields="[
                { key: 'numero', label: 'Número' },
                { key: 'resposta', label: 'Resposta' },
                { key: 'opc', label: '' },
              ]"
            >
              <template #cell(numero)="row">
                <b-input type="number" v-model="row.item.numero"></b-input>
              </template>
              <template #cell(resposta)="row">
                <b-input type="text" v-model="row.item.resposta"></b-input>
              </template>
              <template #cell(opc)="row">
                <b-btn variant="none" @click="delAnswer(row.item)">
                  <i class="fa fa-trash"></i>
                </b-btn>
              </template>
            </b-table>
            <div class="text-right">
              <b-btn variant="primary" @click="addAnswer">
                <i class="fa fa-plus mr-1"></i> Adicionar Resposta
              </b-btn>
            </div>
          </div>
        </div>
        <hr />
        <div class="text-right">
          <b-btn variant="primary" @click="save"
            ><i class="fa fa-save mr-1"></i>Salvar</b-btn
          >
          <b-btn @click="$bvModal.hide('modal-add-edit-config-wpp')">Cancelar</b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../common/BarraCrud.vue";
import {
  buscarWhatsappConfigs,
  storeWhatsappConfigs,
} from "../../libs/WhatsappLib";

export default {
  components: { BarraCrud },
  props: {},

  mounted() {
    this.load();
  },
  data() {
    return {
      list: [],
      objEdit: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async save() {
      let errs = [];
      if (!this.objEdit.name || this.objEdit.name.trim() == "") {
        errs.push("Informe um nome!");
      }
      if (!this.objEdit.type || this.objEdit.type.trim() == "") {
        errs.push("Informe um tipo!");
      }
      if (!this.objEdit.text || this.objEdit.text.trim() == "") {
        errs.push("Informe uma mensagem!");
      }

      if (errs && errs.length > 0) {
        this.$swal({
          title: "Atenção",
          icon: "error",
          html:
            "Os campos abaixo precisam de atenção: <hr/>" + errs.join("<hr/>"),
        });
      } else {
        let res = await storeWhatsappConfigs(this.objEdit);
        console.log("res store", res);
        if (res && res.success) {
          this.load();
          this.$swal("Salvo com sucesso!", "", "success");

          this.$bvModal.hide("modal-add-edit-config-wpp");
        } else {
          this.$swal("Erro ao salvar", "", "error");
        }
      }
    },
    openForm(objEdit) {
      console.log("clickobjedit", objEdit);
      this.objEdit = objEdit;
      if (!this.objEdit) {
        this.objEdit = {
          id: 0,
          name: "",
          type: "Agenda",
          text: "",
          answers: [
            {
              numero: 1,
              resposta: "",
            },
          ],
        };
      }
      console.log("open", this.objEdit);

      setTimeout(() => {
        this.$bvModal.show("modal-add-edit-config-wpp");
      }, 300);
    },
    addAnswer() {
      if (!this.objEdit.answers) {
        this.objEdit.answers = [];
      }
      this.objEdit.answers.push({
        numero:
          this.objEdit.answers && this.objEdit.answers.length > 0
            ? this.objEdit.answers[this.objEdit.answers.length - 1].numero + 1
            : 1,
        resposta: "",
      });
    },
    delAnswer(item) {
      this.objEdit.answers = this.objEdit.answers.filter((x) => x != item);
    },
    async load() {
      this.list = [];

      let res = await buscarWhatsappConfigs();
      if (res && res.success) {
        this.list = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
