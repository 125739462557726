import axios from "@/utils/axios.js";

const registraControle = async (data) => {
  let result = false;
  try {
    result = (await axios.post("/caixas/registraControle", data)).data;
  } catch {
    result = false;
  }

  return result;
};

const buscarCaixaControle = async (empresa_id, data, caixa_num, options) => {
  try {
    let obj = {
      empresa_id,
      data,
      options,
    };
    if (caixa_num && caixa_num > 0) {
      obj.caixa_num = caixa_num;
    }

    return (await axios.post("/caixas/buscarCaixaControle", obj)).data;
  } catch (er) {
    console.log("erro ao buscar controle de cx", er);
    return [];
  }
};

// const buscarRetirada = async (data) => {
//   try {
//     let result = await axios.post("/caixas/retiradas", data);
//     console.log(result);
//     return result;
//   } catch (error) {
//     if (error instanceof Error) {
//       throw error;
//     }
//     console.log("Erro ao gerar retirada.", error);
//   }
// };
const buscarRetirada = async (id) => {
  try {
    let result = await axios.get("/caixas/retiradas", { params: { id } });
    console.log(result);
    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    console.log("Erro ao gerar retirada.", error);
  }
};

const gerarRetirada = async (data) => {
  try {
    let result = await axios.post("/caixas/retiradas", data);
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    console.log("Erro ao gerar retirada.", error);
  }
};

const cancelarRetirada = async (id, data) => {
  try {
    let result = await axios.post(`/caixas/retiradas/cancelar/${id}`, data);
    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw error.response;
    }
    console.log("Erro ao gerar retirada.", error);
  }
};

const buscarSangria = async (id) => {
  try {
    let result = await axios.get("/caixas/sangrias", { params: { id } });
    console.log(result);
    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    console.log("Erro ao buscar sangria.", error);
  }
};

const gerarSangria = async (data) => {
  try {
    let result = await axios.post("/caixas/sangrias", data);
    console.log(result);
    return result;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    console.log("Erro ao gerar sangria.", error);
  }
};

const cancelarSangria = async (id, data) => {
  try {
    let result = await axios.post(`/caixas/sangrias/cancelar/${id}`, data);
    return result.data;
  } catch (error) {
    if (error instanceof Error) {
      throw error.response;
    }
    console.log("Erro ao cancelar sangria.", error);
  }
};

const listagemCaixa = async (caixa_controle) => {
  try {
    return (await axios.post("/caixas/listagemCaixa", { caixa_controle })).data
      .data;
  } catch (err) {
    console.log("Erro na listagem do caixa", err);
    return null;
  }
};

const fechamentoCaixa = async (caixa_controle) => {
  try {
    return (await axios.post("/caixas/fechamento", { caixa_controle })).data
      .data;
  } catch (err) {
    console.log("Erro no fechamento do caixa", err);
    return null;
  }
};

const relatorioLancamentos = async (caixa_controle) => {
  try {
    return (
      await axios.post("/caixas/relatorio/lancamentos", { caixa_controle })
    ).data.data;
  } catch (err) {
    console.log("Erro no fechamento do caixa", err);
    return null;
  }
};

const buscarAcertos = async (caixa_controle) => {
  try {
    return (await axios.post("/caixas/acertos/buscar", { caixa_controle })).data
      .data;
  } catch (err) {
    console.log("Erro no fechamento do caixa", err);
    return null;
  }
};

const gerarAcertos = async (obj) => {
  try {
    return (await axios.post("/caixas/acertos/gerar", obj)).data;
  } catch (Err) {
    console.log("ERROR ON GERAR ACERTOS", Err, obj);
  }
};

const createPix = async (
  devedor_nome,
  devedor_cpf,
  valor,
  observacao,
  externalRef
) => {
  try {
    console.log("sending", {
      devedor_nome,
      devedor_cpf,
      valor,
      observacao,
      externalRef,
    });
    return (
      await axios.post("/caixas/pix/create", {
        devedor_nome,
        devedor_cpf,
        valor,
        observacao,
        externalRef,
      })
    ).data;
  } catch (Err) {
    console.log("ERROR ON GERAR PIX", Err, {
      devedor_nome,
      devedor_cpf,
      valor,
      observacao,
    });
  }
};

const consultarPix = async (id) => {
  try {
    // const source = CancelToken.source();
    // const timeout = setTimeout(() => {
    //   source.cancel();
    //   // Timeout Logic
    // }, 10000);
    return (
      await axios.post(
        "/caixas/pix/refresh",
        {
          id,
        },
        { timeout: 20000 }
      )
    ).data;
  } catch (Err) {
    console.log("ERROR ON REFRESH PIX", Err, id);
  }
};

const cancelarPix = async (id, valor) => {
  try {
    return (
      await axios.post("/caixas/pix/cancel", {
        id,
        valor,
      })
    ).data;
  } catch (Err) {
    console.log("ERROR ON CANCEL PIX", Err, id);
  }
};

const listagemPix = async (filtros) => {
  try {
    console.log("filtros", filtros);
    return (await axios.post("/caixas/pix/list", filtros)).data;
  } catch (Err) {
    console.log("ERROR ON LISTAGEM PIX", Err, filtros);
  }
};

const verificarReceitasNaoLancadas = async (filtros) => {
  try {
    console.log("filtros", filtros);
    return (await axios.post("/caixas/verificarReceitasLancadas", filtros))
      .data;
  } catch (Err) {
    console.log("ERROR ON verificarReceitasLancadas", Err, filtros);
  }
};

const verificarAutDoc = async (aut, doc) => {
  try {
    return (await axios.post("/caixas/verificarAutDoc", { aut, doc })).data;
  } catch (Err) {
    console.log("ERROR ON verificarAutDoc", Err, { aut, doc });
  }
};

export default {
  registraControle,
  buscarCaixaControle,
  buscarRetirada,
  gerarRetirada,
  cancelarRetirada,
  buscarSangria,
  gerarSangria,
  cancelarSangria,
  listagemCaixa,
  fechamentoCaixa,
  relatorioLancamentos,
  buscarAcertos,
  gerarAcertos,
  createPix,
  consultarPix,
  cancelarPix,
  listagemPix,
  verificarReceitasNaoLancadas,
  verificarAutDoc,
};
