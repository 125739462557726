<template>
  <div>
    <div v-if="!itens || itens.length <= 0" class="text-center">
      Nenhum item adicionado ainda.
    </div>
    <div v-else>
      <venda-item-card
        v-for="item in itens"
        :item="item"
        :key="`item_${itens.indexOf(item)}`"
        :num="itens.indexOf(item) + 1"
        @editar="editar"
        @remover="remover"
        :hasFidel="hasFidel"
        @open-descontos="openDescontos(item)"
        :convenio="convenio"
      />
    </div>

    <b-modal id="descontos-por-forma" size="lg">
      <template #modal-title >
        <div class="text-center justify-content-center d-flex">
          <h4>
            Descontos Personalizados
          </h4>
        </div>
      </template>
      <div
        v-for="(desconto,i) of descontosPorForma"
        :key="`desconto_${descontosPorForma.indexOf(desconto)}`"
        class="border rounded p-2 mt-2 shadow"
      >
        <span class="text-muted" style="font-size: 1.3rem;">{{i +1}}.</span> <span v-html="formataDesconto(desconto)"></span>
        <div class="text-muted d-flex justify-content-end">
          <b>{{desconto.acumulativo===1||desconto.acumulativo === true?'É acumulativo':"Não é acumulativo"}}</b>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VendaItemCard from "./VendaItemCard.vue";
export default {
  components: {
    VendaItemCard,
  },
  props: {
    itens: {
      type: Array,
      default: null,
    },
    hasFidel: {
      type: Boolean,
      default: false,
    },
    convenio:Object,
  },
  mounted() {},
  data() {
    return {
      descontosPorForma: [],
      camposDesconto: [
        {
          key: "formaDePagamento",
          label: "Forma de Pagamento",
          class: "text-left text-nowrap",
        },
        { key: "valor_venda", label: "Valor Venda", class: "text-center" },

        { key: "empresa", label: "Empresa", class: "text-left text-nowrap" },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    editar(item) {
      this.$emit("editar", item);
    },
    remover(item) {
      this.$emit("remover", item);
    },
    openDescontos(item) {
      this.descontosPorForma = Object.assign(
        [],
        item?.exameSelecionado?.configuracoes?.personalizarPorForma
      );

      if (
        Array.isArray(this.descontosPorForma) &&
        this.descontosPorForma?.length > 0
      ) {
        this.descontosPorForma = this.descontosPorForma.map((d) => ({
          ...d,
          desconto: (
            parseFloat(
              item?.exameSelecionado?.valor_venda
                ? item?.exameSelecionado?.valor_venda
                : 0
            ) - parseFloat(d.valor_venda ? d.valor_venda : 0)
          ).toFixed(2),
        }));
        this.$bvModal.show("descontos-por-forma");
      } else {
        return;
      }
    },
    formataDesconto(desconto) {
      return `Se a <b>forma de pagamento for ${desconto.formaDePagamento}</b>${
        desconto.empresa.id > -1
          ? ` e a empresa conveniada for <b>${desconto.empresa.razao_social}</b>, `
          : ", "
      } o valor desse produto será de <b  style="color: green;">R$ ${
        desconto.valor_venda
      }</b>, um desconto de <span style="color: red;">R$${desconto.desconto}</span>`;
    },
    closeDescontos() {
      this.descontosPorForma = [];
      this.$bvModal.hide("descontos-por-forma");
    },
  },
};
</script>

<style lang="scss" scoped></style>
