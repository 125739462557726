<template>
  <div>
    <div class="row justify-content-start">
      <Buscar opened :onFiltrar="onFiltrar" />
    </div>
    <div class="row">
      <div class="col d-flex flex-wrap justify-content-center">
        <FilterAlphabet :disabled="loading" v-model="filters.alfabeto" />
      </div>
    </div>
    <b-form-checkbox
      class="text-right"
      switch
      v-model="showValorCusto"
      v-if="user.isAdmin"
    >
      Mostrar Valor de Custo
    </b-form-checkbox>
    <b-tabs :value="tabs.indexOf(filters.tipo)">
      <b-tab
        :title="tab"
        @click="setTipo(tab)"
        v-for="(tab, i) in tabs"
        :key="`${tab}-${i}`"
      >
      </b-tab>
    </b-tabs>

    <b-table
      :busy="loading"
      show-empty
      hover
      selectable
      :select-mode="selectMode"
      small
      outlined
      :items="lista"
      responsive
      :fields="campos"
      class="shadow my-2"
      style="font-size: 15px;"
      ref="tableExames"
      @row-selected="onRowSelected"
      @row-dblclicked="onSelecionar"
    >
      <template #empty>
        <div class="text-center text-muted">
          Nada aqui... <i class="fas fa-frown"></i>
        </div>
      </template>

      <template #table-busy>
        <div class="d-flex justify-content-center">
          <h4><b-icon animation="cylon" icon="search"></b-icon> Buscando...</h4>
        </div>
      </template>

      <template #cell(imagem)="{item}">
        <b-avatar class="mr-2" :src="downloadIMG(item.imagem)" />
      </template>

      <template #cell(valor_custo)="{item}">
        {{ item.valor_custo | currencyWithMask }}
      </template>

      <template #cell(valor_venda)="{item}">
        {{ item.valor_venda | currencyWithMask }}
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="paginacao.total_registros"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
    <div class="row border-top pt-3 mt-4" v-if="showButtons">
      <div class="col d-flex justify-content-end">
        <b-btn
          :disabled="loading"
          @click="onSelecionar"
          variant="primary"
          class="mr-3"
        >
          <div>
            <i v-if="!loading" class="fas fa-plus"></i>
            <b-spinner v-else small></b-spinner> Selecionar
          </div>
        </b-btn>
        <b-btn @click="$emit('close')" variant="secondary">
          <i class="fas fa-ban"></i> Fechar
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Buscar from "./Buscar.vue";
import ExamesLib from "../../libs/ExamesLib";
import FilterAlphabet from "./FilterAlphabet.vue";
import FilesLib from "../../libs/FilesLib";
export default {
  components: {
    Buscar,
    FilterAlphabet,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    entidadeId: { type: [Number, String], default: "" },

    selectMode: {
      type: String,
      default: "single",
    },
    tabs: {
      type: Array,
      default: () => ["Exame", "Consulta", "Produto", "Serviço"],
    },
  },
  data() {
    return {
      campos: [
        { key: "imagem", label: "", class: "text-left" },
        { key: "nome", class: "text-center" },
        { key: "especialidade", class: "text-center" },
        { key: "descricao", label: "Descrição", class: "text-center" },
        {
          key: "valor_venda",
          label: "Valor Venda",
          class: "text-center px-2",
          sortable: true,
        },
      ],
      showValorCusto: false,
      paginacao: {
        total_paginas: 0,
        pagina_atual: 1,
        total_registros: 0,
        registros_por_pagina: 10,
      },
      lista: [],
      filters: {
        busca: "",
        tipo: this.tabs[0],
        alfabeto: "",
      },
      selected: {},
      loading: false,
    };
  },
  methods: {
    setTipo(tipo) {
      this.filters.tipo = tipo;
    },
    downloadIMG(url) {
      return FilesLib.downlaodFile(url, this.user.token);
    },
    onSelecionar(item = null) {
      if (item.id) {
        this.selected = Object.assign(this.selected, item);
      }
      if (!this.selected.id) {
        this.showToast(
          "error",
          `Nenhum ${this.filters.tipo} selecionado.`,
          3500
        );
        return;
      }
      this.$emit("input", this.selected);
      this.$emit("close");
    },
    onFiltrar(filtro) {
      this.filters.busca = filtro;
    },
    onRowSelected(items) {
      if (items.length === 0) return;
      this.selected = items[0];
    },
    async carregar() {
      this.loading = true;
      const result = await ExamesLib.get(
        this.filters.tipo,
        this.entidadeId,
        null,
        this.filters.busca,
        this.paginacao,
        this.filters.alfabeto,
        true
      );
      this.lista = result.result;
      this.paginacao = Object.assign(this.paginacao, {
        ...result.pagination,
        pagina_atual: this.paginacao.pagina_atual,
      });
      this.loading = false;
    },
  },
  mounted() {
    this.carregar();
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        this.carregar();
      },
    },
    showValorCusto(vl) {
      let idxValorCusto = this.campos.findIndex((c) => c.key === "valor_custo");
      if (vl) {
        if (this.user.isAdmin && idxValorCusto === -1) {
          this.campos.push({
            key: "valor_custo",
            label: "Valor Custo",
            class: "text-center px-2",
            sortable: true,
          });
        } else if (idxValorCusto > -1) {
          this.campos.splice(idxValorCusto, 1);
        }
      } else {
        this.campos.splice(idxValorCusto, 1);
      }
    },
    "filters.busca": {
      handler(newV, oldV) {
        if (this.filters.alfabeto && newV.trim().length > oldV.trim().length) {
          this.filters.alfabeto = "";
        }
        this.carregar();
      },
    },
    "paginacao.pagina_atual": {
      async handler() {
        this.carregar();
      },
    },
  },
};
</script>

<style></style>
