<template>
  <div>
    <b-card
      class="mt-4"
      :title="item.exameSelecionado.nome"
      v-if="item.exameSelecionado"
    >
      <hr />
      <div class="row">
        <div class="col-4">
          <label>Validade</label>
          {{ validade | moment("DD/MM/YYYY") }}
        </div>
        <div class="col-4">
          <label>Dependentes</label>
          {{ qtdDependentesInformados }}/{{
            item.exameSelecionado.fidelizcao.qtd_dependentes
          }}
        </div>
        <div class="col-4 mb-4">
          <label>Código Cartão</label>
          <b-input
            v-model="codigo"
            v-mask="['##.#.####','##.#.#####']"
            @change="buscarPorCodigo"
            :state="codigoLivre"
          />
          <b-form-invalid-feedback
            >Código inválido ou já utilizado
            anteriormente</b-form-invalid-feedback
          >
        </div>
      </div>
      <table class="table table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Parentesco</th>
            <th>Adicional</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="depend in dependentes" :key="dependentes.indexOf(depend)">
            <td>
              <b-input type="text" v-model="depend.Nome" />
            </td>

            <td>
              <b-input
                type="text"
                v-model="depend.CPF"
                v-mask="['###.###.###-##']"
                @change="consultaPorCPF(depend)"
                @blur="consultaPorCPF(depend)"
                :state="depend.isValid"
              />
              <b-form-invalid-feedback>
                CPF Inválido ou Inexistente na base (será cadastrado)
              </b-form-invalid-feedback>
              <small v-if="depend.exists === false" class="text-warning"
                ><b-icon-info-circle /> CPF não encontado! Será
                cadastrado!</small
              >
              <small v-if="depend.equalToTitular === true" class="text-danger"
                ><b-icon-info-circle /> O titular da fidelização não pode ser um
                dependente.</small
              >
              <small v-if="depend.exists === true" class="text-success"
                ><b-icon-info-circle /> CPF encontado!
                {{ depend.entidadeNome }}</small
              >
            </td>
            <td>
              <b-select
                :options="[
                  'Pai/Mãe',
                  'Conjuge',
                  'Filho/Filha',
                  'Avô/Avó',
                  'Tio/Tia',
                  'Parente',
                ]"
                v-model="depend.Parentesco"
              />
            </td>
            <td>
              <div class="text-center">
                <b-checkbox v-model="depend.adicional" :value="1" disabled />
                <b-btn
                  size="sm"
                  variant="danger"
                  v-if="depend.adicional && depend.adicional === 1"
                  @click="removerAdicional(depend)"
                  ><b-icon-trash /> Remover</b-btn
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <b-table
        ref="tableDependentes"
        :fields="['Nome', 'CPF', 'Adicional']"
        :items="dependentes"
      >
        <template #cell(Nome)="row">
          <b-input type="text" v-model="row.item.Nome" />
        </template>
        <template #cell(CPF)="row">
          <b-input
            type="text"
            v-model="row.item.CPF"
            v-mask="['###.###.###-##']"
          />
        </template>
        <template #cell(Adicional)="row">
          <div class="text-center">
            <b-checkbox v-model="row.item.adicional" :value="1" disabled />
            <b-btn
              size="sm"
              variant="danger"
              v-if="row.item.adicional && row.item.adicional === 1"
              @click="removerAdicional(row.item)"
              ><b-icon-trash /> Remover</b-btn
            >
          </div>
        </template>
      </b-table> -->
      <div
        class="float-right"
        v-if="
          item &&
            item.exameSelecionado &&
            item.exameSelecionado.fidelizcao.dependente_adicional === 1
        "
      >
        <b-btn variant="primary" @click="addAdicional"
          ><b-icon-plus-circle-fill /> Dependente Adicional
          {{
            item.exameSelecionado.fidelizcao.valor_adicional | currencyWithMask
          }}</b-btn
        >
      </div>
    </b-card>
    <!-- {{item}} -->
  </div>
</template>

<script>
import moment from "moment";
import EntidadeFidelizacaoLib from "../../../libs/EntidadeFidelizacaoLib";
import EntidadeLib from "../../../libs/EntidadesLib";
import Validadores from "../../../helpers/Validadores";
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
    },
    titular: {
      type: Object,
    },
    value: Object,
  },
  async mounted() {
    //console.log(this.item);
    if (this.value) this.carregarValue();
    else await this.montarEstrut();
  },
  updated() {
    // this.montarEstrut();
  },
  beforeDestroy() {
    this.emitInput();
    // console.log("DESTROY");
  },
  data() {
    return {
      dependentes: [],
      validade: moment().format("DD/MM/YYYY"),
      codigo: "",
      codigoLivre: null,
      loading: false,
    };
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.montarEstrut();
      },
    },
    dependentes: {
      deep: true,
      async handler() {
        await this.emitInput();
      },
    },
    codigo: async function() {
      await this.emitInput();
    },
    codigoLivre: async function() {
      // console.log("codigoLivre", this.codigoLivre);
      await this.emitInput();
    },
    value: {
      deep: true,
      handler() {
        this.carregarValue();
      },
    },
  },
  computed: {
    qtdDependentesInformados: function() {
      return this.dependentes.filter((x) => x.Nome && x.Nome.trim() != "")
        .length;
    },
    valor: function() {
      let vl = this.item.exameSelecionado.valor_venda;
      let adicionais = this.dependentes.filter(
        (x) => x.adicional && x.adicional == 1
      ).length;
      //  console.log('ADICIONAIS', adicionais);
      //  console.log('VALOR ADICIONAL',  this.item.exameSelecionado.fidelizcao.valor_adicional);
      //  console.log('VALOR TOTAL ANTES', vl);
      if (adicionais > 0) {
        vl +=
          this.item.exameSelecionado.fidelizcao.valor_adicional * adicionais;
      }
      //  console.log('VALOR TOTAL DEPOIS', vl);
      return vl;
    },
  },
  methods: {
    async consultaPorCPF(depend) {
      depend.isValid = null;
      depend.exists = false;
      depend.equalToTitular = false;
      depend.entidade_id = null;
      depend.entidadeNome = null;

      if (
        depend.CPF.length < 13 ||
        !Validadores.string.validarCPF(depend.CPF)
      ) {
        depend.isValid = false;
      } else {
        if (this.titular ? this.titular.cpf !== depend.CPF : true) {
          depend.isValid = true;
          let res = await EntidadeLib.buscaPorNome(depend.CPF);
          if (res) {
            if (res.length <= 0) {
              depend.exists = false;
            } else {
              depend.exists = true;
              depend.entidade_id = res[0].id;
              depend.entidadeNome = res[0].razao_social;
              depend.Nome = res[0].razao_social;
            }
          }
        } else {
          depend.exists = null;
          depend.equalToTitular = true;
        }
      }
      this.$forceUpdate();
    },
    carregarValue() {
      this.loading = true;
      if (this.value) {
        this.dependentes = this.value.dependentes;
        this.codigo = this.value.codigo;
        this.codigoLivre = this.value.codigoLivre;
        this.validade = moment(this.value.validade).format("YYYY-MM-DD");
        //this.valor_venda = this.value.valor;
        this.$forceUpdate();
        // console.log("this.", this.value);
      }
      this.loading = false;
    },
    async emitInput() {
      // console.log('Call Emit Input', this.dependentes);
      // if (this.dependentes.some((depend) => depend.equalToTitular)) {
      //  await this.$swal({
      //     title: "Atenção",
      //     html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>O titular não pode ser um dependente.</span>`,
      //     icon: "error",
      //   }).then(()=>{return})
      //   return;

      // }
      if (!this.loading) {
        //console.log('EMITIU O VALOR: ', this.valor);
        for(let p of this.dependentes){
          p.Nome = p.Nome.toUpperCase();
        }
        this.$emit("input", {
          dependentes: this.dependentes,
          valor: this.valor,
          codigo: this.codigo,
          codigoLivre: this.codigoLivre,
          validade: this.validade,
        });
      }
    },
    montarEstrut() {
      // console.log('chamou montarEstrut()');
      this.loading = true;
      // console.log(1111111, this.item);
      if (
        this.item &&
        this.item.exameSelecionado &&
        this.item.exameSelecionado.fidelizcao
      ) {
        this.validade = moment()
          .add("M", this.item.exameSelecionado.fidelizcao.prazo)
          .format("YYYY-MM-DD");

        //this.dependentes = [];
        let depends = [];
        this.$forceUpdate();
        this.$nextTick();
        let v = this.item.exameSelecionado.fidelizcao.qtd_dependentes;
        for (let i = 0; i < v; i++) {
          depends.push({ Nome: "", CPF: "", Parentesco: "" });
        }
        this.$forceUpdate();
        this.$nextTick();

        this.dependentes = depends;
        // console.log("========", this.dependentes);

        //this.$refs.tableDependentes.refresh();
        //this.$root.$emit("bv::refresh::table", "tableDependentes");
      }
      this.loading = false;
    },
    addAdicional() {
      this.dependentes.push({ Nome: "", CPF: "", adicional: 1 });
    },
    removerAdicional(item) {
      this.dependentes = this.dependentes.filter((x) => x != item);
    },
    async buscarPorCodigo() {
      if (this.codigo.length < 9) {
        this.codigoLivre = false;
      } else {
        let fidelizacao = await EntidadeFidelizacaoLib.buscarFideliacaoPorCodigo(
          this.codigo
        );
        this.codigoLivre = fidelizacao.data.length <= 0;
      }
      // console.log(this.codigoLivre, fidelizacao);
    },
  },
};
</script>

<style lang="scss" scoped></style>
