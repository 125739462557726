import axios from "../utils/axios";

const buscarWhatsappConfigs = async () => {
  try {
    //console.log('filters',filters)
    return (await axios.get("/whatsapp/configurations")).data;
  } catch (err) {
    console.log("ERRO AO BUSAR wppCOnfig", err);
    return null;
  }
};

const storeWhatsappConfigs = async (data) => {
  try {
    //console.log('filters',filters)
    return (await axios.post("/whatsapp/configurations", data)).data;
  } catch (err) {
    console.log("ERRO AO BUSAR wppCOnfig", err);
    return null;
  }
};

const buscarWhatsappSender = async (reference_id, type) => {
  try {
    //console.log('filters',filters)
    return (await axios.post("/whatsapp/getSender", { reference_id, type})).data;
  } catch (err) {
    console.log("ERRO AO BUSAR wppCOnfig", err);
    return null;
  }
};



export {
  buscarWhatsappConfigs,
  storeWhatsappConfigs,
  buscarWhatsappSender
}

