<template>
  <div>
    <b-modal
      id="modal-print-oftalmo"
      v-if="printNow"
      hide-footer
      hide-header
      no-fade
      @hide="
        printNow = false;
        $emit('close');
      "
    >
      <small>clique fora para fechar</small>
      <print-component
        :printNow="printNow"
        @done="$emit('done')"
        :titulo="`Receita Oftalmologista `"
        :filename="`Receita Oftalmologista ${item.medicoNome}`"
      >
        <div class="d-flex flex-column p-5 m-3" style="height: 100vh">
          <div class="row  border-bottom">
            <div class="col  p-2 my-2 rounded">
              <h4 class="text-center">Dados do paciente</h4>
              <div class="row">
                <div class="col-6"><b>Nome: </b>{{ item.paciente.nome }}</div>
                <div class="col-6"><b>CPF: </b>{{ item.paciente.cpf }}</div>
                <div class="col-6">
                  <b>Data Nascimento: </b
                  >{{ item.paciente.dt_nascimento | moment("DD/MM/YYYY") }}
                </div>
              </div>
            </div>
          </div>
          <div class="row r">
            <div class="col  p-2 my-2 rounded">
              <h4 class="text-center">Dados da Receita</h4>
              <div class="row">
                <div class="col-6"><b>Código:</b> {{ item.codigo }}</div>
                <div class="col-6">
                  <b>Data Receita:</b>
                  {{ item.created_at | moment("DD/MM/YYYY HH:mm") }}
                </div>

                <div class="col-6 mt-1">
                  <b>Médico:</b>
                  {{ item.medicoNome }}
                </div>

                <div class="col-12 mt-1">
                  <b>Especialidade:</b>
                  {{ item.especialidade }}
                </div>
              </div>

              <table
                class="table table-sm table-striped  text-center table-sm mt-3"
                style="font-size: 15px; border-radius: 10px;border: 1px solid #494949; width: 350px; margin: 0 auto;"
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Esférico</th>
                    <th>Cilindrico</th>
                    <th>Eixo</th>
                  </tr>
                </thead>
                <tbody v-if="item.conteudo.oftalmo">
                  <tr>
                    <th>OD</th>
                    <td>{{ item.conteudo.oftalmo.od.esferico }}</td>
                    <td>{{ item.conteudo.oftalmo.od.cilindrico }}</td>
                    <td>{{ item.conteudo.oftalmo.od.eixo }}</td>
                  </tr>
                  <tr>
                    <th>OE</th>
                    <td>{{ item.conteudo.oftalmo.oe.esferico }}</td>
                    <td>{{ item.conteudo.oftalmo.oe.cilindrico }}</td>
                    <td>{{ item.conteudo.oftalmo.oe.eixo }}</td>
                  </tr>
                </tbody>

                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="text-center">Adição</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    {{ item.conteudo.oftalmo.adicao }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row" v-if="item.obs">
            <div class="col-12 ">
              <b>Observações</b>
              <p >
                {{ item.obs }}
              </p>
            </div>
          </div>

          <div
            class=""
            style=" 
         display: flex;
          height: 100%;
         justify-content: end;
          align-items: end;"
          >
            <div class="border-top">
              <b>{{ item.medicoNome }}</b>
              <small>[ <b> CRM: </b> {{ item.medicoCRM.join(", ") }}]</small>
            </div>
          </div>
        </div>
      </print-component>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../../../../Print/PrintComponent.vue";
import moment from "moment";

import LogsLib from "../../../../../libs/LogsLib";
import PacienteReceitasLib from "../../../../../libs/PacienteReceitasLib";

export default {
  components: {
    PrintComponent,
  },
  props: {
    infos: {
      type: Object,
      default: null,
    },
    id: [Number, String],
  },
  mounted() {
    if (this.id) {
      this.carregaInfos();
    } else if (this.infos) {
      this.item = Object.assign(this.item, this.infos);
      this.$nextTick(() => {
        this.open();
      });
    }
  },
  data() {
    return {
      printNow: false,
      loading: true,
      data: moment().format("DD/MM/YYYY"),
      item: {},
    };
  },
  watch: {
    printNow: function() {},
  },
  computed: {},
  methods: {
    async open() {
      console.log(this.item);

      this.printNow = true;
      await this.$nextTick();
      LogsLib.impressoes.store({
        id: this.item?.id,
        tabela: "paciente_receitas",
      });
      this.$bvModal.show("modal-print-oftalmo");
    },
    async carregaInfos() {
      this.item = (await PacienteReceitasLib.get({ id: this.id }))[0];
      await this.$nextTick();
      await this.open();
    },
  },
};
</script>

<style lang="scss" scoped></style>
