<template>
  <div>
    <div class="row">
      <div class="col-5" v-if="!hideFiltros">
        <b-form-group label="Periodo">
          <div class="row">
            <div class="col-6 pr-1">
              <b-input type="date" v-model="filtros.dt_i" />
            </div>
            <div class="col-6 pl-1">
              <b-input type="date" v-model="filtros.dt_f" />
            </div>
          </div>
        </b-form-group>
      </div>
      <div class="col-3">
        <SelectEmpresaClinica
          :clinicaInicialID="clinica"
          :required="false"
          @empresa="selectEmpresa"
          :autoBlock="false"
        />
      </div>
      <div class="col-3">
        <b-form-group label="Situação">
          <b-select
            v-model="filtros.situacao"
            :options="[
              'Todos',
              'Ativos',
              'Concluídos',
              'Pago e não vinculado',
              'Expirados',
            ]"
          />
        </b-form-group>
      </div>

      <div class="col-12" v-if="!loading && lista.length > 0">
        <b-form-group label="Filtrar Resultados">
          <b-input
            v-model="filtro"
            placeholder="localize por ID, Cliente ou Valor..."
          />
        </b-form-group>
      </div>

      <div class="col-12" v-if="selectable">
        <small>
          Clique no pix para selecionar
        </small>
      </div>
    </div>
    <hr />
    <div class="w-100 text-right">
      <b-btn type="button" @click="print"><b-icon-printer /> Imprimir</b-btn>
    </div>
    <div id="printDiv">
      <print-component
        :titulo="`Lançamentos PIX`"
        :filtros="
          `Data: ${this.filtros.dt_i_desc} á ${
            this.filtros.dt_f_desc
          } | Clínica: ${
            !this.filtros.clinica_desc ? 'Todos' : this.filtros.clinica_desc
          } | Situação: ${filtros.situacao} ${
            filtro && filtro != '' ? ' | Filtro: ' + filtro : ''
          }`
        "
        :show="true"
        style="max-width:700px; margin:0 auto"
      >
        <div v-if="loading" class="text-center p-5">
          <b-spinner /><br />
          aguarde...
        </div>
        <div class="row" v-if="!loading">
          <div class="col-12">
            <b-table
              striped
              hover
              :style="`font-size:11px`"
              :items="lista"
              :filter="filtro"
              :selectable="selectable"
              select-mode="single"
              @row-selected="onRowSelected"
              small
              :fields="[
                { key: 'id', label: 'ID' },
                { key: 'data', label: 'Data/Hora' },
                {
                  key: 'clinica.sigla',
                  label: 'Clínica',
                  class:
                    filtros.clinica_id && filtros.clinica_id > 0
                      ? 'd-none'
                      : '',
                },
                { key: 'status', label: 'Status' },
                { key: 'venda.venda_id', label: 'Venda' },
                { key: 'devedor_nome', label: 'Cliente' },
                { key: 'valor', label: 'Valor' },
                { key: 'pixEndToEndCode', label: 'Cod. Trans' },
              ]"
            >
              <template #cell(id)="row">
                {{ row.item.id }}
                <div style="font-size:8px">{{ row.item.txId }}</div>
              </template>
              <template #cell(data)="row">
                {{ row.item.createdAt | moment("DD/MM/YYYY HH:mm") }}
              </template>
              <template #cell(valor)="row">
                {{ row.item.valor | currency }}
              </template>
            </b-table>
          </div>
          <div class="col-12 mt-1" v-if="!selectable">
            <hr />
            <div class="row text-center">
              <div class="col-3 col-sm-3 pr-1">
                <b-card title="Todos">
                  <h3>
                    {{
                      lista.reduce((ret, vl) => {
                        ret += vl.valor;
                        return ret;
                      }, 0) | currency
                    }}
                  </h3>
                </b-card>
              </div>
              <div class="col-3 col-sm-3 pl-1 pr-1">
                <b-card title="Concluídos">
                  <h3>
                    {{
                      lista.reduce((ret, vl) => {
                        ret += vl.status == "PAGO" && vl.venda ? vl.valor : 0;
                        return ret;
                      }, 0) | currency
                    }}
                  </h3>
                </b-card>
              </div>
              <div class="col-3 col-sm-3 pl-1 pr-1">
                <b-card title="Não vinculado">
                  <h3>
                    {{
                      lista.reduce((ret, vl) => {
                        ret += vl.status == "PAGO" && !vl.venda ? vl.valor : 0;
                        return ret;
                      }, 0) | currency
                    }}
                  </h3>
                </b-card>
              </div>
              <div class="col-3 col-sm-3 pl-1">
                <b-card title="Expirados">
                  <h3>
                    {{
                      lista.reduce((ret, vl) => {
                        ret += vl.status == "EXPIRADO" ? vl.valor : 0;
                        return ret;
                      }, 0) | currency
                    }}
                  </h3>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </print-component>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SelectEmpresaClinica from "../../common/SelectEmpresaClinica.vue";
import CaixaLib from "../../../libs/CaixasLib";
import PrintComponent from "../../Print/PrintComponent.vue";
import PrinterWindowHelper from "../../../helpers/PrinterWindowHelper";

export default {
  name: "RelatorioPixForm",
  components: {
    SelectEmpresaClinica,
    PrintComponent,
  },
  props: {
    clinica: Number,
    selectable: {
      type: Boolean,
      default: false,
    },
    hideFiltros: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.clinica && this.clinica > 0) {
      this.filtros.clinica_id = this.clinica;
    }
    this.carregar();
  },
  data() {
    return {
      filtro: "",
      filtros: {
        dt_i: moment().format("YYYY-MM-DD"),
        dt_f: moment().format("YYYY-MM-DD"),
        clinica_id: 0,
        situacao: "Todos",
        clinica_desc: "",
        dt_i_desc: moment().format("DD/MM/YYYY"),
        dt_f_desc: moment().format("DD/MM/YYYY"),
      },
      loading: false,
      lista: [],
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.filtros.dt_i_desc = moment(this.filtros.dt_i).format("DD/MM/YYYY");
        this.filtros.dt_f_desc = moment(this.filtros.dt_f).format("DD/MM/YYYY");
        this.carregar();
      },
    },
  },
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    onRowSelected(row) {
      if (
        row &&
        row.length > 0 &&
        (row[0].status != "ATIVA"  && row[0].status != "PAGO" || 
          (row[0].status == "PAGO" && row[0].venda != null))
      ) {
        this.$swal({
          title: "Indisponível!",
          icon: "error",
          text:
            "O PIX que vocês está tentando selecionar não está disponível para ser utilizado!",
        });
      } else {
        this.$emit("selected", row[0]);
      }
    },
    selectEmpresa(emp) {
      console.log("emp", emp);
      this.filtros.clinica_desc = emp?.sigla;
      this.filtros.clinica_id = emp?.id;
    },
    async carregar() {
      this.filtro = "";
      this.loading = true;
      this.lista = [];

      try {
        let filters = {
          processAt_i: moment(this.filtros.dt_i).format("YYYY-MM-DD"),
          processAt_f: moment(this.filtros.dt_f).format("YYYY-MM-DD"),
          externalRef:
            this.filtros.clinica_id != 0 ? this.filtros.clinica_id : null,
          situacao: this.filtros.situacao,
        };
        console.log("fils", filters);
        let result = await CaixaLib.listagemPix(filters);
        console.log("pix result list", result);
        if (result && result.success) {
          this.lista = result.data;
        }
      } catch (e) {
        console.log("ERROR get pix list", e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>