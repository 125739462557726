import axios from "../utils/axios";

const solicitarNFE = async (data) => {
  try {
    // console.log("SOLICITANDO...", data);
    return (await axios.post("/notas-fiscais/solicitar", data)).data;
  } catch (err) {
    console.log("ERRO AO SOLICITAR NFE  ", err);
    return null;
  }
};

const updateNFE = async (data) => {
  try {
    // console.log("Atualizando nota...", data);
    return (await axios.patch(`/notas-fiscais`, data)).data;
  } catch (err) {
    console.log("ERRO AO SOLICITAR NFE  ", err);
    return null;
  }
};

const getNFSERelatorios = async (filters) => {
  try {
    // console.log("filters --> ", filters);
    const result = (await axios.get("notas-fiscais/relatorio", {
      params: filters,
    })).data;
    return result;
  } catch (error) {
    console.log("Erro no relatório de notas -> ", error.response);
    throw error.response
  }
};

const cancelarNFSE = async  (id)=>{
  try {
    const result = (await axios.post(`notas-fiscais/cancelar/${id}`)).data
    console.log(result)
    return result
  } catch (error) {
    console.log(error)
  }
}

export default {
  solicitarNFE,
  updateNFE,
  getNFSERelatorios,
  cancelarNFSE
};
