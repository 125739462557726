<template>
  <div>
    <div class="row mt-3">
      <div class="col-12 text-right">
        <hr />

        <div class="d-flex">
          <slot name="left"></slot>
          <div class="ml-auto">
            <b-button
              :disabled="saveDisabled || saving"
              variant="primary"
              class="mr-0"
              @click="salvar"
            >
              <span  v-show="saving">
              <b-spinner label="salvando" small />
              aguarde...

              </span>

              <span v-show="!saving"> <i :class="okIcon" />  {{okText}}</span>
            </b-button>
            <b-button variant="secondary" @click="cancelar"
              ><i class="fa fa-ban" /> Cancelar</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BotoesSalvarCancelar",
  components: {},
  props: {
    okIcon:{
      type:String,
      default:'fa fa-save'
    },
    okText:{
      type:String,
      default:'Salvar'
    },
    saving: {
      type: Boolean,
      default: false,
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    salvar() {
      this.$emit("salvar");
    },
    cancelar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style lang="scss" scoped></style>
