<template>
  <div>
    <div
      class="card"
      :class="{ selected: value && typeof value == 'object'? lancamento.id === value.id:lancamento.id == value }"
      @click="onSelect(lancamento)"
    >
      <div class="row">
        <div class="col-6">
          <div class="titulo">
            <small> <i class="fa fa-user" /> {{ lancamento.cliente }} </small>
          </div>
          <div class="titulo">
            <small>
              <small>
                <i class="fab fa-accessible-icon" />
                {{ lancamento.paciente }}
              </small>
            </small>
            <br />
            <small v-if="lancamento.item_nome">
              <i class="fa fa-notes-medical" />
              {{ lancamento.item_nome }}
            </small>
            <small v-if="lancamento.fornecedor">
              <br v-if="lancamento.item_nome" />

              <i class="fa fa-user-md" /> {{ lancamento.fornecedor }}
            </small>
          </div>
        </div>
        <div class="col-6 text-right">
          <div class="valor">
            <h5>{{ lancamento.vl_total | currencyWithMask }}</h5>
          </div>
          <small> <i class="fa fa-headset" /> {{ lancamento.usuario }} </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lancamento: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [Object, Number],
      default: () => {},
    },
  },
  methods: {
    onSelect(venda) {
      //console.log('value', this.value);
      if (
        typeof this.value == "object"
          ? this.value?.id === venda?.id
          : this.value == venda?.id
      ) {
        this.$emit("input", {});
        return;
      }
      this.$emit("input", venda);
    },
  },
};
</script>

<style></style>
