import axios from '@/utils/axios.js';

const get = async () => {
    let clinicas = [];

    await axios.get('/clinicas/get').then((result) => {
        clinicas = result.data
    }).catch((err) => {
        console.log('erro ao buscar clinicas', err);
    });

    return clinicas.data;
}
const store = async (data) => {
    return (await axios.post('/clinicas/store', data)).data;
}
const del = async (data) => {
    return (await axios.post('/clinicas/delete', data)).data;
}
//ecma script
export default {
    get, store, del
}