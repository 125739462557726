<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-form-group>
          <label>Tipo de Receita</label>
          <b-select
            :disabled="disabled"
            @change="hasChanged = true"
            v-model="receita.tipo"
            :options="tiposReceita"
          ></b-select>
          <small class="form-text text-muted" v-if="!receita.tipo"
            >Selecione um tipo de receita para continuar.
          </small>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <!-- {{receita}} -->

      <div class="col-12">
        <select-medico-especialidade
          lockMedico
          :medico_inicial="medico_id"
          :especialidade_inicial="especialidade_id"
          :disabledMedico="disabled"
          :disabledEspecialidade="disabled"
          @especialidade="receita.especialidade_id = $event"
          :especialidade_id="receita.especialidade_id"
          @medico="receita.medico_id = $event"
          :medico_id="receita.medico_id"
        />
      </div>
      <div class="col-3" v-if="receita.tipo == 'Oftalmo'">
        <b-form-group>
          <label for="">Código</label>
          <b-form-input
            :disabled="disabled"
            v-model="receita.codigo"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback
            class="text-nowrap"
            :force-show="codigoExists"
          >
            Código já está em uso por outra receita.</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
    </div>

    <div class="row my-1">
      <div class="col">
        <tipo-medicamento
          :disabled="disabled"
          :medicamento="receita.conteudo.medicamento"
          @change="hasChanged = true"
          v-if="receita.tipo == 'Medicamento'"
        />
        <tipo-oftalmo
          :disabled="disabled"
          @change="hasChanged = true"
          :oftalmo="receita.conteudo.oftalmo"
          v-if="receita.tipo == 'Oftalmo'"
        />
        <tipo-em-branco
          :disabled="disabled"
          @change="hasChanged = true"
          :emBranco="receita.conteudo.emBranco"
          v-if="receita.tipo == 'Em Branco' || receita.tipo == 'Exame'"
        />
      </div>
    </div>

    <div
      class="row"
      v-if="
        receita.tipo && (receita.tipo != 'Em Branco' || receita.tipo == 'Exame')
      "
    >
      <div class="col">
        <b-form-group>
          <label>Observações</label>

          <b-form-textarea
            :readonly="disabled"
            v-model="receita.obs"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>

    <div v-if="!!receita.id" class="row mt-3">
      <div class="col">
        <b-btn :disabled="hasChanged" @click="callPrintReceita">
          <i v-if="!receita.printLoad" class="fas fa-print"></i>
          <b-spinner small v-if="receita.printLoad"></b-spinner> Imprimir
          Receita</b-btn
        >
      </div>
    </div>

    <div class="float-left text-danger mt-sm-3 " v-if="hasChanged">
      <b-icon-info-circle /> Alterações realizadas! Não se esqueça de salvar!
    </div>

    <component
      :id="receita.id"
      v-if="printReceita"
      @close="closePrintReceita()"
      @done="donePrint()"
      :is="imprime[receita.tipo]"
    ></component>
  </div>
</template>

<script>
// import moment from "moment";
import PacienteReceitasLib from "../../../../libs/PacienteReceitasLib";
import Validadores from "../../../../helpers/Validadores";
import TipoMedicamento from "./Tipos/TipoMedicamento.vue";
import TipoOftalmo from "./Tipos/TipoOftalmo.vue";
import TipoEmBranco from "./Tipos/TipoEmBranco.vue";
import SelectMedicoEspecialidade from "../../../common/SelectMedicoEspecialidade.vue";
import ImprimeOftalmo from "./ImprimeReceita/Oftalmo.vue";
import ImprimeMedicamento from "./ImprimeReceita/Medicamento.vue";
import ImprimeEmBranco from "./ImprimeReceita/EmBranco.vue";
// import { required, minLength } from "vuelidate/lib/validators";
export default {
  props: {
    receita_edit: Object,
    paciente: Object,
    directSave: {
      type: Boolean,
      default: true
    },
    medico_id: [Number, String],
    especialidade_id: [Number, String],
    readonly: {
      type: Boolean,
      default: false
    },
    horario_id: [Number, String]
  },
  components: {
    TipoMedicamento,
    TipoOftalmo,
    TipoEmBranco,
    ImprimeOftalmo,
    SelectMedicoEspecialidade
  },
  // mixins:{
  //   validationMixin
  // },
  // validations:{
  //   solicitacao: {
  //       data:{required},
  //       medico_id: {required},
  //       especialidade_id: {required},
  //       paciente_id: {required},
  //       obs: "",
  //       exames:{minLength:minLength(1)},
  //     },
  // },
  data() {
    return {
      campos: [
        // {
        //   key: "exameId",
        //   label: "Código",
        //   class: "text-center",
        // },
        {
          key: "nome",
          label: "Exame",
          class: "text-center text-nowrap"
        },
        {
          key: "descricao",
          label: "Descrição",
          class: "text-center"
        },
        // {
        //   key: "obs",
        //   label: "Observação",
        //   class: "text-center",
        // },
        {
          key: "actions",
          label: "",
          class: "text-center"
        }
      ],
      imprime: {
        Oftalmo: ImprimeOftalmo,
        "Em Branco": ImprimeEmBranco,
        Exame: ImprimeEmBranco,
        Medicamento: ImprimeMedicamento
      },
      hasChanged: false,
      printReceita: false,
      tiposReceita: [
        "Oftalmo",
        "Exame",
        "Medicamento",
        "SEM GRAU / SEM ALTERAÇÕES",
        "PATOLOGIA / OUTROS",
        "ENCAMINHAMENTO PARA CIRURIGA"
      ],
      codigoExists: false,
      receita: {
        especialidade_id: null,
        medico_id: null,
        paciente_id: this.paciente ? this.paciente.id : null,
        tipo: null,
        codigo: null,
        obs: "",
        horario_id: this.horario_id,
        conteudo: {
          oftalmo: {
            od: {
              esferico: "",
              cilindrico: "",
              eixo: ""
            },
            oe: {
              esferico: "",
              cilindrico: "",
              eixo: ""
            },
            adicao: ""
          },
          emBranco: {
            texto: ""
          },
          Exame: {
            texto: ""
          },
          medicamento: {
            medicamentos: []
          }
        }
      },
      // receita: {

      // },
      hasErrors: []
    };
  },
  methods: {
    async callPrintReceita() {
      console.log(this.imprime, this.receita);
      if (
        !Object.keys(this.imprime).includes(
          this.receita.tipo 
        )
      ) {
        this.showToast(
          "error",
          "Ainda não há impressão disponivel para este tipo de receita!"
        );
        return;
      }
      this.receita.printLoad = true;

      this.$forceUpdate();
      await this.$nextTick();
      setTimeout(async () => {
        await this.$nextTick();
        this.printReceita = true;
        await this.$nextTick();
      }, 500);
    },
    closePrintReceita() {
      this.printReceita = false;
      // this.$swal
      //   .fire({
      //     title: "Deseja fechar a solicitação?",
      //     icon: "question",
      //     showCancelButton: true,
      //     cancelButtonText: "Não.",
      //     confirmButtonText: " Sim.",
      //   })
      //   .then(async (result) => {
      //     if (result.isConfirmed) {
      //       this.$emit("close");
      //     }
      //   });
    },
    donePrint() {
      // console.log("DONE PRINT");
      this.receita.printLoad = false;
    },
    validateState(campo) {
      let hasError = this.hasErrors.find(x => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    async codigoJaExiste() {
      this.codigoExists = false;
      let receita = (
        await PacienteReceitasLib.get({
          codigo: this.receita.codigo
        })
      )[0];

      if (receita && this.receita.id && receita.id != this.receita.id) {
        this.codigoExists = receita ? true : false;
      }

      return this.codigoExists;
    },
    addOrUpdateError(campo, state) {
      let hasError = this.hasErrors.find(x => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map(error => {
        error.state = null;
      });
    },
    async validar() {
      this.clearErrors();
      await this.$nextTick();

      this.addOrUpdateError("conteudo", this.receita.conteudo);
      this.addOrUpdateError(
        "tipo",
        !Validadores.string.IsNullOrEmpty(this.receita.tipo)
      );
      this.addOrUpdateError(
        "paciente",
        !Validadores.string.IsNullOrEmpty(this.receita.paciente_id)
      );
      this.addOrUpdateError(
        "especialidade",
        !Validadores.string.IsNullOrEmpty(this.receita.especialidade_id)
      );

      this.addOrUpdateError(
        "medico",
        !Validadores.string.IsNullOrEmpty(this.receita.medico_id)
      );

      if (this.receita.tipo == "Oftalmo") {
        this.addOrUpdateError(
          "codigo",
          !Validadores.string.IsNullOrEmpty(this.receita.codigo) &&
            !(await this.codigoJaExiste())
        );
      }

      return {
        success: !this.hasErrors.some(x => x.state == false),
        messages: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>\n ${this.hasErrors
          .filter(x => x.state == false)
          .map(error => {
            return error.campo.indexOf("codigo") !== -1
              ? "Código inválido ou já em uso."
              : error.campo.charAt(0).toUpperCase() + error.campo.slice(1);
          })
          .reduce((it, vl) => {
            it += vl + " <hr />";
            return it;
          }, "")}`
      };
    },
    async salvar() {
      delete this.receita?.printLoad;
      let validacao = await this.validar();
      console.log(validacao);
      if (!validacao.success) {
        this.$swal("Atenção", validacao.messages, "error");
        return;
      }
      try {
        if (this.directSave) {
          let result = await PacienteReceitasLib.store(this.receita);
          if (!this.receita.id) {
            this.$set(this.receita, "id", result.data[0]);
            // this.receita.id = result.data[0];
          }
          // console.log("reuslt da receita: ", result.data);
          if (result.status == 200 || result.status == 201) {
            this.showToast("success", "Receita salva com sucesso!");
            this.$emit("salvou", {
              close: false
            });

            if (Object.keys(this.imprime).includes(this.receita.tipo)) {
              await this.$swal
                .fire({
                  title: "Deseja imprimir a receita?",
                  icon: "question",
                  showCancelButton: true,
                  cancelButtonText: "Não.",
                  confirmButtonText: '<i class="fas fa-print"></i> Sim.'
                })
                .then(async result => {
                  if (result.isConfirmed) {
                    await this.callPrintReceita();
                  } else {
                    this.$emit("salvou");
                  }
                });
            }
          }
        } else {
          this.$emit("salvou", { data: this.receita });
        }
      } catch (error) {
        console.log("erro ao inserir", error);
        this.showToast("error", "Alguma coisa deu errado :/\n" + error, 3600);
      }
      this.hasChanged = false;
    }
  },
  mounted() {
    if (!isNaN(this.receita_edit.id) || !isNaN(this.receita_edit._idInterno)) {
      this.receita = Object.assign({}, this.receita_edit);
    }
  },
  computed: {
    // examesFiltradas() {
    //   return this.solicitacao.exames.filter((exame) => {
    //     return exame._rowStatus
    //       ? exame._rowStatus == "deleted"
    //         ? false
    //         : true
    //       : true;
    //   });
    // },
    disabled() {
      return this.readonly;
    }
  },
  watch: {
    "receita.tipo"(newVal) {
      // for (const key in this.receita.conteudo) {
      //   delete this.receita.conteudo[key];
      // }

      if (newVal == "Oftalmo" && !this.receita.conteudo.oftalmo) {
        this.$set(this.receita.conteudo, "Oftalmo", {
          od: {
            esferico: "",
            cilindrico: "",
            eixo: ""
          },
          oe: {
            esferico: "",
            cilindrico: "",
            eixo: ""
          },
          adicao: ""
        });
      }

      if (newVal == "Medicamento" && !this.receita.conteudo.medicamento) {
        this.$set(this.receita.conteudo, "medicamento", { medicamentos: [] });
      }

      if (newVal == "Em Branco" && !this.receita.conteudo.emBranco) {
        this.$set(this.receita.conteudo, "emBranco", { texto: "" });
      }
      if (newVal == "Exame" && !this.receita.conteudo.Exame) {
        this.$set(this.receita.conteudo, "emBranco", { texto: "" });
      }
    }
  }
};
</script>

<style></style>
