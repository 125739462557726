<template>
  <div>
    <div class="row align-items-center">
      <div class="col-12">
        <b-form-group>
          <label>Medicamentos</label>
          <v-select
          :disabled="computedDisabled"
            class=""
            @input="addMedicamento"
            :options="medicamentos"
            label="medicamento"
          >
            <template #search="{ attributes, events }">
              <input
                v-model="medicamentosInput"
                :class="['vs__search rounded']"
                v-bind="attributes"
                v-on="events"
                @keyup.enter="
                  addMedicamento({ medicamento: medicamentosInput })
                "
              />
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nenhum resultado para <em>{{ search }} </em>. Aperte
                <b>enter</b> para cadastrar.
              </template>
              <em style="opacity: 0.5" v-else
                >Não encontramos nenhum médicamento 😕.</em
              >
            </template>
          </v-select>
        </b-form-group>
      </div>
    </div>

    <b-table
      small
      stacked="sm"
      show-empty
      outlined
      responsive
        
      hover
      :items="medicamento.medicamentos"
      :fields="campos"
      class="shadow"
    >
      <template #empty>
        <div class="text-center">
          Nenhum medicamento por enquanto. 💊
        </div>
      </template>

      <template #cell(concentracao)="{item}">
        <div class="row justify-content-center">
          <div class="col-6 text-center">
            <b-form-input
              placeholder="Concentração..."
              class="rounded mt-2 text-center"
              size="sm"
               :disabled="computedDisabled"
              v-model="item.concentracao"
            ></b-form-input>
          </div>
        </div>
      </template>

      <template #cell(posologia)="{item}">
        <div class="row justify-content-center">
          <div class="col">
            <b-form-textarea
              placeholder="Digite a indicação da dose adequada de um medicamento... (Opcional)"
              class="rounded text-center"
              rows="1"
               :disabled="computedDisabled"
              no-resize
              size="sm"
              v-model="item.posologia"
            ></b-form-textarea>
          </div>
        </div>
      </template>
      <template #cell(actions)="{index}">
        <b-btn @click="removeMedicamento(index)"  :disabled="computedDisabled" variant="transparent"
          ><i class="fas fa-times"></i
        ></b-btn>
      </template>
    </b-table>
  </div>
</template>

<script>
import MedicamentosLib from "../../../../../libs/MedicamentosLib";
export default {
  props: {
    medicamento: Object,
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medicamentos: [],
      medicamentosSelecionados: [],
      medicamentosInput: "",
      campos: [
        {
          key: "medicamento",
          label: "Medicamento",
          class: "text-center",
        },
        {
          key: "concentracao",
          label: "Concentração",
          class: "text-center",
        },
        {
          key: "posologia",
          label: "Posologia",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    async carregaMedicamentos() {
      this.medicamentos = await MedicamentosLib.get();
    },
    async addMedicamento(medicamento) {
      if (
        this.medicamento?.medicamentos?.some(
          (med) =>
            med.medicamento === medicamento.medicamento ||
            med.id === medicamento.id
        )
      ) {
        this.showToast(
          "error",
          `Você já adicionou <b> '${medicamento.medicamento}' </b>  à receita.`,
          3000
        );
        return;
      }
      if (!medicamento.id) {
        medicamento.id = (
          await MedicamentosLib.store({
            medicamento: medicamento.medicamento,
            concentracao: "",
          })
        )?.data[0];

        await this.carregaMedicamentos();
      }
      this.medicamentosInput = "";
      medicamento.posologia = medicamento.posologia
        ? medicamento.posologia
        : "";
      this.medicamento.medicamentos.push(medicamento);
    },
    removeMedicamento(i) {
      this.medicamento.medicamentos.splice(i, 1);
    },
  },
  watch: {
    medicamento: {
      deep: true,
      handler() {
        this.$emit("change", this.medicamento);
      },
    },
  },
  async mounted() {
    await this.carregaMedicamentos();
    // if (!this.receita.medicamentos) {
    //    this.$set(this.receita, 'medicamentos', [])
    // }
  },
  computed:{
    computedDisabled(){
      return this.disabled
    }
  }
};
</script>

<style></style>
