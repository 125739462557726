<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <label>Data Solicitação</label>
            <b-input type="date" v-model="solicitacao.dt_solicitacao"></b-input>
          </div>
          <div class="col-12 col-sm-12 pr-sm-1">
            <b-form-group>
              <label>Nome</label>
           
              <select-paciente
                :tipo="['paciente']"
                @paciente="onPaciente"
                :selecionado="solicitacao.paciente_id"
                :disabled="solicitacao.agendado == 1"
                :showBotaoAdd="false"
                :showBotaoEdit="false"
                @input="solicitacao.nome = $event"
                :text="solicitacao.nome"
                setNullOnEmpty
              ></select-paciente>

              <b-form-invalid-feedback
                :force-show="validateState('nome') === false"
                >O nome é obrigatório!</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4">
            <b-form-group>
              <label>Idade</label>
              <b-form-input
                v-model="solicitacao.idade"
                :state="validateState('idade')"
                placeholder="Idade"
                :disabled="solicitacao.agendado == 1"
                :readonly="solicitacao.agendado == 1"
              ></b-form-input>
              <b-form-invalid-feedback
                >A idade é obrigatória!</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <div class="col-12 col-sm-8">
            <b-form-group>
              <label>CPF</label>
              <b-form-input
                v-mask="'###.###.###-##'"
                v-model="solicitacao.cpf"
                :state="validateState('cpf')"
                placeholder="CPF"
                :disabled="solicitacao.agendado == 1"
                :readonly="solicitacao.agendado == 1"
              ></b-form-input>
              <b-form-invalid-feedback
                >Por favor, digite um CPF válido!</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <div class="col-12 col-sm-6">
            <b-form-group>
              <label>Telefone</label>
              <b-form-input
                v-model="solicitacao.telefone"
                :state="validateState('telefone')"
                v-mask="['(##) ####-####', '(##) #####-####']"
                placeholder="Telefone"
                :disabled="solicitacao.agendado == 1"
                :readonly="solicitacao.agendado == 1"
              ></b-form-input>
              <b-form-invalid-feedback
                >Por favor, digite um telefone/celular
                válido!</b-form-invalid-feedback
              >
            </b-form-group>
          </div>

          <div class="col-12 col-sm-6 pl-sm-1">
            <b-form-group>
              <label>Telefone2</label>
              <b-form-input
                v-model="solicitacao.telefone2"
                v-mask="['(##) ####-####', '(##) #####-####']"
                placeholder="Telefone 2"
                :disabled="solicitacao.agendado == 1"
                :readonly="solicitacao.agendado == 1"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-3 mt-3">
          <div class="col-12 mb-3">
            <label>Especialidade</label>
            <v-select
              v-model="solicitacao.especialidade_id"
              :options="especialidades"
              label="especialidade"
              @input="carregaEntidades()"
              :reduce="(espec) => espec.id"
              :disabled="solicitacao.agendado == 1"
              :readonly="solicitacao.agendado == 1"
            >
              <template #search="{ attributes, events }">
                <input
                  :class="[
                    'vs__search rounded',
                    typeof validateState('especialidade_id') == 'boolean' &&
                    !validateState('especialidade_id')
                      ? 'isInvalid'
                      : '',
                  ]"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Não encontramos nenhum médico com essa especialiade 😕.</em
                >
              </template>
            </v-select>
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('especialidade_id') == 'boolean' &&
                  !validateState('especialidade_id')
              "
            >
              Selecione uma especialiade e/ou médico e/ou clínica.
            </b-form-invalid-feedback>
          </div>

          <div class="col-12 mb-3">
            <label>Médico</label>

            <v-select
              v-model="solicitacao.medico_id"
              :options="medicos"
              label="razao_social"
              :reduce="(med) => med.id"
              :disabled="solicitacao.agendado == 1"
              :readonly="solicitacao.agendado == 1"
            >
              <template #search="{ attributes, events }">
                <input
                  :class="[
                    'vs__search rounded',
                    typeof validateState('medico_id') == 'boolean' &&
                    !validateState('medico_id')
                      ? 'isInvalid'
                      : '',
                  ]"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Não encontramos nenhum médico com essa especialiade 😕.</em
                >
              </template>
            </v-select>
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('medico_id') == 'boolean' &&
                  !validateState('medico_id')
              "
            >
              Selecione uma especialiade e/ou médico e/ou clínica.
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row mb-3">
          <div class="col-12 mb-2">
            <select-clinicas
              :autoBlock="false"
              consultorio
              :disabled="solicitacao.agendado == 1"
              @consultorio="solicitacao.consultorio = $event"
              :valueConsultorio="solicitacao.consultorio"
              v-model="solicitacao.clinica_id"
            />
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('clinica_id') == 'boolean' &&
                  !validateState('clinica_id')
              "
            >
              Selecione uma especialiade e/ou médico e/ou clínica.
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-7 pr-sm-1">
            <label>Data</label>
            <b-form-input
              type="date"
              v-model="solicitacao.dt_agendamento"
              :state="validateState('dt_agendamento')"
              :disabled="solicitacao.agendado == 1"
              :readonly="solicitacao.agendado == 1"
            ></b-form-input>
            <b-form-invalid-feedback>
              A data da solicitação é obrigatória.
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-5 mb-3 pl-sm-1">
            <label>Hora</label>
            <b-form-input
              type="time"
              locale="br"
              step="0"
              v-model="solicitacao.horario_desejado"
              :disabled="solicitacao.agendado == 1"
              :readonly="solicitacao.agendado == 1"
            ></b-form-input>
          </div>
          <div class="col-12 c">
            <label>Tipo</label>
            <v-select
              :options="tipos"
              v-model="solicitacao.tipo"
              :disabled="solicitacao.agendado == 1"
              :readonly="solicitacao.agendado == 1"
            >
              <template #search="{ attributes, events }">
                <input
                  :class="[
                    'vs__search rounded',
                    typeof validateState('tipo') == 'boolean' &&
                    !validateState('tipo')
                      ? 'isInvalid'
                      : '',
                  ]"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Não encontramos nenhum tipo 😕.</em
                >
              </template>
            </v-select>
            <b-form-invalid-feedback
              :force-show="
                typeof validateState('clinica_id') == 'boolean' &&
                  !validateState('clinica_id')
              "
            >
              O Tipo é obrigatório!
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <b-form-group>
              <label>Observações</label>
              <b-form-textarea
                type="text"
                style="height: 75px"
                v-model="solicitacao.obs"
                :disabled="solicitacao.agendado == 1"
                :readonly="solicitacao.agendado == 1"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  
    <b-btn
      variant="primary"
      @click="agendar"
      block
      v-if="typeof solicitacao.id == 'number' && !solicitacao.agendado &&
       ((solicitacao && solicitacao.especialidade && !solicitacao.especialidade.toLowerCase().includes('oftalm') && user.perfil=='secretaria')
       || user.perfil!=='secretaria')"
    >
      <i class="fas fa-check"></i> Agendar</b-btn
    >
    <div
      class="row"
      v-if="typeof solicitacao.id == 'number' && solicitacao.agendado"
    >
      <div class="col-12 p-4 text-left">
        <b-card
          class="align-items-center card-hover p-2 w-100"
          @click="abrirAgenda"
        >
          <b-card-text>
            <div v-if="solicitacao.agendado && solicitacao.agenda">
              <b-card-title class="text-center">Agendado</b-card-title>
              <p class="m-0">
                <i class="fas fa-calendar-alt"></i> <b>Data:</b>
                {{ solicitacao.agenda.data | moment("DD/MM/YYYY") }}
              </p>
              <p class="m-0">
                <i class="fas fa-user-md"></i> <b>Médico:</b>
                {{ solicitacao.agenda.medico }}
              </p>
              <p>
                <i class="fas fa-stethoscope"></i>
                <b> Especialidade:</b>
                {{ solicitacao.agenda.especialidade }}
              </p>
              <small><i>* clique para ver a agenda</i></small>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import EspecialidadesLib from "@/libs/EspecialidadesLib";
import EntidadesLib from "@/libs/EntidadesLib";
import ClinicasLib from "@/libs/ClinicasLib";
import Validadores from "../../../helpers/Validadores";
import SelectPaciente from "../../common/SelectPaciente.vue";
// import AgendasSolicitacoesLib from "@/libs/AgendasSolicitacoesLib"

import moment from "moment";
import SelectClinicas from "../../common/SelectClinicas.vue";

function required(value) {
  // console.log("value", value);

  return !Validadores.string.IsNullOrEmpty(value);
}

export default {
  components: { SelectClinicas, SelectPaciente },
  props: {
    solicitacao_edit: { type: Object, default: () => {} },
    dataSelecionada: { type: [Date, Object], default: null },
  },
  mounted() {
    if (typeof this.solicitacao_edit.id == "number") {
      console.log("===>", this.solicitacao_edit);
      if (this.solicitacao_edit.dt_solicitacao) {
        this.solicitacao_edit.dt_solicitacao = moment(this.solicitacao_edit.dt_solicitacao).format("YYYY-MM-DD")
      }
      Object.assign(this.solicitacao, this.solicitacao_edit);
    }

    this.carregaEspecialidades();
    this.carregaClinicas();
    this.carregaEntidades();
  },
  data() {
    return {
      solicitacao: {
        nome: "",
        idade: null,
        telefone: "",
        telefone2: "",
        cpf: "",
        paciente_id: null,
        medico_id: null,
        especialidade_id: null,
        clinica_id: null,
        tipo: "",
        horario_desejado: moment(new Date()).format("HH:mm"),
        dt_agendamento: this.dataSelecionada
          ? moment().format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD HH:mm:ss"),
        confirmado: 0,
        agendado: 0,
        agenda_id: null,
        horario_id: null,
        consultorio: 0,
        dt_solicitacao: this.dataSelecionada
          ? moment().format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        // dt_agendamento: "",
        obs: "",
      },
      hasErrors: [],
      clinicas: [],
      medicos: [],
      especialidades: [],
      tipos: ["Procedimento", "Consulta", "Retorno", "Exame", "Orçamento"],
    };
  },
  // validations: {
  //   solicitacao: {
  //     nome: { required },
  //     idade: { required },
  //     telefone: {
  //       required,
  //       validar() {
  //         return Validadores.string.validaTelefone(this.solicitacao.telefone);
  //       },
  //     },
  //     cpf: {
  //       validar() {
  //         return this.solicitacao.cpf
  //           ? Validadores.string.validarCPF(this.solicitacao.cpf)
  //           : true;
  //       },
  //     },

  //     medico_id: {
  //       customRequired() {
  //         return this.customRequired();
  //       },
  //     },
  //     especialidade_id: {
  //       customRequired() {
  //         return this.customRequired();
  //       },
  //     },
  //     clinica_id: {
  //       customRequired() {
  //         return this.customRequired();
  //       },
  //     },
  //     tipo: { required },
  //     //   horario_desejado: { required },
  //     //   horario: { required },
  //     dt_agendamento: { required },
  //     //   // confirmado: 0,
  //     //   // agendado: 0,
  //     //   //   agenda_id: { required },
  //     //   //   horario_id: { required },
  //     //   dt_agendamento: { required },
  //     //   obs: { required },
  //   },
  // },
  watch: {},
  methods: {
    // validateState(name) {
    //   const { $dirty, $error } = this.solicitacao[name];
    //   return $dirty ? !$error : null;
    // },
    resetForm() {
      for (const key in this.solicitacao) {
        this.solicitacao[key] = null;
      }

      this.$nextTick(() => {
        this.$reset();
      });
    },
    onPaciente(paciente) {
      console.log("paciente selected", paciente, this.solicitacao_edit);

      if (paciente && paciente?.id) {
        if (!this.solicitacao_edit || !this.solicitacao_edit.id) {
          this.solicitacao.paciente_id = paciente?.id;
          this.solicitacao.nome = paciente?.razao_social;
          this.solicitacao.cpf =
            !this.solicitacao.cpf || this.solicitacao.cpf == ""
              ? paciente?.cpf
              : this.solicitacao.cpf;
          this.solicitacao.idade =
            !this.solicitacao.idade || this.solicitacao.idade == ""
              ? paciente?.idade
              : this.solicitacao.idade;
          if (
            Array.isArray(paciente.telefones) &&
            paciente.telefones.length > 0
          ) {
            this.solicitacao.telefone =
              !this.solicitacao.telefone || this.solicitacao.telefone == ""
                ? paciente.telefones[0]?.telefone
                : this.solicitacao.telefone;
            if (paciente.telefones.length > 1) {
              this.solicitacao.telefone2 =
                !this.solicitacao.telefone2 || this.solicitacao.telefone2 == ""
                  ? paciente.telefones[1]?.telefone
                  : this.solicitacao.telefone2;
            }
          }
        }
      } else if (!this.solicitacao_edit) { 
        //this.solicitacao.nome = 
      }
      else if (!this.solicitacao_edit) {
        this.solicitacao.paciente_id = null;
        this.solicitacao.nome = "";
        this.solicitacao.cpf = "";
        this.solicitacao.telefone = "";
        this.solicitacao.telefone2 = "";
        this.solicitacao.idade = "";
      }
    },
    async validar() {
      this.clearErrors();
      await this.$nextTick();

      let validations = [
        {
          campo: "nome",
          rule: [required],
          label: "Informe o nome do paciente!",
        },
        {
          campo: "idade",
          rule: [required],
          label: "Informe a idade do paciente!",
        },
        {
          campo: "telefone",
          rule: [required],
          label: "informe o telefone do paciente!",
        },
        {
          campo: "cpf",
          rule: [
            (cpf) => {
              // console.log("cpf", cpf, "length", cpf?.length);
              if (Array.isArray(cpf) && cpf?.length > 0) {
                return Validadores.string.validarCPF(cpf);
              }
              return true;
            },
          ],
          label: "CPF do paciente inválido!",
        },
        // {
        //   campo: "especialidade_id",
        //   rule: [required],
        //   label: "Informe a especialidade!",
        // },
        // {
        //   campo: "medico_id",
        //   rule: [required],
        //   label: "Informe o médico!",
        // },
        // {
        //   campo: "clinica_id",
        //   rule: [required],
        //   label: "Informe a clinica da solicitação!",
        // },

        {
          campo: "tipo",
          rule: [required],
          label: "Informe o tipo da solicitação!",
        },
        {
          campo: "dt_agendamento",
          rule: [required],
          label: "Informe a data da solicitação!",
        },
      ];

      validations.map((valid) => {
        // console.log(valid);
        this.addOrUpdateError({
          campo: valid.campo,
          state: !valid.rule
            .map((v) => v(this.solicitacao[valid.campo], 1))
            .some((e) => e == false),
          label: valid.label,
        });
      });

      if (
        !this.solicitacao.especialidade_id &&
        !this.solicitacao.medico_id &&
        !this.solicitacao.clinica_id &&
        this.solicitacao.consultorio == 0
      ) {
        this.addOrUpdateError({
          campo: "especialidade_id",
          state: false,
          label: "Informe ao menos uma especialidade e/ou médico e/ou clinica.",
        });
        this.addOrUpdateError({
          campo: "clinica_id",
          state: false,
          label: "Informe ao menos uma clinica e/ou médico e/ou especialidade.",
        });
        this.addOrUpdateError({
          campo: "medico_id",
          state: false,
          label: "Informe ao menos uma médico e/ou clinica e/ou especialidade",
        });
      } else {
        ["especialidade_id", "clinica_id", "medico_id"].forEach((campo) =>
          this.addOrUpdateError({
            campo,
            state: true,
            label: "Informe ao menos uma clinica e/ou médico e/ou especialidad",
          })
        );
      }

      // console.log("HAS ERRORS", this.hasErrors);
      let errors = this.hasErrors.filter((x) => !x.state);
      console.log(errors);

      if (errors.length > 0) {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errors
            .map((e) => `${e.label}`)
            .reduce((it, vl) => {
              if (vl) {
                it += vl + " <hr />";
              }
              return it;
            }, "")} `,
        });
        return false;
      }
      return true;
    },
    validateState(campo) {
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) return null;
      else return hasError.state;
    },
    addOrUpdateError({ campo, state, label }) {
      // console.log(campo, state, label);
      let hasError = this.hasErrors.find((x) => x.campo == campo);
      if (!hasError) {
        this.hasErrors.push({ campo, state, label });
      } else {
        hasError.state = state;
      }
    },
    clearErrors() {
      this.hasErrors.map((error) => {
        error.state = null;
      });
    },

    // customRequired() {
    //   return (
    //     (this.solicitacao.medico_id != null &&
    //       this.solicitacao.medico_id != "") ||
    //     (this.solicitacao.especialidade_id != null &&
    //       this.solicitacao.especialidade_id != "") ||
    //     this.solicitacao.consultorio == 1 ||
    //     (this.solicitacao.clinica_id != null &&
    //       this.solicitacao.clinica_id != "")
    //   );
    // },
    async carregaEspecialidades() {
      try {
        this.especialidades = await EspecialidadesLib.get();
      } catch (error) {
        console.warn(error);
      }
    },
    async carregaEntidades() {
      // this.solicitacao.medico_id = "";
      this.loading = true;
      try {
        this.medicos = await EntidadesLib.getByEspecialidade(
          this.solicitacao.especialidade_id, true
        );
      } catch (error) {
        console.warn(error);
      }
      this.loading = false;
    },
    async carregaClinicas() {
      try {
        this.clinicas = await ClinicasLib.get();
      } catch (error) {
        console.warn(error);
      }
    },
    async salvar() {
      if (await this.validar()) {
        try {
          this.loading = true;
          delete this.solicitacao._rowVariant;
          // console.log("enviou para salvar", this.solicitacao);

          this.$emit("salvou", this.solicitacao);
          // console.log(result)
          // if (result.success && result.data) {
          //   this.showToast("success", "Solicitação salva com sucesso!")
          //   this.$emit("salvou")
          //   return result.data[0]
          // } else {
          //   this.showToast(
          //     "error",
          //     "Erro ao salvar solicitação!\n" + result.message
          //   )
          // }
        } catch (error) {
          this.showToast("error", "Erro ao salvar solicitação!");
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async agendar() {
      if (this.solicitacao.agenda_id && this.solicitacao.agendado == 1) {
        this.showToast("error", `Está solicitação já foi agendada`);
        return;
      }
      // let errors = [];

      // if (!this.solicitacao.especialidade_id) {
      //   errors.push("Especialidade");
      // }

      // if (!this.solicitacao.medico_id) {
      //   errors.push("Médico");
      // }

      // if (this.solicitacao.consultorio == 0 && !this.solicitacao.clinica_id) {
      //   errors.push("Clínica");
      // }
      // if (errors.length > 0) {
      //   this.showToast(
      //     "error",
      //     `É necessário informar os seguintes campos: \n[${errors}]\n para gerar a agenda.`,
      //     7500
      //   );
      //   return;
      // }

      if (!(await this.validar())) return;

      // await this.salvar();
      await this.toastConfirmacao(
        async () => {
          try {
            if (typeof this.solicitacao.id != "number") {
              // this.solicitacao.id = await this.salvar();
              return;
            }
            // let result = await AgendasSolicitacoesLib.agendarSolicitacao(
            //   this.solicitacao
            // )
            this.$emit("agendarSolicitacao", this.solicitacao);
            // console.log(result)
          } catch (error) {
            console.log(error);
          }
        },
        () => {},
        "Atenção!",
        "Tem certeza? Isso irá gerar uma agenda de acordo com a solicitação.",
        "warning",
        "Sim, Agendar!"
      );
    },
    abrirAgenda() {
      if (this.solicitacao.agenda && this.solicitacao.agenda.id) {
        this.$emit("abrirAgenda", this.solicitacao.agenda.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-hover {
  cursor: pointer;
  box-shadow: 0px 4px 11px 4px rgba(0, 0, 0, 0.13);
  transition: all 300ms ease;
  &:hover {
    transform: scale(101%);
    box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.08);
  }
}
</style>