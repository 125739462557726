<template>
  <div>
    <b-avatar
      :icon="icon"
      :src="formataURLimagem(logo)"
      class="avatar"
      style="margin:0 auto; min-width:120px; max-width:190px; width:100%; height:100%; min-height:190px; max-height:190px"
      @click.native="selectFile()"
    >
    </b-avatar>

    <input
      type="file"
      ref="fl"
      style="visibility: hidden; width: 0; height: 0px; margin: 0"
      @change="uploadFile()"
    />
  </div>
</template>
<style scoped>
.recordConfirmation {
  font-size: 30px;
}
</style>
<script>
import Vue from "vue";
import axios from "@/utils/axios.js";
export default {
  props: {
    logo: { type: String, default: "" },
    icon: { type: String, default: "person-fill" },
  },
  data() {
    return {
      loading: false,
      uploaded: false,
      file: "",
      token: this.$store.state.auth.user.token,
      fileName: "",
    };
  },
  mounted() {
    this.loading = false;
    this.uploaded = false;
    this.file = "";
    this.fileName = "";

    // if (this.logo) {
    //   console.log(this.logo);
    // }
  },
  computed: {
    isPDF() {
      return (
        this.file
          .toString()
          .toLowerCase()
          .indexOf(".pdf") >= 0 ||
        this.file
          .toString()
          .toLowerCase()
          .indexOf(".txt") >= 0
      );
    },
  },
  methods: {
    async selectFile() {
      this.$refs.fl.value = "";
      this.$refs.fl.click();
    },
    formataURLimagem(url) {
      this.loading = true;
      let urlFormatado = `${axios.defaults.baseURL}downloadFile/${url}?token=${this.token}`;
      this.loading = false;
      return urlFormatado;
    },
    isImage(file) {
 
      return (
        file
          .toString()
          .toLowerCase()
          .indexOf(".jpg") >= 0 ||
        file.toString().indexOf(".png") >= 0 ||
        file.toString().indexOf(".bmp") >= 0 ||
        file.toString().indexOf(".gif") >= 0
      );
    },
    async uploadFile() {
      try {
        await Vue.nextTick();

        //  console.log(this.$refs.fl.files[0].name);
        if (this.$refs.fl.files.length > 0) {
        //   console.log(this.isImage(this.$refs.fl.files[0]));
        // if (!this.isImage(this.$refs.fl.files[0])) return;
          this.fileName = this.$refs.fl.files[0].name;
          var form = new FormData();

          form.append("file", this.$refs.fl.files[0]);

          this.loading = true;
          await Vue.nextTick();
          axios
            .post("/uploadFile", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((data) => {
              if (data && data.data && data.data.success) {
                this.file = data.data.file;
                this.pronto = true;
                this.loading = false;
                this.uploaded = true;

                this.$emit("fileAddOrchanged", this.file);
              }
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.avatar:hover {
  transition: 0.5s step-start;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}
</style>
