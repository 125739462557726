<template>
  <b-form>
    <div class="row">
      <div class="col-5">
        <b-form-group>
          <label>
            <i class="fas fa-id-card-alt"></i>
            CRM
          </label>
          <div class="d-flex align-items-baseline ">
            <b-form-input
              class="mr-3"
              v-model="medico.crm"
              :disabled="loading"
              placeholder="CRM"
              @blur="onChangeCRM(medico.crm)"
              size="sm"
            ></b-form-input>
            <b-spinner small v-if="loading">..Buscando </b-spinner>
          </div>
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group>
          <label><i class="fas fa-user-md"></i> Nome</label>
          <b-form-input
            v-model="medico.razao_social"
            placeholder="Nome do Médico"
            size="sm"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-12">
        <b-form-group>
          <label for=""
            ><i class="fas fa-stethoscope"> </i> Especialidade</label
          >

          <select-especialidade
            :defaultEspecialidades="medico.especialidadesOBJ"
            v-model="medico.especialidade_id"
            label=""
          ></select-especialidade>
          <small
            class="text-muted"
            v-if="
              Array.isArray(medico.especialidadesOBJ) &&
                medico.especialidadesOBJ.length > 0
            "
          >
            * Foram filtradas apenas as especialidades do médico
            {{ medico.razao_social }}
          </small>
        </b-form-group>
      </div>
    </div>

    <div class="row border-top pt-3 ">
      <div class="col d-flex justify-content-end">
        <b-btn @click="onAdd" class="mr-3" variant="primary">
          <i class="fas fa-save"></i> Adicionar</b-btn
        >
        <b-btn @click="onClose" variant="secondary">
          <i class="fas fa-ban"></i> Fechar</b-btn
        >
      </div>
    </div>
  </b-form>
</template>

<script>
import SelectEspecialidade from "../../SelectEspecialidade.vue";
import EntidadesLib from "../../../../libs/EntidadesLib";

export default {
  name: "FormMedicoResumido",
  components: {
    SelectEspecialidade,
  },
  data() {
    return {
      medico: {
        crm: "",

        razao_social: "",
        especialidade_id: "",
      },
      lastCRMFinded: "",
      medicoFinded: {
        especialidadesOBJ: [],
      },
      interval: null,
      loading: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async onAdd() {
      if (!this.medico.id) {
        try {
          let objMedico = {
            tipo: "PF",
            razao_social: this.medico.razao_social,
            crm: this.medico.crm,
            cpf: "",
            status: 1,
            ie: "",
            enderecos: [],
            telefones: [],
            configuracoes: {
              especialidades: [this.medico.especialidade_id],
            },
            medico: 1,
          };
          let result = await EntidadesLib.store(objMedico, true);
          if (result) {
            this.medico.id = result.data[0];
            this.$emit("added", this.medico);
            this.onClose();
          }
        } catch (error) {
          console.log("erro ao inserrir médico", error);
        }
      } else {
        this.$emit("added", this.medico);
        this.onClose();
      }
    },
    async onChangeCRM(crm) {
      if (typeof crm !== "string") return;
      await this.$nextTick();
      if (this.lastCRMFinded && crm.length !== this.lastCRMFinded.length) {
        this.lastCRMFinded = "";
        this.medico.id = null;

        this.medico.especialidade_id = null;
        this.medico.razao_social = "";
        this.medico.especialidadesOBJ = [];

        return;
      }
      if (
        typeof crm === "string" &&
        crm.trim().length > 3 &&
        crm !== this.lastCRMFinded
      ) {
        //      if (!this.loading) {

        this.$forceUpdate();
        this.loading = true;
        let caracteresInvalidos = [];
        crm = crm
          .split("")
          .filter((c) => !caracteresInvalidos.includes(c))
          .join("");
        if (crm) {
          this.medicoFinded = (
            await EntidadesLib.getResumido(null, null, { crm }, ["medico"])
          ).entidades[0];
          if (this.medicoFinded?.id) {
            this.medico.id = this.medicoFinded.id;
            this.medico.razao_social = this.medicoFinded.razao_social;
            this.medico.especialidade_id = this.medicoFinded.especialidadesOBJ[0].id;
            await this.$swal
              .fire({
                title: "Atenção!",
                icon: "warning",
                html: `Encontramos o médico <b>${this.medico.razao_social}</b> com o CRM <b>${crm}</b>, deseja utiliza-lo?  <hr/>`,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Sim",
                confirmButtonColor: "#e60f0f",
                confirmButtonAriaLabel: "Thumbs up, great!",
                cancelButtonText: "Nâo",
                cancelButtonColor: "##dc3545",

                cancelButtonAriaLabel: "Thumbs down",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$emit("added", this.medico);
                  this.onClose();
                } else {
                  this.lastCRMFinded = "";
                  this.medico.id = null;

                  this.medico.especialidade_id = null;
                  this.medico.razao_social = "";
                  this.medico.especialidadesOBJ = [];
                }
              });
          }
        }
        this.loading = false;
      }
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss"></style>
