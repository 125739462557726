<template>
  <div>
    <div class="row">
      <div class="col">
        <b-form-textarea
          v-model="emBranco.texto"
          placeholder="Digite aqui..."
          rows="10"
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emBranco: Object,
  },
  watch: {
    emBranco: {
      deep: true,
      handler() {
        this.$emit("change", this.emBranco);
      },
    },
  },
};
</script>

<style></style>
