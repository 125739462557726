<template>
  <div>
    <div class="barraCrud">
      <div class="d-flex">
        <img
          :src="require('@/assets/img/icones/menu/' + menu_ativo.icon)"
          v-if="menu_ativo && menu_ativo.iconType == 'png'"
        />
        <i
          :class="menu_ativo.icon"
          v-if="
            menu_ativo && menu_ativo.iconType && menu_ativo.iconType == 'fa'
          "
          style="font-size:40px; margin-right:20px "
        />
        <h3 v-if="menu_ativo" class="text-nowrap">{{ title? title:menu_ativo.nome }}</h3>
      </div>
      <Buscar :onFiltrar="onFiltrar" :minLength="minLength" v-if="busca" />

      <div class="bread ">
        <b-breadcrumb :items="breads"></b-breadcrumb>
        <slot name="extraButtonLeft"></slot>
        <b-button v-if="botaoNovo" variant="primary" @click="onNovo"
          ><i class="fa fa-plus"></i>
          {{
            title? title:
            menu_ativo
              ? menu_ativo.nome
                  .substr(menu_ativo.nome.length - 1, 1)
                  .toLowerCase() == "s"
                ? menu_ativo.nome.substr(0, menu_ativo.nome.length - 1)
                : menu_ativo.nome
              : ""
          }}</b-button
        >
        <slot name="extraButtonRigth"></slot>
      </div>
    </div>
    <FilterAlphabet
      :disabled="filterAlphabetDisable"
      v-if="filterAlphabet"
      :value="char"
      @input="$emit('charClicked', $event)"
    />
  </div>
</template>

<script>
import Buscar from "./Buscar.vue";
import FilterAlphabet from "./FilterAlphabet.vue";
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    onFiltrar: Function,
    minLength: { type: [Number, String] },
    botaoNovo: {
      type: Boolean,
      default: false,
    },
    filterAlphabet: {
      type: Boolean,
      default: false,
    },
    filterAlphabetDisable: {
      type: Boolean,
      default: false,
    },
    char: [String],
    onNovo: Function,
    busca: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Buscar,
    FilterAlphabet,
  },
  watch: {
    "$route.path": function() {
      this.carregaConf();
    },
  },
  data() {
    return {
      menu_ativo: null,
      breads: [],
    };
  },
  mounted() {
    this.carregaConf();
  },
  methods: {
    carregaConf() {
      this.breads = [];
      let mnus = this.$store.state.menu.reduce((ret, item) => {
        if (item.children && item.children.length > 0) {
          item.children = item.children.map((itt) => {
            return { pai: item, ...itt };
          });
          ret.push(...item.children);
        }
        ret.push(item);
        return ret;
      }, []);
      this.menu_ativo = mnus.find(
        (x) =>
          x.link == this.$route.path || x.link == this.$route.path.substr(1)
      );
      // console.log("MENU",this.menu_ativo)
      if (this.menu_ativo) {
        if (this.menu_ativo.pai) {
          this.breads.push({
            text: this.menu_ativo.pai.nome,
            href: this.menu_ativo.pai.link,
          });
        }
        this.breads.push({
          text: this.menu_ativo.nome,
          href: this.menu_ativo.link,
        });
      }
    },
    encontraGeneroPalavraNovo() {
      let final = this.menu_ativo.nome.substr(
        this.menu_ativo.nome.length - 1,
        1
      );
      if (final.toLowerCase() == "s") {
        final = this.menu_ativo.nome.substr(this.menu_ativo.nome.length - 2, 1);
      }
      if (final.toLowerCase() == "a" || final.toLowerCase() == "e") {
        return "Nova";
      } else if (final.toLowerCase() == "o") {
        return "Novo";
      }
    },
  },
};
</script>

<style></style>
