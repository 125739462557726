<template>
  <div class="card">
    <div class="medico" v-if="agenda">
      <div>
        <i class="fa fa-user-md mr-2" />
        {{ agenda.EntidadeNome }}
        <br />
        <small
          ><i class="fa fa-stethoscope" /> {{ agenda.especialidadeNome }}</small
        >
      </div>
      <div class="text-center">
        <b-icon-shop />
        {{ !!agenda.consultorio ? "Consultorio Médico" : agenda.clinic }}
        <div v-if="mostraData">
          <i class="far fa-calendar" />
          {{ agenda.data | moment("DD/MM/YYYY") }}
        </div>
      </div>
      <div class="hora">
        <div>
          <i class="far fa-clock" />

          {{ agenda.hora_inicio ? agenda.hora_inicio.slice(0, 5) : "" }}
        </div>
        <div class="tempo">
          <span v-if="agenda.intervalo">
            <i class="fa fa-hourglass-half"/>
            {{ agenda.intervalo }} min.<br
          /></span>
          <div>
            <span style="font-size: 10px"
              ><b>Consultas: </b>{{ agenda.consultas }} /
              {{ agenda.consulta_qtd }}</span
            >
          </div>

          <div>
            <span style="font-size: 10px"
              ><b>Retornos: </b>{{ agenda.retornos }} /
              {{ agenda.retorno_qtd }}</span
            >
          </div>
          <div>
            <span style="font-size: 10px"
              ><b>Total: </b>{{ agenda.agendados }} /
              {{ agenda.total }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="agenda.obs && agenda.obs.length > 0">
      <div style="font-size: 10px">
        <b><b-icon-chat /> Observações: </b> {{ agenda.obs }}
      </div>
    </div>
    <div class="status" v-if="false">
      <!-- <div class="conteudo"> -->
      <div>
        <header>
          <i class="fa fa-clock" />
          Horários
        </header>
        <content>{{ agenda.total }}</content>
      </div>
      <div>
        <header>
          <i class="fa fa-users" />
          Agendados
        </header>
        <content> {{ agenda.agendados }} </content>
      </div>
      <div>
        <header>
          <i class="fa fa-undo" />
          Retornos
        </header>
        <content> {{ agenda.retornos }}/{{ agenda.retorno_qtd }} </content>
      </div>
      <div>
        <header>
          <i class="fa fa-user-clock" />
          Espera
        </header>
        <content> 0/{{ agenda.espera_qtd }} </content>
      </div>
      <div>
        <header>
          <i class="fa fa-check-square" />
          Confirmados
        </header>
        <content> {{ agenda.confirmados }} </content>
      </div>
      <div class="no-border-right">
        <header>
          <i class="fa fa-user-check" />
          Compareceu
        </header>
        <content>0</content>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agenda: {
      type: Object,
      default: null,
    },
    mostraData: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/agenda.scss";
</style>
