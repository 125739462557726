<template>
  <div>
    <div v-if="loading"><b-spinner></b-spinner> Carregando...</div>
    <div class="row" v-if="devolucaoObj">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 mt-3 col-sm-6">
            <b>Código</b><br />
            {{ devolucaoObj.id }}
          </div>
          <div class="col-12 mt-3 col-sm-6">
            <b>Data</b><br />
            {{ devolucaoObj.created_at | moment("DD/MM/YYYY HH:mm") }}
          </div>
          <div class="col-12 mt-3 col-sm-6">
            <b>Valor Total</b><br />
            {{ devolucaoObj.vl_total | currencyWithMask }}
          </div>
          <div class="col-12 mt-3 col-sm-6">
            <b>Valor Estornar</b><br />
            {{ devolucaoObj.vl_estorno | currencyWithMask }}
          </div>
          <div class="col-12">
            <hr />
          </div>
          <div class="col-12">
            <b>Usuário</b><br />
            {{ devolucaoObj.usuario }}
          </div>
          <div class="col-12 mt-3">
            <b>Cliente</b><br />
            {{ devolucaoObj.Cliente }}
          </div>
          <div class="col-12 mt-3">
            <b>Fornecedor</b><br />
            {{ devolucaoObj.Fornecedor }}
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 mt-">
            <b>Motivo</b><br />
            {{ devolucaoObj.motivo }}
          </div>
          <div class="col-12">
            <hr />
          </div>
          <div class="col-12">
            <b>Tipo de Devolução</b><br />
            {{ devolucaoObj.tipo_devolucao }}
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
        <div
          v-if="
            devolucaoObj &&
            devolucaoObj.creditos &&
            devolucaoObj.creditos.length > 0
          "
        >
          <h4>Créditos Gerados</h4>

          <b-table
            :items="devolucaoObj.creditos"
            :fields="[
              { key: 'id', label: 'Código' },
              { key: 'created_at', label: 'Data' },
              { key: 'valor', label: 'Valor' },
              { key: 'vl_usado', label: 'Usado' },
            ]"
          >
            <template #cell(created_at)="row">
              {{ row.item.created_at | moment("DD/MM/YYYY") }}
            </template>
            <template #cell(valor)="row">
              {{ row.item.valor | currencyWithMask }}
            </template>
            <template #cell(vl_usado)="row">
              {{ row.item.valor_usado | currencyWithMask }}
            </template>
          </b-table>
        </div>

        <div
          class="mt-2"
          v-if="
            devolucaoObj &&
            devolucaoObj.caixa_devolucao &&
            devolucaoObj.caixa_devolucao.length > 0
          "
        >
          <h4>Caixa Devolução</h4>

          <b-table
            :items="devolucaoObj.caixa_devolucao"
            :fields="[
              { key: 'dt_caixa', label: 'Data' },
              { key: 'empresa', label: 'Clínica' },
              { key: 'usuarioAbriu', label: 'Usuário' },
            ]"
          >
            <template #cell(dt_caixa)="row">
              {{ row.item.dt_caixa | moment("DD/MM/YYYY HH:mm") }}
            </template>
          </b-table>
        </div>

        <div class="mt-2">
          <h4>Pagamento Descontado</h4>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VendaLib from "@/libs/VendaLib";

export default {
  components: {},
  props: {
    devolucaoId: Number,
  },
  mounted() {
    this.loadDevolucao();
  },
  data() {
    return {
      devolucaoObj: null,
      loading: false,
    };
  },
  watch: {
    devolucaoId: {
      handler: function () {
        this.loadDevolucao();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    async loadDevolucao() {
      console.log(123, this.devolucaoId)
      this.loading = true;
      try {
        if (this.devolucaoId) {
          const { data } = await VendaLib.getDevolucoes({["venda_devolucoes.id"]:this.devolucaoId});
          console.log(data);
          if (data && data.length > 0) {
            this.devolucaoObj = data[0];
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
